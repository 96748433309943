/* form {
  margin: 10px auto;
  padding: 20px;
  width: 90%;
  border-radius: 10px;
} */

form h2 {
  text-align: center;
  color: #333;
}
.btnflow{ 
margin-top: 10px;
background-color: #4CAF50;
border: none;
border-radius: 5px;
color: #fff;
font-size: 18px;
cursor: pointer;
transition: background-color 0.3s;
width: auto;
float: right;
}

.btn-lb2.signin-btn{
width: auto;
min-width: 120px;
}

.form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
  gap: 20px; /* Add space between sections */
}

.form-column {
  display: flex;
  flex-direction: column;
  width: 48%; /* Use half-width for larger screens */
}


.mainlogindesk input,.mainlogindesk select {
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px; 
}

select:disabled {
  background-color: #f0f0f0;
  color: #a9a9a9;
  cursor: not-allowed;
}

.mainlogindesk button {
  margin-top: 30px;
  width: 100%;
  padding: 12px;
  background-color: #4CAF50;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.mainlogindesk button:hover {
  background-color: #45a049;
}

.mainlogindesk input:focus,.mainlogindesk select:focus {
  border-color: #4CAF50;
  outline: none;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
}

/* Media Query for Mobile View */
@media (max-width: 600px) {
  .form-container {
      flex-direction: column; /* Stack items vertically */
      overflow-x: auto; /* Enable horizontal scroll if necessary */
  }

  .form-column {
      width: 100%; /* Full width on smaller screens */
  }

  .form-column input, 
    .form-column select {
        width: 100%; /* 100% width for inputs and dropdowns */
  }

  .mainlogindesk button {
      font-size: 16px;
  }
}
