/* @import url("https://fonts.googleapis.com/css?family=Concert+One");
html,
body {
  width: 100%;
  height: 100%;
  background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%);
} */


.bg-lb-certificate {
  background: #fee;
  background-size: 100% auto;
  background-image: url('../images/lb-certificate-final-02.jpg');
  background-repeat: no-repeat;
}
.fixed-color svg {
  color: black !important; /* Set the desired color */
}

.fixed-color svg:hover, .fixed-color svg:focus {
  color: black !important; /* Prevent color change on hover */
}

.CircularProgressbar {
  width: 150px !important;
  margin-bottom: 20px;
}
.value-wr{
  width: 100%;
  overflow-x: auto;
  padding-bottom: 15px;
}
.pdfviewer{
  position: relative;
}
.pdfviewer::after{
  content: '';
  position: absolute;
  left: 10px;
  top: 0;
  height: 50px;
  width: calc(100% - 0px);
  background: #fff;
}
.btn.inactiveButton,
.btn.inactiveButton:visited,
.btn.inactiveButton:focus,
.btn.inactiveButton:hover
{
  font-size: 18px;
  border-radius: 1px;
  padding: 8px 25px;
  position: absolute;
  right: 11px;
  top: 4px;
  color: #fff;
  z-index: 5;
  box-shadow: none;
  font-weight: 600;
  border: 0px solid #000;
  text-align: center !important;
  background-color: #3f58de !important;
}
.btn.activeButton,
.btn.activeButton:visited,
.btn.activeButton:focus,
.btn.activeButton:hover{
   position: fixed;
  right: 0px;
  top: 0px;
  z-index: 5;
  font-size: 18px;
  border-radius: 1px;
  padding: 13px 25px;
  color: #fff;
  z-index: 5;
  box-shadow: none;
  font-weight: 600;
  border: 0px solid #000;
  text-align: center !important;
  background-color: #3f58de !important;
}

.prev.inactiveButton,
.prev.inactiveButton:visited,
.prev.inactiveButton:focus,
.prev.inactiveButton:hover
{
  font-size: 18px;
  border-radius: 1px;
  height: 70px;
  color: #fff;
  z-index: 5;
  box-shadow: none;
  font-weight: 600;
  border: 0px solid #000;
  width: 50px;
  text-align: center !important;
  background-color: #3f58de !important;
}

.prev.activeButton,
.prev.activeButton:visited,
.prev.activeButton:focus,
.prev.activeButton:hover{
  position: fixed;
  left: 0px;
  top: 50%;
  right: 0;
  width: 50px; 
  z-index: 5;
  font-size: 18px;
  border-radius: 1px;
  height: 70px;
  /* padding: 13px 25px; */
  color: #fff;
  z-index: 5;
  box-shadow: none;
  font-weight: 600;
  border: 0px solid #000;
  text-align: center !important;
  background-color: #3f58de !important;
}

.nxt.inactiveButton,
.nxt.inactiveButton:visited,
.nxt.inactiveButton:focus,
.nxt.inactiveButton:hover
{
  font-size: 18px;
  border-radius: 1px;
  width: 50px; 
  height: 70px;
  color: #fff;
  z-index: 5;
  box-shadow: none;
  font-weight: 600;
  border: 0px solid #000;
  text-align: center !important;
  align-items: center;
  background-color: #3f58de !important;
}

.nxt.activeButton,
.nxt.activeButton:visited,
.nxt.activeButton:focus,
.nxt.activeButton:hover{
  position: fixed;
  right: -3px;
  top: 50%;
  z-index: 5;
  font-size: 18px;
  border-radius: 1px;
  color: #fff;
  z-index: 5;
  width: 50px; 
  height: 70px;
  box-shadow: none;
  font-weight: 600;
  border: 0px solid #000;
  text-align: center !important;
  align-items: center;
  background-color: #3f58de !important;
}

.prev.inactiveButton{
  left: 0px;
  top: 50%;
  right: 0;
  width: 50px;
  height: 70px;
    position: absolute;
  }
  .nxt.inactiveButton{
    right: -3px;
    top: 50%;
    left: revert;
    width: 50px;
    height: 70px;
    position: absolute;
}



.slide-arrow{
  position: absolute;
  top: 50%;
  margin-top: -15px;

  height: 40px;
  width: 40px;
  background: #333;
  line-height: 1;
  border-radius: 100px;
  color: red;
}
.prev-arrow{
  left: 5%;
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 15px solid #113463;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.next-arrow{
  right: 5%;
  width: 0;
  height: 0;
  border-right: 0 solid transparent;
  border-left: 15px solid #113463;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}


.labelhidepro .MuiInputLabel-outlined.MuiInputLabel-shrink{
  opacity:0;
}
.animationscorebox {
  background: #0003 !important;
  position: fixed;
  height: 100vh;
  width: 100%;
  display: block;
  left: 0;
  z-index: -1;
  top: 0;
}

.ant-calendar-picker-input.ant-input {
  line-height: 1.5;
  height: 40px !important;
}

@-webkit-keyframes text-animation {
  from {
    color: white;
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #FFDD1B, 0 0 70px #FFDD1B, 0 0 80px #FFDD1B, 0 0 100px #FFDD1B, 0 0 150px #FFDD1B;
  }

  to {
    color: white;
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #ff0080, 0 0 30px #ff0080, 0 0 40px #ff0080, 0 0 55px #ff0080, 0 0 75px #ff0080;
    text-align: center;
  }
}

@keyframes text-animation {
  from {
    color: white;
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #FFDD1B, 0 0 70px #FFDD1B, 0 0 80px #FFDD1B, 0 0 100px #FFDD1B, 0 0 150px #FFDD1B;
  }

  to {
    color: white;
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #ff0080, 0 0 30px #ff0080, 0 0 40px #ff0080, 0 0 55px #ff0080, 0 0 75px #ff0080;
    text-align: center;
  }
}

.bomb-rocket,
.normal-rocket {
  position: absolute;
  bottom: 0;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.bomb-rocket:nth-child(1) {
  left: 22vw;
  -webkit-animation-name: bomb-rocket-animate-2;
  animation-name: bomb-rocket-animate-2;
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  color: yellow;
}

.normal-rocket:nth-child(16) {
  left: 63vw;
  -webkit-animation-name: normal-rocket-animate-2;
  animation-name: normal-rocket-animate-2;
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  color: blue;
}

.bomb-rocket:nth-child(2) {
  left: 11vw;
  -webkit-animation-name: bomb-rocket-animate-3;
  animation-name: bomb-rocket-animate-3;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  color: greenyellow;
}

.normal-rocket:nth-child(17) {
  left: 21vw;
  -webkit-animation-name: normal-rocket-animate-3;
  animation-name: normal-rocket-animate-3;
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  color: yellow;
}

.bomb-rocket:nth-child(3) {
  left: 27vw;
  -webkit-animation-name: bomb-rocket-animate-1;
  animation-name: bomb-rocket-animate-1;
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  color: red;
}

.normal-rocket:nth-child(18) {
  left: 32vw;
  -webkit-animation-name: normal-rocket-animate-1;
  animation-name: normal-rocket-animate-1;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  color: greenyellow;
}

.bomb-rocket:nth-child(4) {
  left: 61vw;
  -webkit-animation-name: bomb-rocket-animate-2;
  animation-name: bomb-rocket-animate-2;
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  color: blue;
}

.normal-rocket:nth-child(19) {
  left: 25vw;
  -webkit-animation-name: normal-rocket-animate-2;
  animation-name: normal-rocket-animate-2;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  color: yellow;
}

.bomb-rocket:nth-child(5) {
  left: 23vw;
  -webkit-animation-name: bomb-rocket-animate-3;
  animation-name: bomb-rocket-animate-3;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  color: blue;
}

.normal-rocket:nth-child(20) {
  left: 83vw;
  -webkit-animation-name: normal-rocket-animate-3;
  animation-name: normal-rocket-animate-3;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  color: yellow;
}

.bomb-rocket:nth-child(6) {
  left: 45vw;
  -webkit-animation-name: bomb-rocket-animate-1;
  animation-name: bomb-rocket-animate-1;
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  color: turquoise;
}

.normal-rocket:nth-child(21) {
  left: 22vw;
  -webkit-animation-name: normal-rocket-animate-1;
  animation-name: normal-rocket-animate-1;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  color: springgreen;
}

.bomb-rocket:nth-child(7) {
  left: 84vw;
  -webkit-animation-name: bomb-rocket-animate-2;
  animation-name: bomb-rocket-animate-2;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  color: springgreen;
}

.normal-rocket:nth-child(22) {
  left: 74vw;
  -webkit-animation-name: normal-rocket-animate-2;
  animation-name: normal-rocket-animate-2;
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  color: turquoise;
}

.bomb-rocket:nth-child(8) {
  left: 53vw;
  -webkit-animation-name: bomb-rocket-animate-3;
  animation-name: bomb-rocket-animate-3;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  color: hotpink;
}

.normal-rocket:nth-child(23) {
  left: 81vw;
  -webkit-animation-name: normal-rocket-animate-3;
  animation-name: normal-rocket-animate-3;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  color: hotpink;
}

.bomb-rocket:nth-child(9) {
  left: 4vw;
  -webkit-animation-name: bomb-rocket-animate-1;
  animation-name: bomb-rocket-animate-1;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  color: yellow;
}

.normal-rocket:nth-child(24) {
  left: 8vw;
  -webkit-animation-name: normal-rocket-animate-1;
  animation-name: normal-rocket-animate-1;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  color: red;
}

.bomb-rocket:nth-child(10) {
  left: 5vw;
  -webkit-animation-name: bomb-rocket-animate-2;
  animation-name: bomb-rocket-animate-2;
  -webkit-animation-delay: 1.9s;
  animation-delay: 1.9s;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  color: deepskyblue;
}

.normal-rocket:nth-child(25) {
  left: 26vw;
  -webkit-animation-name: normal-rocket-animate-2;
  animation-name: normal-rocket-animate-2;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  color: yellow;
}

.bomb-rocket:nth-child(11) {
  left: 24vw;
  -webkit-animation-name: bomb-rocket-animate-3;
  animation-name: bomb-rocket-animate-3;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  color: yellow;
}

.normal-rocket:nth-child(26) {
  left: 41vw;
  -webkit-animation-name: normal-rocket-animate-3;
  animation-name: normal-rocket-animate-3;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  color: springgreen;
}

.bomb-rocket:nth-child(12) {
  left: 48vw;
  -webkit-animation-name: bomb-rocket-animate-1;
  animation-name: bomb-rocket-animate-1;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  color: turquoise;
}

.normal-rocket:nth-child(27) {
  left: 69vw;
  -webkit-animation-name: normal-rocket-animate-1;
  animation-name: normal-rocket-animate-1;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  color: springgreen;
}

.bomb-rocket:nth-child(13) {
  left: 27vw;
  -webkit-animation-name: bomb-rocket-animate-2;
  animation-name: bomb-rocket-animate-2;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  color: springgreen;
}

.normal-rocket:nth-child(28) {
  left: 17vw;
  -webkit-animation-name: normal-rocket-animate-2;
  animation-name: normal-rocket-animate-2;
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  color: red;
}

.bomb-rocket:nth-child(14) {
  left: 62vw;
  -webkit-animation-name: bomb-rocket-animate-3;
  animation-name: bomb-rocket-animate-3;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  color: turquoise;
}

.normal-rocket:nth-child(29) {
  left: 6vw;
  -webkit-animation-name: normal-rocket-animate-3;
  animation-name: normal-rocket-animate-3;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  color: turquoise;
}

.bomb-rocket:nth-child(15) {
  left: 53vw;
  -webkit-animation-name: bomb-rocket-animate-1;
  animation-name: bomb-rocket-animate-1;
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  color: springgreen;
}

.normal-rocket:nth-child(30) {
  left: 81vw;
  -webkit-animation-name: normal-rocket-animate-1;
  animation-name: normal-rocket-animate-1;
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  color: yellow;
}

@-webkit-keyframes bomb-rocket-animate-1 {
  0% {
    -webkit-transform: rotate(-10deg) translateY(0) scale(0);
    transform: rotate(-10deg) translateY(0) scale(0);
    opacity: 1;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  0.1% {
    -webkit-transform: rotate(-10deg) translateY(0) scale(0.1, 0.5);
    transform: rotate(-10deg) translateY(0) scale(0.1, 0.5);
    background: -webkit-gradient(linear, left top, left bottom, from(currentColor), color-stop(80%, #000000));
    background: linear-gradient(180deg, currentColor 0%, #000000 80%);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: 1;
  }

  50% {
    -webkit-transform: rotate(-10deg) translateY(-50vh) scale(0.1, 0.5);
    transform: rotate(-10deg) translateY(-50vh) scale(0.1, 0.5);
    background: -webkit-gradient(linear, left top, left bottom, from(currentColor), color-stop(80%, #000000));
    background: linear-gradient(180deg, currentColor 0%, #000000 80%);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: 1;
  }
}

















@font-face {
  font-family: 'gorditalight';
  src: url('fonts/gordita-light-webfont.woff2') format('woff2'),
    url('fonts/gordita-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');

@font-face {
  font-family: 'GothamRoundedBold';
  src: url('../fonts/gbm/GothamRoundedBold.eot');
  src: url('../fonts/gbm/GothamRoundedBold.eot') format('embedded-opentype'),
    url('../fonts/gbm/GothamRoundedBold.woff2') format('woff2'),
    url('../fonts/gbm/GothamRoundedBold.woff') format('woff'),
    url('../fonts/gbm/GothamRoundedBold.ttf') format('truetype'),
    url('../fonts/gbm/GothamRoundedBold.svg#GothamRoundedBold') format('svg');
}

@font-face {
  font-family: 'Gotham Rounded';
  font-style: normal;
  /* font-weight: 400 !important; */
  src: url('../fonts/gbme/GothamRoundedMedium.eot');
  src: url('../fonts/gbme/GothamRoundedMedium.eot') format('embedded-opentype'),
    url('../fonts/gbme/GothamRoundedMedium.woff2') format('woff2'),
    url('../fonts/gbme/GothamRoundedMedium.woff') format('woff'),
    url('../fonts/gbme/GothamRoundedMedium.ttf') format('truetype'),
    url('../fonts/gbme/GothamRoundedMedium.svg#GothamRoundedMedium') format('svg');
}


h1,
h2,
h3 {
  font-family: 'Gotham Rounded';
}

.f-18 {
  font-size: 18px;
}

/* scroll css start here for all browser support */
* {

  outline: none !important;
  /* overflow: auto; */
  scrollbar-color: #3F58DE #d9d9d9;
  scrollbar-width: thin;
}



::-webkit-scrollbar {
  width: 10px;
  border-radius: 20px;

}

::-webkit-scrollbar-track-piece {
  background-color: #d9d9d9 !important;
}

::-webkit-scrollbar-thumb:vertical {
  background-color: #3F58DE; 
  scrollbar-color: #0a4c95 #fe0;
  border-radius: 20px;
}

html {
  scrollbar-color: #3F58DE #d9d9d9;
  scrollbar-width: thin;
  scroll-behavior: smooth;
}

/* scroll css end here for all browser support */

body {
  background-color: #fff;
  color: #333;
  font-weight: 300 !important;
  font-family: 'Gotham Rounded' !important;
}

/* body iframe {
  position: relative !important;
  height: 0px !important;
  background-color: red;
} */

.wrapper-lb {
  position: relative;
  top: 0;
  height: 100%;
  /* max-width: 1920px; */
}

/* .ac-box-arrow{
  visibility: hidden;
} */

input.ant-calendar-picker-input.ant-input::placeholder {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}


.wrapper-lb-new .lesson-list {
  width: 0px !important;
}

.wrapper-lb-new .lesson-list-home {
  width: 76px !important;
}

.wrapper-lb-new .lesson-m-panel {
  width: calc(100% - 76px);
  transition: 0.5s;
}

.wrapper-lb-new .pdfviewer.book embed {
  display: block;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
.wrapper-lb .pdfviewer.book embed{
   height: 100%;
   min-height:calc(100vh - 20vh);
   width: 100%;
}

.css-g1d714-ValueContainer {
  height: 50px !important;
  min-height: 40px !important;
  border-radius: 8px !important;
}

.wrapper-lb-new .lesson-list-wrap,
.wrapper-lb-new .btn-wrap-p1 button,
.wrapper-lb-new .select-instument-show,
.wrapper-lb-new .weekboxs,
.wrapper-lb-new .btn {
  display: none;
}

.wrapper-lb-new .bcomplete-session {
  display: block !important;
}

.bannercourse img {
  width: 100%;
  height: auto;
  position: relative;
  margin-bottom: 30px;
  margin-top: 0px;
}

.wrapper-lb-new .lesson-list {
  border: none !important;
  box-shadow: none !important;
  background: #fff !important;
}

.wrapper-lb-new .lesson-list-home {
  border: none !important;
  box-shadow: none !important;
  background: #fff !important;
}

.wrapper-lb-new .fix-menu-lb {
  border: none !important;
  box-shadow: none !important;
  background: #fff !important;
  width: 0px;
  transition: 0.6s;
}

.wrapper-lb-new .fix-menu-lb .logo-img {
  visibility: hidden;
}

.wrapper-lb-new .fix-menu-lb-two {
  border: none !important;
  box-shadow: none !important;
  background: #fff !important;
  width: 0px;
}

.wrapper-lb-new .fix-menu-lb-two .logo-img {
  visibility: hidden;
}

.wrapper-lb-new .main-panel {
  width: calc(100% - 200px);
  transition: 0.6s;
}

.wrapper-lb-new .nav-lb {
  display: none;
}

.weekboxs {
  border-bottom: 2px solid rgba(63, 88, 222, 1);
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  margin: 0 30px 0 15px;
}


.lb-frame {
  /* height: 100vh; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #ffdecc;
  border: 10px solid #fff;
  /* min-height: 600px; */
  min-height: 100vh;
}

.dflex-sb {
  /* display: flex; */
  justify-content: space-between;
  align-items: baseline;
}

.dflex-sb .nav-link,
.lesson-list-wrap .nav-link {
  display: block;
  padding: 0 !important;
}



.box-shadow-lb {
  border: 2px solid #000 !important;
  border-radius: 15px !important;
  -webkit-box-shadow: 0px 2px 0px 0px #000;
  box-shadow: 0px 2px 0px 0px #000;
  margin: 5% auto;
}

.box-shadow-lb input {
  border: 2px solid #000 !important;
  padding: 10px;
  height: auto;
  font-size: 18px;
  font-weight: 600;
  color: #666 !important;
}

.box-shadow-lb .mainotp {
  display: flex;
  justify-content: space-between;
}

.box-shadow-lb .mainotp label {
  cursor: pointer;
}

.box-shadow-lb .mainotp input {
  border: none !important;
  height: auto;
  width: auto;
  position: relative;
  cursor: pointer;
  top: -3px;
}

.import-box-lb .btn-lb.pr-absolute {
  position: absolute;
  bottom: 20px;
}

.MuiOutlinedInput-root {
  position: relative;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #000;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none !important;
}

/* .filter-wrap-fix {
  background: #ffdecc;
  padding: 10px;
  border-left: 2px solid #000;
  border-right: 2px solid #000;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  border-radius: 10px 10px 0px 0px;
} */

.nav-lb,
.nav-lb .navbar-collapse.collapse,
.navbar-nav {
  /* display: flex !important; */
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: start !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  width: 100%;
}

.navbar-expand-lg .navbar-nav .nav-link a {
  font-family: "Gotham Rounded";
  font-weight: 400;
}

.fix-menu-lb {
  height: 100vh;
  /* overflow: hidden; */
  left: 0;
  width: 350px;
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 270px;
  background: #3F58DE;
  -webkit-box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
  box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%),
    0 8px 10px -5px rgb(0 0 0 / 20%);
  transition: 0.6s;
}

.ant-fullcalendar-fullscreen .ant-fullcalendar-header .ant-radio-group {
  margin-left: 16px;
  background: #008000;
  display: none;
}

.gAMYCL .head-div {
  overflow: unset !important;
}

.kTwPWZ {
  height: 100%;
  overflow-y: unset !important;
  overflow-x: unset !important;
}

.fix-menu-lb-two {
  height: 100vh;
  overflow: hidden;
  left: 0;
  width: 350px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 270px;
  background: #F7F8FC;
  -webkit-box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
  box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%),
    0 8px 10px -5px rgb(0 0 0 / 20%);
}


.fix-menu-lb .navbar-nav a {
  color: #fff !important;
  margin: 6px 0px;
  width: 100%;
  padding: 0.85vh 15px;
  border-radius: 0px 10px 10px 0px;
  font-family: "Gotham Rounded";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
}

.fix-menu-lb .navbar-brand,
.fix-menu-lb-two .navbar-brand {
  background: #fff !important;
  display: block !important;
  width: 100%;
  margin-bottom: 30px;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.table-custom {
  display: table !important;
}

.d-custom {
  display: table-cell !important;
}

.fix-menu-lb .navbar-brand svg,
.fix-menu-lb-two .navbar-brand svg {
  transform: scale(1.3) !important;
}

.row-performance {
  display: flex;
  flex-wrap: wrap;
}

.row-performance .col-lg-2 {
  min-width: 210px;
  margin-bottom: 25px;
}

.fix-menu-lb .navbar-brand svg,
.fix-menu-lb-two .navbar-brand svg {
  transform: scale(1);
}

/* Homework Evaluation */

.fix-menu-lb-two .navbar-nav a {
  color: #000000 !important;
  margin: 6px 0px;
  width: 100%;
  padding: 0.85vh 15px;
  font-family: "Gotham Rounded";
  line-height: 26px;
  border-radius: 0px 10px 10px 0px;
  font-size: 16px !important;
}
.fix-menu-lb .navbar-nav a.active{
  color: #3f58de !important;
  background-color: #fff;
}
.fix-menu-lb-two .navbar-nav a.active{
  color: #fff !important;
  background-color: #3f58de;
}
.fix-menu-lb .navbar-nav a:hover {
  color: #3f58de !important;
  background-color: #fff;
}

#responsive-navbar-nav {
  padding-right: 10px;
}

.fix-menu-lb .navbar-nav a:hover svg {
  fill: #3f58de;
}

.fix-menu-lb .navbar-nav a:hover svg rect,
.fix-menu-lb .navbar-nav a:hover svg circle {
  fill: #3f58de;
  stroke: #3f58de;
}

/* song page css */
.song-page-instrument {
  /* background-color: #D9F1A5; */
  padding: 0px 8px;
  padding-bottom: 30px;
  padding-bottom: 30px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.flex-fill {
  margin: 0 20px;
}

.lesson-down-btns svg {
  margin: 0 4px;
  height: 20px !important;
  width: auto;
}




.lesson-down-btns {
  background: #F7F8FC;
  border-radius: 8px;
  display: flex;
  /* min-width:150px; */
  height: auto;
  padding: 13px 25px;
  font-size: 16px;
  font-weight: 400 !important;
  align-items: center;
  margin: 5px 6px 5px 16px;
  justify-content: center;
  border: none;
  width: 200px !important;
  box-shadow: 0px 1px 2px #666 !important;
}

.lesson-down-btns-student {
  background: #F7F8FC;
  border-radius: 8px;
  display: flex;
  /* min-width:150px; */
  height: auto;
  padding: 13px 25px;
  font-size: 16px;
  font-weight: 400 !important;
  align-items: center;
  margin: 5px 6px 5px 16px;
  justify-content: center;
  border: none;
  width: 50% !important;
  box-shadow: 0px 1px 2px #666 !important;
}

/* .song-page-instrument.Guitar .newrcustomModal h4[style]{
  background-color: #FDCD53 !important;
  padding: 40px;
} */

.signin-btn {
  border: 1px solid #000 !important;
  border-radius: 20px;
  box-shadow: 1px 1px 0 1px #000;
  font-size: 14px;
  font-weight: 700 !important;
}

/* .song-page-instrument.MIF::before ,
.song-page-instrument.Music.is.Fun::before ,
.song-page-instrument.Drums::before ,
.song-page-instrument.Keyboard::before ,
.song-page-instrument.Guitar::before ,
.song-page-instrument.indian.Vocals::before ,
.song-page-instrument.Vocals::before ,
.song-page-instrument.Western::before,
.song-page-instrument.Piano::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100px;
  background: #0003;
  z-index: 0;
  left: 0;
  bottom: -25px;
  border-radius: 15px;
} */

.obj-title-song-page {
  background: #F7F8FC;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 15px;
  text-align: left;
  margin: 6px 0;
}

.obj-title-song-inner b {
  font-family: 'gorditalight';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 17px;
  color: #000;
  width: 99%;

}

.obj-title-song-inner {
  background: #F7FCF9;
  box-shadow: none;
  padding: 15px;
  border-bottom: 1px solid #0003;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}

/* .song-page-instrument.Drums{
  background-color: #ee7b70;
}
.song-page-instrument.Keyboard{
  background-color: #94edd2;
}
.song-page-instrument.Guitar{
  background-color: #FDCD53;}
  .song-page-instrument.MIF,
  .song-page-instrument.Music.is.Fun{
  background-color: #b697f6;
}
.song-page-instrument.Vocals,
.song-page-instrument.indian.Vocals{
  background-color: #f677a5;
}   .song-page-instrument.Western{
  background-color: #f6a577;
}   .song-page-instrument.Guitar{
  background-color: #FDCD53;
}   .song-page-instrument.Guitar{
  background-color: #FDCD53;
}    */

/* .song-page-instrument.Keyboard p,
.song-page-instrument.Keyboard h3,
.song-page-instrument.Guitar p,
.song-page-instrument.Guitar h3,
.song-page-instrument.Drums p,
.song-page-instrument.Drums h3,
.song-page-instrument.Drums p,
.song-page-instrument.Drums h3{
 color: #fff !important;}  */

 .fix-menu-lb .navbar-nav a svg ,
 .fix-menu-lb .navbar-nav a img {
  margin: 0px 10px;
}
.fix-menu-lb .navbar-nav a img{
  filter: invert(1);
}
.fix-menu-lb-two .navbar-nav a img{
  margin: 0px 10px;
  filter: inherit;
}
.fix-menu-lb .navbar-nav .classroom path {
  fill: #ffffff !important;
  stroke: #ffffff !important;
}

.fix-menu-lb .navbar-nav a svg rect,
.fix-menu-lb .navbar-nav a svg path,
.fix-menu-lb .navbar-nav a svg circle {
  stroke: #fff !important;
}


.fix-menu-lb-two .navbar-nav a:hover svg rect,
.fix-menu-lb-two .navbar-nav a:hover svg path,
.fix-menu-lb-two .navbar-nav a:hover svg circle {
  stroke: #fff !important;
  fill: #3F58DE !important;
}

.student-rollno {
  font-weight: bold;
}


.desktop-downLinkContainer img {
  height: 30px;
  margin: 5px !important;
  width: 90px;
}

.fix-menu-lb .navbar-nav a:hover svg rect,
.fix-menu-lb .navbar-nav a:hover svg path,
.fix-menu-lb .navbar-nav a:hover svg circle {
  stroke: #3F58DE !important;
  fill: #fff !important;
}

.fix-menu-lb .navbar-nav a.logout {
  padding-left: 15px;
}

.fix-menu-lb .navbar-nav a.logout {
  background-color: #3F58DE;
}

.fix-menu-lb .navbar-nav a.logout:hover,
.logout:hover {
  background-color: #fff;
}

.fix-menu-lb-two .navbar-nav a.logout,
.logout {
  color: #000 !important;
  background-color: #f7f8fc;
}

.fix-menu-lb-two .navbar-nav a:hover {
  color: #fff !important;
  background-color: #3F58DE;
}

.fix-menu-lb-two .navbar-nav a .img-submenufsm,
.fix-menu-lb-two .navbar-nav a svg {
  margin: 0px 10px;
  width: 15px;
  height: 15px;
}
.fix-menu-lb .navbar-nav a svg, .fix-menu-lb .navbar-nav a img {
  margin: 0px 10px;
  height: 16px;
  width: 16px;
}
.menucontrol.navbar-nav{
  padding-bottom: 30px;
}

.userfsmico {
  margin: 0px 10px;
  width: 30px;
  background: #e6e6e6;
  border-radius: 4px;
  padding: 3px;
  border: 2px solid #3f58de;
}
.progressprofile p {
  color: #333 !important;
  padding-left: 0 !important;
  font-size: 18px !important;
  color: #3f58de !important;
}
.progressprofile .card-body .leftvalueshow{
  min-width: 200px;
}
.progresscard{
  min-width: 100%;
  max-width: 700px !important;
 }
.fix-menu-lb .head-div svg {
  transform: rotate(180deg);
  position: relative;
  right: 34px;
  z-index: 1;
  top: 56px;
  width: 50px;
}

.wrapper-lb-new .main-panel {
  width: 100%;
  /* -webkit-transform: scale(.85)translateY(-25px);
transform: scale(.85)translateY(-25px); */
}

.wrapper-lb-new .mrlevelimg {
  display: none;
}

.fix-menu-lb-two .mrlevelimg {
  display: none !important;
}

.fix-menu-lb .head-div.toggled svg {
  transform: rotate(0deg);
  position: relative;
  right: -40px;
  z-index: 1;
  top: 8px;
  width: 50px;
}

.wrapper-lb-new .ac-box-arrow {
  position: absolute;
  right: -95px;
  z-index: 1;
  top: -1px;
  width: 95px;
  transform: rotate(0deg);
  height: 40px;
  background: #3f58de;
  text-align: left;
  padding: 7px 7px 0px 41px;
  cursor: pointer;
  transition: 0.1s;
  border-radius: 4px;
}

.wrapper-lb-new .pdfviewer::after {
  content: '';
  position: fixed;
  left: 2px;
  top: 0;
  height: 52px;
  width: calc(100% - 0px);
  background: #323639;
  /* background: #fff; */
  z-index: 1;
}

.wrapper-lb-new .ac-box-arrow svg {
  display: none;
}

.wrapper-lb-new .ac-box-arrow b svg {
  display: block;
  height: 18px;
  position: absolute;
  width: 18px;
  top: 10px;
  left: 14px;
}

.wrapper-lb .ac-box-arrow svg {
  display: block;
  height: 21px;
  position: absolute;
  width: 21px;
  top: 8px;
  left: 14px;
}

.lesson-objective:hover {
  border-bottom: 2px solid #3f58de;
  width: auto;
  display: inline-block;
}


.wrapper-lb .lesson-list .ac-box-arrow {
  opacity: 0;
}

.wrapper-lb .lesson-list:hover .ac-box-arrow {
  opacity: 1;
}

.wrapper-lb .lesson-list-home .ac-box-arrow {
  opacity: 0;
}

.wrapper-lb .lesson-list-home:hover .ac-box-arrow {
  opacity: 1;
}







  .wrapper-lb .pdfviewer.book embed.isfullModeopen {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    z-index: 3;
}


.pdfviewer.isfullModeopen::after {
  content: '';
  position: fixed;
  left: 2px;
  top: 0;
  height: 52px;
  width: calc(100% - 0px);
  background: #323639;
  /* background: #fff; */
  z-index: 3;
}










.wrapper-lb .ac-box-arrow {
  position: absolute;
  right: -105px;
  z-index: 6;
  top: 16px;
  width: 95px;
  transform: rotate(0deg);
  height: 40px;
  background: #3f58de;
  text-align: left;
  padding: 7px 7px 0px 41px;
  cursor: pointer;
  transition: 0.1s;
  border-radius: 4px;
}

.ac-box-arrow b {
  color: #fff !important;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
}

.wrapper-lb .ac-box-arrow b svg {
  opacity: 0;
}

.wrapper-lb-new .ac-box-arrow-new {
  position: absolute;
  left: 1px;
  z-index: 1;
  top: 2px;
  width: 95px;
  transform: rotate(0deg);
  height: 40px;
  background: #3f58de;
  text-align: left;
  padding: 7px 7px 0px 41px;
  cursor: pointer;
  transition: 0.1s;
  border-radius: 4px;
}

.wrapper-lb-new .ac-box-arrow-new svg {
  display: none;
}

.wrapper-lb-new .ac-box-arrow-new b svg {
  display: block;
  height: 18px;
  position: absolute;
  width: 18px;
  top: 10px;
  left: 14px;
}

.wrapper-lb .ac-box-arrow-new svg {
  display: block;
  height: 21px;
  position: absolute;
  width: 21px;
  top: 8px;
  left: 14px;
}


.wrapper-lb .lesson-list .ac-box-arrow-new {
  opacity: 0;
}

.wrapper-lb .lesson-list:hover .ac-box-arrow-new {
  opacity: 1;
}

.wrapper-lb .lesson-list-home .ac-box-arrow-new {
  opacity: 0;
}

.wrapper-lb .lesson-list-home:hover .ac-box-arrow-new {
  opacity: 1;
}

.wrapper-lb .ac-box-arrow-new {
  position: absolute;
  left: 275px;
  opacity: 0;
  display: none;
  z-index: 1;
  top: 5px;
  width: 95px;
  transform: rotate(0deg);
  height: 40px;
  background: #3f58de;
  text-align: left;
  padding: 7px 7px 0px 41px;
  cursor: pointer;
  transition: 0.1s;
  border-radius: 4px;
}

.ac-box-arrow-new b {
  color: #fff !important;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
}

.wrapper-lb .ac-box-arrow-new b svg {
  opacity: 0;
}

.fix-menu-lb .head-div .sort-logo {
  opacity: 0;
  display: none;
  transition: 0.3s;
}

.fix-menu-lb .head-div.toggled .sort-logo {
  opacity: 1;
  display: block;
  transition: 0.3s;
  width: 50px;
  overflow: hidden;
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: brightness(0) invert(1);
}

/* student-details */
.editstudentname img {
  filter: grayscale(100%);
}

.editstudentname {
  /* text-decoration: underline; */
  cursor: pointer;
}

.editstudentname:hover,
.editstudentname:focus,
.editstudentname:visited {
  color: #027bff !important;
}

.editstudentname:hover img {
  filter: grayscale(0%);
}

.ihLnEL {
  margin: 0px !important;
}

.MuiOutlinedInput-input {
  padding: 14.5px 14px !important;
}

.red {
  color: #d60000 !important;
}

.gap {
  margin: 3% 0;
}

/* new side menu css */
.zwYsQ {
  background: #3F58DE !important;
}

.progress-search-wrap {
  margin-top: -50px;
}

.prog-min .ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
  min-width: 250px;
  margin-right: 20px;
  margin-left: 10px;
}


.overview .ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
  min-width: 200px;
  margin-right: 20px;
  margin-left: 10px;
}


.instrument-progress-img {
  background-color: #fff0;
  padding: 0px;
  border-radius: 8px;
}

/* song page css */
.song-page-title {
  background-color: #3F58DE;
  padding: 35px 10px 20px 20px;
  /* margin-bottom: 25px !important; */
  border-radius: 8px;
  margin-top: 25px;
  height: 110px;
}

.editstudentbox .MuiSelect-select {
  border: 1px solid #3333 !important;
}

.song-page-title h3 {
  color: #fff !important;
}

.song-form {
  margin-top: -25px;
}

.song-form-v2 {
  margin-top: -45px;
}

.lb-header-top-songs .song-page-instrument .song-form-v2 {
  margin-left: -15px;
}

.lb-header-top-songs .song-form-v2 {
  margin-left: -15px;
}


.lb-header-top-songs .col-lg-9 .song-form-v2 {
  margin-left: 0px;
}

.song-form-v2 .css-2613qy-menu div {
  border-bottom: 1px solid #333 !important;
  background: #333 !important;
  margin: 2px !important;
}

.instrument-box-one {
  background: #fff;
  /* background: linear-gradient(180deg,#fff 40%,#fff0 50%); */
  border-radius: 15px;
  padding: 0 !important;
  margin: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.instrument-box-one.flipbook img {
  border-radius: 15px;
}

.instrument-box-one h3 {
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 600;
  font-size: 170%;
  text-transform: capitalize;
  line-height: 35px;
  color: #000000;
  margin: -1px;
  min-height: 60px;
}

.css-1pahdxg-control {
  min-height: 50px !important;
}

.framel {
  display: inline-block;
  width: 33.3%;
  padding: 10px;
}

.framel img {
  width: 100%;
}

.instrument-box-one .instrument {
  min-height: auto;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0px 0%;
}

.instrument-box-one .instrument img {
  width: 100%;
  height: auto;
  margin: 5px auto;
}

/*exercise page css */
.lb-header-top h3 {
  /* color: #0D5A74;
  font-weight: 600;
  line-height: 39px;
  font-size: 40px; */
  font-size: 40px;
  font-family: 'Gotham Rounded';
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;
}

.lb-header-top-songs h3 {
  color: #2D564A;
  font-size: 40px;
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 39px;
  /* identical to box height, or 98% */


  color: #2D564A;
}


.lb-header-top-songs p {
  color: #2D564A !important;
  font-family: "Gotham Rounded";
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0px !important;
  padding-left: 0 !important;
}

.lb-header-top-concept h3 {
  color: #0D5A74 !important;
  font-weight: 600;
  line-height: 39px;
  font-size: 40px;
}

.lb-header-top-import h3 {
  color: #fff;
  font-weight: 600;
  line-height: 39px;
  font-size: 40px;
}

.title {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
}

.header-two-logo {
  width: 65%;
  height: auto;
}

.activity-title.title {
  background-color: #8cc9dd4a;
  padding: 20px 20px;
  border-radius: 8px;
  /* color: #0D5A74; */
}

.activity-title-concepts.title {
  background-color: #8cc9dd4a;
  padding: 20px 20px;
  border-radius: 8px;
}

.activity-title-concepts.title h2 {
  color: #0D5A74;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
}

.detail-row div>div {
  display: flex;
  padding: 10px 12px;
}

.customModalLessonPage .dropdown-menu {
  max-height: 200px;
  overflow-y: auto; 
  width: 100%; 
  border-radius: 5px; 
  background-color: #f9f9f9; 
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.custom-dropdown-button .dropdown-toggle::after {
  position: absolute;
  right: 15px; 
  top: 50%;
  transform: translateY(-50%);
}

.customModalLessonPage .custom-dropdown-item {
  padding: 15px; 
  font-size: 16px; 
  text-align: left; 
  color: #333; 
  cursor: pointer;
  transition: background-color 0.2s;
}

.customModalLessonPage .custom-dropdown-item:hover {
  background-color: #e0f7fa; 
}

.checkbox-container {
  display: flex;
  justify-content: flex-end; 
  align-items: center;    
  flex: 1;
}

.styled-checkbox {
  width: 24px;     
  height: 24px;    
  cursor: pointer; 
  margin-right: 20px;
}

.box-wrap-lb-song {
  display: flex;  
  justify-content: space-between; 
  align-items: center; 
  margin-bottom: 10px; 
  border-bottom: 1px solid #ddd; 
  padding-bottom: 10px;
}

.video-player>div {
  padding: 0px !important;
}

.video-player iframe,
.video-player {
  height: 26vw !important;
  width: 45vw !important;
  min-height: 300px;
  max-height: 600px;
}

.concepts-excercise-value p {
  font-size: 18px;
  color: #000;
  line-height: 28px;
  margin-bottom: 0;
  font-weight: 400;
  font-family: 'Gotham Rounded';
}

/* lb-header-top-exercise
{
  padding: 35px 10px 20px 20px;
} */

.exercise-details {
  background-color: #F7F8FC;
  border-radius: 8px;
}


.activity-title.title h2 {
  color: #0D5A74 !important;
}

.tittle-6 {
  color: #d60000 !important;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

.ps-container {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
}

.main-panel,
.sidebar,
.sidebar-wrapper {
  -webkit-transition-property: top, bottom, width;
  -o-transition-property: top, bottom, width;
  transition-property: top, bottom, width;
  -webkit-transition-duration: 0.2s, 0.2s, 0.35s;
  -o-transition-duration: 0.2s, 0.2s, 0.35s;
  transition-duration: 0.2s, 0.2s, 0.35s;
  -webkit-transition-timing-function: linear, linear, ease;
  -o-transition-timing-function: linear, linear, ease;
  transition-timing-function: linear, linear, ease;
  -webkit-overflow-scrolling: touch;
}

.main-panel {
  position: relative;
  float: right;
  width: calc(100% - 260px);
  -webkit-transition: 0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1);
  -o-transition: 0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1);
  transition: 0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.lesson-m-panel {
  position: relative;
  float: right;
  height: 100%;
  width: calc(100% - 410px);
  -webkit-transition: 0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1);
  -o-transition: 0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1);
  transition: 0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1);
  /* background: green;
  background-image: url('./fonts/cropped.jpg');
  background-repeat:no-repeat;
  background-size: 100%; */
}

.full-right-control {
  overflow-y: inherit !important;
}

.full-right-control .lesson-right-value {
  margin: 35px 10px 0px 20px;
  min-height: 75vh;
}

/* box-style start */
/* .box-wrap-lb {
  border: 1px solid #000;
  border-radius: 20px;
  -webkit-box-shadow: 1px 3px 0px 1px #000;
  box-shadow: 1px 3px 0px 1px #000;
  padding: 20px;
} */

.box-wrap-lb-dashboard {
  border: 1px solid #000;
  border-radius: 15px;
  padding: 0px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 2px 0px #000000;
  border-radius: 6px;
}

.content-box-lb h4 {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  font-family: 'Gotham Rounded';
  font-style: normal;
  line-height: 22px;
  padding-left: 19px;
  padding-bottom: 10px;
}
.box-nwtexam .content-box-lb h4 {
  font-size: 20px;
  line-height: 20px;
  padding-right: 15px;
  text-transform: capitalize;
}

.content-box-lb p {
  color: #000;
  font-weight: 500;
  margin-bottom: 5px;
}

.content-box-lb span {
  color: #161616;
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  padding: 0 10px;
  border-right: 1px solid #333;
}

.content-box-lb span:last-child {
  border: none;
}

.my-school-list-slider .content-box-slide .school-name h4 {
  padding: 20px;
  margin: 10px 5px;
  margin-bottom: 0.5em;
  border: 1px solid #000;
  border-radius: 6px;
  padding-right: 50px;
  background: #FFF8E3;

}

/* import data css */
.school-name h4 {
  font-family: "Gotham Rounded";
  font-weight: 400;
  font-size: 32px;
  line-height: 30px;
}

.teacher-name h4 {
  font-family: "Gotham Rounded";
  font-weight: 400;
  font-size: 32px;
  line-height: 30px;
}

.music-session-data {
  width: 100%;
  height: auto;
  padding: 0 30px;
  margin-top: 50px;
}

.music-session-data input {
  min-height: 50px;
}

.importdata .music-session-data .filter-value-import-data input {
  min-height: 50px !important;
}

.music-session-data .filter-value-import-data input {
  /* background-image:url(../images/cal.png);
 background-repeat: no-repeat;
 background-position: 93.5% center;
 background-size: 15px auto; */

}

#inputState span,
#date-time-edit span,
input::-webkit-datetime-edit-hour-field {
  font-weight: 700 !important;
  color: #000 !important;
}

.Student-details-data {
  margin-top: 30px;
}

.filter-value-import-data {
  padding: 0 !important;
}

.my-school-list-slider .content-box-slide .content-box-lb-dashboard {
  padding: 20px;
  margin: 10px 0px;
  margin-bottom: 0.0em;
  border-radius: 6px;
  padding-right: 0px;
  background: #FFF8E3;
}

.box-fr-lb-vol .box-layer p {
  margin-bottom: 0;
}

.my-school-list-slider .slick-prev {
  background-image: url('../images/triangle-ico.png');
  background-repeat: no-repeat;
  background-size: 80% auto;
  padding: 20px;
  right: 60px;
  left: inherit;
  top: 45px;
  z-index: 2;
  transform: rotate(178deg) translate(-10px, 24px);
}

#form.alerts .MuiSelect-outlined {
  padding-right: 32px;
  border: 2px solid #d40000;
  box-shadow: 0px 0px 4px 0px #d40000;
}

.my-school-list-slider .slick-prev:before,
.my-school-list-slider .slick-next:before {
  color: #0000 !important;
}

.my-school-list-slider .slick-next {
  background-image: url('../images/triangle-ico.png');
  background-repeat: no-repeat;
  background-size: 80% auto;
  padding: 20px;
  right: 5px;
  top: 47px;
  z-index: 2;
}

.box-fr-lb-vol .box-layer {
  background: #fff;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.box-fr-lb-vol {
  padding: 10px 20px 20px 20px;
  margin: 5px;
  border: 1px solid #000;
  border-radius: 6px;
  box-shadow: 1px 3px 0px 1px #000;
}

.left-box-lb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 82%;
}

.box-logo-lb {
  margin: 0 15px 0 0;
}

.lesson-list .select-instument-show .box-logo-lb img {
  height: 70px !important;
  width: 70px !important;
}

.box-logo-lb-dashboard {
  margin: 25px 0px 0px 15px;
}

.lesson-details {
  padding: 5px;
  display: block;
  width: 100%;
}

.rplayer-frame {
  height: 300px;
  width: 100%;
}

.import-box-lb h4 {
  font-weight: 600;
  /* font-size: 18px; */
  line-height: 22px;
}

.grade-add {
  background-color: #3F58DE !important;
  color: #fff !important;
}


.grade-add {
  width: 50px;
  position: relative;
  top: 0;
  /* padding: 15px !important; */
  height: 38px;
  margin: 0 !important;
  border-radius: 0px 4px 4px 0px !important;
  background: #3F58DE !important;
  font-weight: 600;
  border-color: #3F58DE !important;
}

.box-wrap-lb {
  /* border: 1px solid #000; */
  border-radius: 15px;
  /* -webkit-box-shadow: 1px 3px 0px 1px #000; */
  /* box-shadow: 1px 3px 0px 1px #000; */
  padding: 20px 5px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  /* overflow: hidden; */
  background-color: #F7F8FC !important;
}

.box-wrap-lb-imports {
  /* border: 1px solid #000; */
  border-radius: 15px;
  /* -webkit-box-shadow: 1px 3px 0px 1px #000; */
  /* box-shadow: 1px 3px 0px 1px #000; */
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  /* overflow: hidden; */
  background-color: #F7F8FC !important;
  flex-wrap: wrap;
}

.box-wrap-lb-imports:nth-child(2) {
  margin-top: 15px !important;
}

.view-search-filters .labelpart-instrument .MuiOutlinedInput-input {
  padding-left: 15px !important;
}

.box-wrap-lb-imports .instrument-tools,
.instrument-toolsbox {
  font-size: 14px;
  padding: 7px;
  border: 1px solid #333;
  margin: 27px 5px 20px 5px;
  height: 30px;
  width: 30px !important;
  border-radius: 3px;
}

.assessmentsframe nav a {
  margin: 6px 0px;
  padding: 10px 25px;
  border-radius: 0px;
  font-size: 18px !important;
  color: rgb(0, 0, 0) !important;
  background-color: #3F58DE;
  border: 1px solid #fff;
  border-color: #fff !important;
}

.assessmentsframe input,
.assessmentsframe select {
  background: #fff;
  height: 50px;
  border-radius: 4px !important;
  color: #333;
}

.assessmentsframe input[type="checkbox"] {
  height: auto;
}

.importdata .progress-value .colfix .colfixfilebox {
  margin-top: 0px !important;
  /* background: green; */
}

.assessmentnew-frame {}

.assessmentnew-frame.nav-tabs {
  border: none !important;
}

nav.assessmentnew-frame a {
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.25rem;
  padding-left: 0 !important;
  margin-top: 0px !important;
  margin-bottom: 10px !important;
  color: #333 !important;
  background: #fff0 !important;
  border: none !important;
}

.Student-details-data .MuiInputLabel-root {
  color: #000 !important;
}

/*cluster song page */
.cluster-song-top {
  background: #fff8e3;
}

.bx-wrap-new-cluster {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #000;
  border-left: 2px solid #000;
}

.filter-wrap-fix-cluster {
  background: #ffdecc;
  padding: 10px;
  border: 2px solid #000;
  border-radius: 10px 10px 0 0;
  margin-top: 35px
}


/* home page css teacher name */
.school-teacher-name-wrap {
  background-color: #3f58de;
  border-radius: 8px;
  padding: 30px 25px 5px;
  color: #fff;
  margin-top: 35px;
}

.instrument-img img {
  width: 100%;
}

.content-box-slide .MuiOutlinedInput-input {
  padding: 10px 14px !important;
  background: #F7F8FC !important;
  border-radius: 8px !important;
  outline: none !important;
}

.calender-wrap {
  border-top: 0;
  background: #fff;

}

.MuiOutlinedInput-notchedOutline {
  border-color: #0000 !important;
}

.ant-fullcalendar-fullscreen {
  background: #F7F8FC !important;
  border-radius: 8px !important;
}

.ant-select-selection {
  background-color: #f7f8fc;
  background: #f7f8fc !important;
}

.school-teacher-name-wrap h3 {
  color: #fff;
  font-size: 40px;
  font-family: 'Gotham Rounded';
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 400;
  line-height: 39px;
}

.school-teacher-name-wrap p {
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: #FFFFFF;
  padding: 15px 0 0;
  margin-bottom: 25px;
}

.school-name-heading h4 {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  font-family: "Gotham Rounded";
  margin-bottom: 0;
}

.instrument-box {
  margin: 15px 0 0;

  /* background-image: linear-gradient(to right, #fdcd53, #fdc853, #fdc353, #fdbe53, #fdb953); */
  border-radius: 8px;
  /* padding: 0px 15px; */
}

.instrument-box-piano {
  margin: 3% 0;

  background-image: linear-gradient(to bottom, #94edd2, #8deacd, #85e6c9, #7ee3c4, #76dfbf);
  border-radius: 8px;
  padding: 0px 15px;
}

.instrument-box-heading h4 {
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  font-family: "Gotham Rounded";
}

.instrument-box-heading .instrument-img {
  padding: 0 0px 15px 0px;
}

.instrument-name-progress-boxes {
  background-color: #F7F8FC;
  padding: 15px 10px;
  border-radius: 8px;
  margin: 20px 10px;
  width: calc(33% - 20px);
}

.hscroll .infinite-scroll-component__outerdiv,
.calender-timeslote .events-container.hscroll #scrollableDiv {
  max-height: calc(42vh + 0px);
  overflow-y: auto;
  height: calc(40vh + 0px);
}

.view-search-filters .MuiFormControl-root {
  margin: 10px -10px !important;
  width: 100% !important;
}

p.school-heading {
  color: #3F58DE;
  font-weight: 600;
  font-size: 18px;
  font-family: "Gotham Rounded";
  margin-bottom: -5px;
}

.ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #d6d6d6 !important;
  border-radius: 100px;
}

.school-search {
  max-width: 45%;
  flex: 0 0 50%;
}

.school-name-search {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.school-name-event {
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  margin: 0 0 10px 0px;
  padding: 3px;
  transition: 0.3s;
  background: #F7F8FC;
  border-radius: 8px;
  border: 1px solid #d6d6d6;
}

.instrument-box-single {
  flex: 0 0 25%;
  max-width: 25%;
}

.instrument-detail-box {
  flex: 0 0 76%;
  max-width: 76%;
}

.calender-timeslote.normal .instrument-detail-box {
  flex: 0 0 74%;
  max-width: 74%;
}

.instrument-icon img {
  width: 86px;
  height: 86px;
  margin-top: 4px;
}

.instrument-icon {
  background: #fff;
  /* padding: 25px 20px; */
  margin: 2px 0px 2px 0px;
  border-radius: 8px;
  width: 85px;
  height: 85px;
  /* box-shadow: 0px 4px 4px rgb(0 0 0 / 25%); */
  display: flex;
  align-items: center;
  justify-content: center;
}


.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #0000 !important;
  border-width: 0 !important;
}

.MuiOutlinedInput-root,
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #0000 !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #000 !important;
}

.MuiOutlinedInput-root {
  position: relative;
  border-radius: 8px;
  border: none;
}

/* .infinite-scroll-component__outerdiv{
    height: 54.5vh;
    overflow-y: scroll;
} */

.infinite-scroll-component__outerdiv {
  width: 100%;
  /* height: calc(10vh - 0vh); */
  /* overflow-y: auto; */
}

.excepopup .MuiInputLabel-outlined,
.excepopup .objective-active.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
  transform: translate(1px, -24px) scale(1) !important;
}

.excepopup .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(1px, -24px) scale(1) !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink,
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  -webkit-transform: translate(14px, -6px) scale(0.75);
  -ms-transform: translate(14px, -6px) scale(0.75);
  transform: translate(14px, -6px) scale(0.75);
  color: #000 !important;
  font-weight: 400 !important;
}

.MuiSelect-outlined.MuiSelect-outlined,
.MuiInputBase-input.Mui-disabled {
  padding-right: 32px;
  /* border: 1px solid #ced4da; */
  box-shadow: none;
  background: #fff;
}

/* .MuiInputLabel-outlined.MuiInputLabel-shrink,
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  -webkit-transform: translate(14px, -6px) scale(0.75);
  -ms-transform: translate(14px, -6px) scale(0.75);
  transform: translate(14px, -6px) scale(0.75);
  color: #000 !important;
  font-weight: 400 !important;
} */

/* .MuiSelect-outlined.MuiSelect-outlined,
.MuiInputBase-input.Mui-disabled {
  padding-right: 32px;

  box-shadow: none;
  background: #fff;
} */

.right-input-lb .MuiInputBase-root.Mui-disabled {
  color: rgb(3 3 3);
  cursor: default;
}

.Practice-btn {
  border: none;
  padding: 10px 20px;
  background: #F7F8FC;
  color: #000;
}

.box-wrap-lb-song {
  /* border-left: 2px solid #000; */
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 20px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  /* border-right: 2px solid #000; */
  /* overflow: hidden; */
  /* border-bottom: 2px solid #000; */
  background-color: #F7F8FC;
  margin: 10px 0;
}

/* .box-wrap-lb-song:hover{
  background: #ffdecc78;
} */

.Curriculum-box-btn,
.Curriculum-box-btn button {
  cursor: pointer;
  color: #2D564A;
  font-family: "Gotham Rounded";
  font-weight: 400;
}



.Curriculum-box-btn {
  cursor: pointer;
  color: #2D564A;
  font-family: "Gotham Rounded";
  font-weight: 400;
}

.Curriculum-box-btn p {
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 300 !important;
  font-size: 16px;
  line-height: 17px;
  color: #2D564A;
  margin: 0;
  padding: 3px 10px;
  border-right: 1px solid #000;
  height: 25px;
}


.Curriculum-box-btn:hover p {
  font-weight: 600 !important;
}

.form-check-input {
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  transform: scale(2);
  margin-left: -14px !important;
}

.form-check-label {
  margin-bottom: 0;
  margin-left: 15px;
  font-weight: 600;
}

.btn-lb {
  /* border: 1px solid #000 !important; */
  border-radius: 20px;
  /* -webkit-box-shadow: 1px 1px 0px 1px #000; */
  /* box-shadow: 1px 1px 0px 1px #000; */
  font-size: 16px;
  font-weight: bold !important;
}

.btn-lb2 {
  border-radius: 20px;
  -webkit-box-shadow: 1px 1px 0px 1px #000;
  box-shadow: 1px 1px 0px 1px #000;
  font-size: 16px;
  font-weight: bold !important;
  background: #fff !important;
  color: #000 !important;
  background-color: #fff !important;
  border: 1px solid #000 !important;
}

.ant-btn-primary,
.ant-btn {
  font-size: 16px;
  font-weight: bold !important;
}

.rollbackvideo {
  background: #f4f4f4;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  padding: 25px 25px 0 25px;
  border-radius: 10px;
  border: 1px solid #ddd;
}

.importdata {
  margin-top: -5px;
}

.row-instrument-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 0px 0 4px;
  margin: 0 -15px;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 50px !important;
  z-index: 3;
  display: block;
  height: calc(1.5em + .75rem);
  padding: .775rem .75rem !important;
  line-height: 1.5;
  color: #fff !important;
  content: "Browse";
  background-color: #3F58DE !important;
  border-left: inherit;
  border-radius: 0 .25rem .25rem 0;
}

.custom-file-label {
  height: 50px !important;
  padding: 0.775rem 0.75rem !important;
  top: 5px !important;
}

.btn-lb:focus,
.btn-lb:active,
.btn-lb:hover {
  /* background: #D60000 !important; */
  /* background: #ffa774!important; */
  /* background: rgb(255, 193, 100) !important; */
  /* background: rgb(255, 160, 160) !important; */
  /* border: 1px solid #000 !important; */
  /* color: #fff !important; */
}


.rt-deatils-song {
  width: 60%;
  min-width: 50%;


}

.tag-school-list {
  background: #d4eaff;
  color: #000;
  font-size: 13px;
  padding: 7px;
  margin: 0;
  border-radius: 2px;
  position: absolute;
  right: 0;
  top: 0;
}

.logout,
.logout:hover {
  position: fixed !important;
  bottom: 4vh !important;
  width: 250px !important;
}

.my-school-list-slider {
  background: #fff;
  padding: 0px;
}


.ant-dropdown-menu-root.ant-dropdown-menu-vertical li,
.ant-dropdown-menu-root.ant-dropdown-menu-vertical li i,
.ant-dropdown-menu-root.ant-dropdown-menu-vertical li a {
  font-size: 18px !important;
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  color: #000 !important;
  justify-content: space-evenly;
  font-weight: 100 !important;
}

.box-layer {
  background: #ddd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 4px 0;
}

.box-layer svg {
  margin: 10px 10px 0 0;
}

/* song page css */

.lb-header-top {
  padding: 0px 10px 45px 20px;
  /* background: #fff8e3; */
  border-radius: 6px;
  margin-top: 15px;
}

.lb-header-topcluster {
  position: fixed;
  right: 2%;
  top: -20px;
  width: calc(100% - 320px);
  z-index: 3;
  padding-bottom: 0;
  background: #fff;
}

.table-responsive.exam.cluster {
  margin-top: 170px !important;
}

.mt-content-100 {
  margin-top: -100px;
}

.filter-value {
  background: #fff0;
  padding: 15px 10px 0;
  width: 100%;
  display: flex;
  align-items: stretch;
  align-content: flex-end;
}

.fullcluster {
  background: #fff0;
  padding: 15px 10px 0;
  width: 100%;
  display: block;
}

.filter-values {
  background: #fff0;
  padding: 25px 0px 0;
  width: 100%;
}

.filter-value .MuiFormControl-root {
  width: 100%;
  border-radius: 6px;
}

.MuiFormControl-root *,
.MuiList-root.MuiMenu-list * {
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: 'Gotham Rounded' !important;
  color: #333 !important;
}
/* .excepopup .MuiFormControl-root *,.excepopup .MuiList-root.MuiMenu-list *, */
.MuiList-root.MuiMenu-list span.MuiCheckbox-root.MuiCheckbox-colorPrimary svg{
  font-size:30px !important
}

.right-input-lb-wrap {
  /* width: 50%; */
  width: 25%;
  max-width: 550px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.content-box-lb {
  width: 100%;
  margin-top: 10px;

}

.song-namest4 {
  width: 50%;
}

.content-box-lb-dashboard {
  width: 100%;

}

.css-11unzgr {
  /* height: 10vh !important; */
  max-height: 200px !important;
  overflow-y: auto;
  padding-bottom: 4px;
  padding-top: 4px;
  position: relative;
  box-sizing: border-box;
  z-index: 1 !important;
}

.MuiMenu-paper {
  max-height: calc(50% - 96px) !important;
}

.MuiInputLabel-outlined {
  z-index: 4 !important;
  transform: translate(14px, 20px) scale(1);
  pointer-events: none;
  /* color: #9d9da0 !important; */
  color: #000 !important;
}

.right-input-lb {
  width: 48%;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  -webkit-transform: translate(1px, -14px) scale(0.75) !important;
  -ms-transform: translate(1px, -14px) scale(0.75) !important;
  transform: translate(1px, -14px) scale(0.75) !important;
  opacity: 0;
}

.bg-blue {
  background: #8cc9dd4a;
}

.bg-light-blue {
  background: #d4eaff;
}

.bg-dark-pink {
  background: #ffdecc;
}

.song-namest4 {
  width: 50%;
}

.bg-lighter-pink {
  background: #3F58DE;
}

.lb-header-top p {
  font-weight: 500;
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #0D5A74;
  padding-left: 10px;
  margin-right: 20px;
}

.lb-header-top .content-box-lb p {
  padding-left: 20px !important;
}

.lb-header-top .content-box-lb h4 {
  padding-bottom: 0px;
}

.video-player iframe {
  margin: 0 auto;
  display: block;
  border: 10px solid #000;
  border-radius: 30px;
  width: 43rem !important;
  height: 25rem !important;
  -webkit-box-shadow: 6px 6px 8px rgba(0, 0, 0, .16);
  box-shadow: 6px 6px 8px rgba(0, 0, 0, .16);
}
.login-player iframe {
  display: block;
  border: 10px solid #000;
  border-radius: 30px;
  height: 59vh;
  min-height: 450px;
  max-height: 800px;
  margin: 5vh auto;
  -webkit-box-shadow: 6px 6px 8px rgba(0, 0, 0, .16);
  box-shadow: 6px 6px 8px rgba(0, 0, 0, .16);
}
.customModalLessonPage .video-player,
.newrcustomModal .video-player,
.customModalLessonPage .video-player iframe,
.newrcustomModal .video-player iframe {
  width: 42rem !important;
  height: 24rem !important;
  min-height: 200px;
  max-height: 550px;
  border-radius: 20px;
  overflow: hidden;
  background: #000;
}

.lb-header-top-exercise {
  background: #8cc9dd4a;
  border-radius: 8px;
  padding: 30px 30px 30px 20px;
}

.lb-header-top-exercise h3 {
  color: #0D5A74;
  font-weight: bold;
  line-height: 39px;
}

.t-tips-lb {
  background: #fff;
  /* border: 2px solid #000; */
  border-radius: 10px;
}

.t-tips-lb .nav-item a {
  /* border: 2px solid #000; */
  border-radius: 0px;
  font-size: 16px;
  font-weight: bold;
  padding: 15px;
  color: #000;
  border-left: 0px solid #000;
  border-top: 0px solid #000;
}

.t-tips-lb .nav-item:first-child a {
  border-radius: 9px;
  padding: 20px 15px;
  background-color: #F7F8FC;
  margin: 0 0 10px 0;
  text-align: left;
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.flex-column.t-tips-left-nav {
  margin-top: 6px;
}

.t-tips-lb .tab-content .t-tips-content-deatils {
  margin-right: 3%;
  padding: 20px;
}

.t-tips-content-deatils p {
  margin-bottom: 0px !important;
  font-size: 18px;
  font-weight: 400;
}

.t-tips-content-deatils h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

.t-tips-lb .nav-item .nav-link.active,
.t-tips-lb .nav-item .show>.nav-link {
  color: #000;
  /* background-color: #a9d2e0; */
}

.rt-deatils-song {
  height: calc(100vh - 220px);
  overflow-y: auto;

}

.t-tips-left-nav {
  display: block;
  height: calc(100vh - 220px);
  overflow-y: auto;
  flex-wrap: nowrap !important;
}

.infinite-scroll-component {
  overflow-x: hidden !important;
}

.filter-value .infinite-scroll-component {
  overflow-x: hidden !important;
  padding-bottom: 300px;
}

.assign-curriculum {
  font-size: 18px;
  font-weight: 600;
  color: #3F58DE !important;
  line-height: 30px;
}

.MuiTextField-root {
  width: 100%;
  margin: 10px 0 !important;
}

.import-box-lb .MuiFormControl-root {
  width: 100%;
}

.MuiInput-root {
  position: relative;
  width: 100%;
  padding: 10px;
}

.import-box-lb {
  height: auto;
  /* padding-bottom: 40px; */
  min-height: 460px;
}

.import-box-lb .assign-curriculum {
  text-decoration: none !important;
  /* border-bottom: 2px solid #000; */
}

.ac-box-lb {
  width: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.ac-box-lb-dashboard {
  width: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.arrowlinks svg {
  height: 25px;
  width: 25px;
}

.ant-progress-status-success .ant-progress-bg {
  background-color: #3f58de !important;
}

.ant-progress-success-bg,
.ant-progress-bg {
  background-color: #3f58de !important;
}

.exit-lb {
  margin: 30px 0;
}

.exit-lb b {
  width: 100%;
  font-weight: 600;
  display: block;
  line-height: 20px;
}

.progress-value {
  padding: 0 20px;
}

.progress-value .colfix .colfixfilebox {
  width: 100%;
  display: block;
  margin-top: 15px;
  max-width: 280px;
  font-size: 12px !important;
  overflow: hidden;
}

.progress-value .box-wrap-lb {
  background: #fff;
}

/* calender css */
.calende-multi-function {
  height: 100%;
  padding: 0px;
  max-width: 425px;
  float: right;
  /* background: rgba(233, 232, 252, 0.3) !important; */
}

/* calender css */

.ant-fullcalendar-fullscreen .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date {
  display: block;
  height: 35px !important;
  overflow: hidden !important;
}

.ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-date {
  background: #fff;
  border-radius: 8px;
  color: #000;
  font-weight: bold;
  border: 0px solid #000 !important;
}

.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-date {
  background-color: #000 !important;
  background: #3f58de !important;
  border: none !important;
  box-shadow: none !important;
  padding: 4px 10px !important;
  min-width: 35px !important;
}

.instrument-name-progress .col-lg-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  min-width: 300px !important;
}


.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value,
.ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-value {
  color: #fff !important;

}

.ant-fullcalendar-fullscreen .ant-fullcalendar-content {
  position: static;
  width: auto;
  height: 35px !important;
  overflow: hidden !important;
}

.view-search-filters {
  box-shadow: none !important;
  margin-top: -45px !important;
}


.excercise-filter,
.view-search-filters {
  margin-top: -50px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: -40px !important;
}

.mt-content-50 {
  margin-top: -59px !important;
}

.view-details-container.show {
  margin: 6px 30px 6px 6px;
  /* max-height: calc(100vh - 220px);
  overflow-x: hidden; */
}

.ant-select.ant-fullcalendar-year-select {
  min-width: 80px !important;
  width: 275px;
  height: 30px;
  background: #F7F8FC;
  border-radius: 8px;
}

.ant-select-open .ant-select-selection {
  box-shadow: none !important;
  border: none !important;
  background: #F7F8FC;
  border-radius: 8px;
}

.mr-auto.navbar-nav a:hover svg {
  color: #3f58de;
}

.calender-timeslote {
  margin-top: 20px;
}

.calender-timeslote {
  position: static;
  transition: 1.5s;
}

.calender-timeslote.normal {
  position: fixed;
  top: 0;
  background: #fff;
  transition: 1.5s;
  width: 400px;
}

.calender-timeslote.normal .events-container {
  width: 400px;
}

.box-wrap-lb-song .right-input-lb-wrap .css-1s2u09g-control:hover,
.box-wrap-lb-song .right-input-lb-wrap .css-1s2u09g-control:focus,
.box-wrap-lb-song .right-input-lb-wrap .css-1s2u09g-control {
  height: 50px;
  border-color: #3333 !important;
}

.ant-fullcalendar-fullscreen .ant-fullcalendar-month {
  display: block;
  height: auto;
  color: rgba(0, 0, 0, 0.65);
  text-align: left;
  border: 2px solid #ffffff;
  border-radius: 0;
}

.ant-fullcalendar-fullscreen .ant-fullcalendar-month {
  border-top: 2px solid #ffffff !important;
}

.ant-fullcalendar-fullscreen .ant-fullcalendar-date {
  border-top: 2px solid #fff0 !important;
}

.ant-fullcalendar-header {
  padding: 10px 0 !important;
  text-align: right;
  background: #fff !important;
  border-radius: 0;
  margin-bottom: 10px;
  border-bottom: 0px solid;
  display: flex;
  justify-content: space-between;
}

.ant-select.ant-fullcalendar-month-select {
  min-width: 85px !important;
  margin-left: -1px !important;
}

.br-no {
  border: none !important;
}

.ant-radio-button-wrapper,
.ant-radio-button-wrapper:hover {
  font-weight: bold;
  padding: 0 15px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border: 1px solid #ffffff;
}

.ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:visited,
.ant-select-selection:active {
  border: 1px solid #f7f8fc !important;
  font-weight: bold;
  color: #000 !important;
  border-color: #f7f8fc;
  border-right-width: 1px !important;
  box-shadow: none !important;
}

.events-container {
  padding: 0 0 0 15px;
}

.ant-select-arrow {
  color: #000 !important;
}

.events-container::-webkit-scrollbar {
  width: 0 !important;
  -ms-overflow-style: none;
}





.events__list {
  padding-left: 0 !important;
  max-height: 50vh;
  overflow-y: auto;
  padding-right: 15px;
}

.events__title {
  /* color: #3F58DE; */
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  /* margin-bottom: 16px; */
  font-family: "Gotham Rounded";
}

.events__title-upcoming {
  color: #3F58DE;
  display: inline-block;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
  font-family: "Gotham Rounded";
}

.events__tag,
.events__item--left span:nth-child(2) {
  /* margin-left: 10px; */
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
  display: block;
  margin-bottom: 6px;
}

.events__tag--highlighted {
  background: #000;
}

.newrcustomModal h4 {
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
}

.newrcustomModal h5 {
  font-family: 'gorditalight';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  margin-bottom: 15px !important;
  text-align: left !important;
}

.obj-title-song-page h5 {
  font-family: 'Gotham Rounded';
  font-size: 16px;
  line-height: 17px;
  margin-bottom: 0px !important;

}

.newrcustomModal {
  max-width: 80vw !important;
  min-width: 80vw !important;
  border-radius: 15px;
  text-align: center !important;
}

.newrcustomModalStudent {
  max-width: 500px !important;
  min-width: 450px !important;
  border-radius: 15px;
  text-align: left !important;
}

.newrcustomModalSchedule {
  max-width: 40vw !important;
  /* min-width: 60vw !important; */
  border-radius: 15px;
  text-align: left !important;
}

.success-data {
  max-height: 40vh;
  overflow-y: auto;
}

.error-data {
  max-height: 40vh;
  overflow-y: auto;
}



.Instruments {
  background: fixed;
  margin-top: -70px;

}

.newrcustomModal h2,
.newrcustomModal h3 {
  font-size: 24px !important;
  line-height: 30px !important;
}

.events__item {
  padding: 10px 5px 10px 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* margin-bottom: 30px; */
  /* border: 1px solid #000; */
  border-radius: 6px;
  /* box-shadow: 1px 3px 0px 1px #000; */
  font-family: 'gorditalight';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;

  color: #352E2E;
}

.anticon.anticon-delete {
  color: #3F58DE;
  font-size: 18px;
  margin: 1px 0px 0 10px;
}

.infinite-scroll-component .events__item:nth-child(3n+1) {
  background: #F7F8FC;
}

.infinite-scroll-component .events__item:nth-child(3n+2) {
  background: #FFF8E3;
}

.infinite-scroll-component .events__item:nth-child(3n+3) {
  background: #FFDECC;
}


.events__item--left {
  width: 100%;
}

.events__name {
  font-size: 12px;
  font-weight: 600;
  color: #222741;
  display: block;
  margin-bottom: 6px;
  line-height: 18px;
  max-height: 37px;
  overflow: hidden;
  font-family: "Gotham Rounded";
}

.events__date {
  color: #000;
  display: flex;
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 400;
  margin-right: 10px;
  font-size: 16px;
  line-height: 16px;
  color: #352E2E;
  letter-spacing: 0;
}

/* lesson start */

.lesson-list {
  background: rgba(233, 232, 252, 0.3);
  padding: 00px;
  height: 100vh;
  overflow: unset;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 410px;
  box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);

  /* background: green;
  background-image: url('./fonts/cropped.jpg');
  background-repeat:no-repeat;
  background-size: auto 101vh;
  background-position: center top; */
}

.lesson-m-panel.full-right-control .lesson-right-value .lessons-top-displays {
  -webkit-transform: scale(.85)translateY(-25px);
  transform: scale(.85)translateY(-25px);
}

/* .lesson-topic-name-wrap {
  width: 50%;
  max-width: 750px;
  min-width: 600px;
  position: relative;
  margin: 16vh auto 0px auto;
  background-color: #D9F1A5;
  padding: 5px 20px;
  border-radius: 8px; } */

.lesson-topic-name-wrap {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  position: relative;
  margin: 0vh auto 0px auto;
  /* background-color: #D9F1A5; */
  padding: 0px 17px;
  border-radius: 8px;
  margin-left: -5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5vh;
}
.menucontrol
{ 
    min-height: 45vh;
    height: 55vh;
    overflow: auto;
}
.row-buttopnnew {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 5px 0px;
  /* float: right; */
  max-width: 300px;
  min-width: 300px;
}
.row-buttoncover{
  display: flex;
   justify-content: flex-end;
}
.row-buttopnnew .btndrop {
  display: flex;
  justify-content: center;
  width: 100%;
}

.lesson-topic-name-wrapsecond {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  position: relative;
  margin: 0vh auto 0px auto;
  /* background-color: #D9F1A5; */
  padding: 0px 17px;
  border-radius: 8px;
  margin-left: -5px;
}


.lesson-topic-name-wrap .lesson-down-btns,
.lesson-topic-name-wrap .Practice-btn {
  width: 100% !important;
  padding: 10px 12px !important;
  font-size: 14px;
  margin: 5px;
  box-shadow: 0px 1px 2px #666;
}

.exit-assignment .lesson-down-btns svg,
.exit-assignment .Practice-btn svg {
  width: 20px !important;
  height: 20px !important;
}

.newrcustomModal h2,
.newrcustomModal h3 {
  color: #000 !important;
}

.instrument-box-one.Guitar,
.exit-assignment.d-endof.Guitar .lession-bd .lesson-down-btns,
.filter-wrap-fix-studentList.newrcustomModal.Guitar,
.filter-wrap-fix-studentList.Guitar,
.select-instument-show-quiz.Guitar,
.newrcustomModal h2.Guitar,
.newrcustomModal h3.Guitar,
.lesson-topic-name-wrap.Guitar {
  background-color: #FDCD53 !important;
}

.instrument-box-one.Keyboard,
.exit-assignment.d-endof.Keyboard .lession-bd .lesson-down-btns,
.filter-wrap-fix-studentList.newrcustomModal.Keyboard,
.filter-wrap-fix-studentList.Keyboard,
.select-instument-show-quiz.Keyboard,
.newrcustomModal h2.Keyboard,
.newrcustomModal h3.Keyboard,
.lesson-topic-name-wrap.Keyboard {
  background-color: #94EDD2 !important;
}


.instrument-box-one.Piano,
.exit-assignment.d-endof.Piano .lession-bd .lesson-down-btns,
.filter-wrap-fix-studentList.newrcustomModal.Piano,
.filter-wrap-fix-studentList.Piano,
.select-instument-show-quiz.Piano,
.newrcustomModal h2.Piano,
.newrcustomModal h3.Piano,
.lesson-topic-name-wrap.Piano {
  background-color: #D9F1A5 !important;
}


.instrument-box-one.Drums,
.exit-assignment.d-endof.Drums .lession-bd .lesson-down-btns,
.filter-wrap-fix-studentList.newrcustomModal.Drums,
.filter-wrap-fix-studentList.Drums,
.select-instument-show-quiz.Drums,
.newrcustomModal h2.Drums,
.newrcustomModal h3.Drums,
.lesson-topic-name-wrap.Drums {
  background-color: #EE7B70 !important;
}

.instrument-box-one.Recorder,
.exit-assignment.d-endof.Recorder .lession-bd .lesson-down-btns,
.filter-wrap-fix-studentList.newrcustomModal.Recorder,
.filter-wrap-fix-studentList.Recorder,
.select-instument-show-quiz.Recorder,
.newrcustomModal h2.Recorder,
.newrcustomModal h3.Recorder,
.lesson-topic-name-wrap.Recorder {
  background-color: #e1830a !important;
}

.instrument-box-one.Flute,
.exit-assignment.d-endof.Flute .lession-bd .lesson-down-btns,
.filter-wrap-fix-studentList.newrcustomModal.Flute,
.filter-wrap-fix-studentList.Flute,
.select-instument-show-quiz.Flute,
.newrcustomModal h2.Flute,
.newrcustomModal h3.Flute,
.lesson-topic-name-wrap.Flute {
  background-color: #A9E3F5 !important;
}


.instrument-box-one.Vocals,
.exit-assignment.d-endof.Vocals .lession-bd .lesson-down-btns,
.filter-wrap-fix-studentList.newrcustomModal.Vocals,
.filter-wrap-fix-studentList.Vocals,
.select-instument-show-quiz.Vocals,
.newrcustomModal h2.Vocals,
.newrcustomModal h3.Vocals,
.lesson-topic-name-wrap.Vocals {
  background-color: #F677A5 !important;
}


.instrument-box-one.Music,
.instrument-box-one.MIF,
.exit-assignment.d-endof.Music .lession-bd .lesson-down-btns,
.filter-wrap-fix-studentList.newrcustomModal.Music,
.filter-wrap-fix-studentList.Music,
.select-instument-show-quiz.Music,
.newrcustomModal h2.Music,
.newrcustomModal h3.Music,
.lesson-topic-name-wrap.Music {
  background-color: #B697F6 !important;
}


.instrument-box-one.Percussion,
.exit-assignment.d-endof.Percussion .lession-bd .lesson-down-btns,
.filter-wrap-fix-studentList.newrcustomModal.Percussion,
.filter-wrap-fix-studentList.Percussion,
.select-instument-show-quiz.Percussion,
.newrcustomModal h2.Percussion,
.newrcustomModal h3.Percussion,
.lesson-topic-name-wrap.Percussion {
  background-color: #FDCD53 !important;
}


.exit-assignment.d-endof.Quaver .lession-bd .lesson-down-btns,
.filter-wrap-fix-studentList.newrcustomModal.Quaver,
.filter-wrap-fix-studentList.Quaver,
.select-instument-show-quiz.Quaver,
.newrcustomModal h2.Quaver,
.newrcustomModal h3.Quaver,
.lesson-topic-name-wrap.Quaver {
  background-color: #FDCD53 !important;
}


.instrument-box-one.SangeetIsFun,
.exit-assignment.d-endof.SangeetIsFun .lession-bd .lesson-down-btns,
.filter-wrap-fix-studentList.newrcustomModal.SangeetIsFun,
.filter-wrap-fix-studentList.SangeetIsFun,
.select-instument-show-quiz.SangeetIsFun,
.newrcustomModal h2.SangeetIsFun,
.newrcustomModal h3.SangeetIsFun,
.lesson-topic-name-wrap.SangeetIsFun {
  background-color: #A9E3F5 !important;
}


.instrument-box-one.Tabla,
.exit-assignment.d-endof.Tabla .lession-bd .lesson-down-btns,
.filter-wrap-fix-studentList.newrcustomModal.Tabla,
.filter-wrap-fix-studentList.Tabla,
.select-instument-show-quiz.Tabla,
.newrcustomModal h2.Tabla,
.newrcustomModal h3.Tabla,
.lesson-topic-name-wrap.Tabla {
  background-color: #FDCD53 !important;
}


.instrument-box-one.theory,
.instrument-box-one.theory,
.exit-assignment.d-endof.theory .lession-bd .lesson-down-btns,
.filter-wrap-fix-studentList.newrcustomModal.theory,
.filter-wrap-fix-studentList.theory,
.select-instument-show-quiz.theory,
.newrcustomModal h2.theory,
.newrcustomModal h3.theory,
.lesson-topic-name-wrap.theory {
  background-color: #EB3DD0 !important;
}


.instrument-box-one.Violin,
.exit-assignment.d-endof.Violin .lession-bd .lesson-down-btns,
.filter-wrap-fix-studentList.newrcustomModal.Violin,
.filter-wrap-fix-studentList.Violin,
.select-instument-show-quiz.Violin,
.newrcustomModal h2.Violin,
.newrcustomModal h3.Violin,
.lesson-topic-name-wrap.Violin {
  background-color: #FDCD53 !important;
}

.instrument-box-one.Western,
.exit-assignment.d-endof.Western .lession-bd .lesson-down-btns,
.filter-wrap-fix-studentList.newrcustomModal.Western,
.filter-wrap-fix-studentList.Western,
.select-instument-show-quiz.Western,
.newrcustomModal h2.Western,
.newrcustomModal h3.Western,
.lesson-topic-name-wrap.Western {
  background-color: #F6A577 !important;
}

.instrument-box-one.Pedagogy,
.exit-assignment.d-endof.Pedagogy .lession-bd .lesson-down-btns,
.filter-wrap-fix-studentList.newrcustomModal.Pedagogy,
.filter-wrap-fix-studentList.Pedagogy,
.select-instument-show-quiz.Pedagogy,
.newrcustomModal h2.Pedagogy,
.newrcustomModal h3.Pedagogy,
.lesson-topic-name-wrap.Pedagogy {
  background-color: #ed574c !important;
}


.instrument-box-one.Dance,
.exit-assignment.d-endof.Dance .lession-bd .lesson-down-btns,
.filter-wrap-fix-studentList.newrcustomModal.Dance,
.filter-wrap-fix-studentList.Dance,
.select-instument-show-quiz.Dance,
.newrcustomModal h2.Dance,
.newrcustomModal h3.Dance,
.lesson-topic-name-wrap.Dance {
  background-color: #8cb45e !important;
}

.instrument-box-one.Speech,
.exit-assignment.d-endof.Speech .lession-bd .lesson-down-btns,
.filter-wrap-fix-studentList.newrcustomModal.Speech,
.filter-wrap-fix-studentList.Speech,
.select-instument-show-quiz.Speech,
.newrcustomModal h2.Speech,
.newrcustomModal h3.Speech,
.lesson-topic-name-wrap.Speech {
  background-color: #f16063 !important;
}



.newpinfo{
  margin-bottom: 1rem;
display: flex;
flex-direction: row;
justify-content: flex-start;
}




.mail .date {
  display: flex;
  width: auto;
  color: #333;
  padding: 5px;
  margin-right: 15px;
  font-size: 30px;
  font-weight: bold;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: center; */
 }

 .mail .gradeclas {
   font-weight:500;
   color: #333;
   display: inline-block;
   width: 150px;
   font-size: 16px !important;
 }


 .mail .details .from {
  display: flex;
  font-weight: bold;
  height: auto;
  align-items: center;
 }
 .mail .details .from .name {
  margin-left: 10px;
  font-size: 24px;
 }


 .mail {
  position: relative;
  height: auto;
  width: 100%;
  min-width: 230px;
  margin: 10px;
  border: 1px solid #3333;
  border-radius: 10px;
  font-family: 'Gotham Rounded';
 }
 .portfolio {
     position: relative;
     height: auto;
     width: 100%;
     min-width: 230px;
     margin: 8px 2px;
     border: 1px solid #3333;
     border-radius: 10px;
 }

 .rightvalueshow {
   color: #333 !important;
   font-weight: 600 !important;
   text-transform: capitalize;
   font-size: 16px !important;
   line-height: 24px !important;
 }
 .portfolio .name{
 color: #3f58de;
 display: block;
 width: 100%;
 font-size: 40px;
 margin-left: 0 !important;
 font-size: 24px;
 text-align: left;
}
.monthname {
  color: #3f58de;
  font-size: 25px;
  text-transform: capitalize;
  line-height: 30px;
}
 .mail.portfolio .data.data-1{
 display: flex;
 justify-content: space-between;
 }

.namesubt3{
  display: inline-block;
  width: 14vw;
  margin: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 400;
  min-width: 200px;
  border: 1px solid #e4e4e4;
}
.portfolio .from img {
 width: 45px;
 height: auto;
 background: #e0e0e094;
 padding: 2px;
 border-radius: 5px;
 margin: 0px 10px;
 border: 2px solid #3f58de !important ;
 box-shadow: 0 1px 2px #666 !important;

}
.studentwrapline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.progresgraph{
  display: flex;
}
.mailwrap{
display: flex;
flex-wrap: wrap;
justify-content: start;
}

.mail .details .subject {
 height: auto;
 line-height: 24px;
 font-weight: 500;
 color: #333;
 font-size: 18px;
}
.tittle-fsm>b {
  font-size: 24px;
  font-weight: 500;
  color: #333;
  line-height: 40px;
  text-transform: capitalize;
}

.mail .details {
width:calc(100% - 240px);
padding: 15px;
}
.lesson-down-time span{
margin-left: 10px;
}
 .lesson-down-time,.lesson-down-pdf{
 display: flex;
 height: auto;

}
.btn-save-download,
.btn-save-download:hover,
.btn-save-download:focus,
.lesson-down-pdf a,
.lesson-down-pdf a:hover,
.lesson-down-pdf a:focus,
.lesson-down-pdf a:visited{
  padding: 6px 15px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 400 !important;
  align-items: center;
  margin: 0px;
  justify-content: center;
  cursor: pointer;
  /* border: 1px solid #666666; */
  background: #fff;
  box-shadow: 0 1px 2px #666 !important;
  color: #333;
  border: 2px solid #3f58de;
  box-shadow: 0 1px 2px #666 !important;


}
 .mail .details .content {
   height: auto;
 line-height: 20px;
 overflow: hidden;
 color: gray;
 font-size: 14px;
}














#lesson-cont-wrap-tab-lesson-objective {
  margin: 10px 0 !important;
}

.lesson-list-wrap {
  padding-left: 0;
  list-style: none;
  padding: 0px 10px 10px 15px;
  height: calc(100vh - 260px);
  overflow-y: auto
}

.lesson-list-wrap .nav-link.active,
.lesson-list-wrap .show>.nav-link {
  color: #000 !important;
  background-color: #fff0 !important;
  /* font-weight: 600; */
}

.lesson-list-wrap .nav-link {
  color: #000 !important;
}

.lesson-list-wrap li {
  padding: 1px 5px;
  border-bottom: 2px solid rgb(196, 196, 196);
}

.lesson-list-wrap li b,
.l-tips-value b {
  text-transform: uppercase;
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-size: 16px;
  color: #D60000;
  letter-spacing: 1px;
  padding: 10px 0 5px !important;
  display: block;
}

.lesson-right-value h6 {
  text-transform: uppercase;
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-size: 16px;
  color: #000 !important;
  letter-spacing: 1px;
}

.l-tips-value b {
  text-transform: uppercase;
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-size: 16px;
  color: #D60000;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 10px 0 0 !important;
  display: block;
  margin-bottom: -10px;
  display: block;
  float: left;
}

.lessons-top-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  /* flex-direction: row; */
  padding-bottom: 3%;
}

.lesson-top-btns {
  background: #F7F8FC;
  border-radius: 8px;
  padding: 5px 10px;
}

.lesson-name-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}



.btn-wrap-p1 {
  display: flex !important;
  justify-content: space-between;
  padding: 15px 10px;
}

.btn-wrap-p1 i {
  background: green !important;
  font-size: 20px !important;
}

.btn-wrap-p1 .btn.ml-3 svg {
  height: 14px !important;
  width: 16px !important;
}

.select-instument-show {

  /* background: #D9F1A5;
margin: 10px;
padding: 15px 5px;
  padding-left: 5px;
border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center; */
  padding: 10px !important;
  display: flex;
  margin: 20px 30px 15px 12px;
  background: #fff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 8px;
}

.lessonPageTitle b {
  font-size: 11px !important;
  line-height: 17px !important;
  min-width: 200px !important;
  display: block;
}

.select-instument-show-quiz {
  background: #D9F1A5;
  margin: 10px;
  padding: 15px 5px;
  padding-left: 5px;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.assignment-heading {
  height: 160px;
  width: 160px;
  background-color: #2D564A;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -110px;
  left: -20px;
  z-index: -5;
}

.assignment-heading h4 {
  color: #D9F1A5;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 39px;
}

.select-instument-show h3 {
  font-size: 21px;
  line-height: 24px;
  font-weight: 500;
  font-family: 'Gotham Rounded';
}

.lesson-right-value h3 {
  font-size: 24px !important;
  line-height: 28px !important;
  font-weight: 500 !important;
  font-family: 'Gotham Rounded';
  color: #000 !important;
}

.select-instument-show b {
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  font-family: 'Gotham Rounded';
}

.l-tips-value {
  margin: 60px 100px;
}

.lesson-right-value {
  margin: 30px 20px;
}

.lesson-right-value .lessons-top-display,
.exit-assignment.d-endof {
  padding-left: 10% !important;
  padding-right: 10% !important;
}

.lesson-right-value h6 {
  font-size: 18px;
  padding-bottom: 5px;
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 500;
  margin: 20px 0 0px;
  color: #2D564A;
}

.d-flexlession {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  /* max-height: 60px; */
  overflow: hidden;
}

.d-flexlession .btn {
  width: 190px;
}

.lesson-right-value h3 {
  font-size: 14px;
  padding-bottom: 10px;
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 400;
  text-align: left;
  /* margin-top: 50px; */
  line-height: 18px;
  color: #2D564A;
  margin-top: 0px;
  text-align: left;
}

.lesson-name-details .sub-title {
  width: calc(100% - 0px);
  margin-top: 0px;
}

.lesson-right-value ul {
  list-style: none;
  padding-left: 0;
}

.lesson-right-value ul li {
  /* margin: 15px 0; */
  font-size: 18px;
}

.lesson-right-value ul li b {
  font-size: 18px;
}

.content-css {
  font-weight: bold;
  margin-bottom: 15px;
}

.lesson-right-value video,
.lesson-right-value iframe {
  height: 30rem !important;
  width: 52rem !important;
  margin: 20px !important;
  background: #000;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0px 0px 0px 20px #e6e6e6;
}

.popup-msgbox {
  position: absolute;
  top: -12px;
  z-index: 0;
  margin: auto;
  left: 0;
  right: 0;
  width: 21vw;
  min-width: 100%;
  color: #fff !important;
  font-size: 12px;
  height: auto;
  line-height: 6;
}

.popup-msgbox .msg {
  padding: 5px;
  color: #fff !important;
  background: #3d922d;
  font-size: 16px;
  width: 100%;
  display: inline-block;
}

/* .lesson-right-value::before {
  position: absolute;
  content: '';
  height: 20px;
  width: 75%;
  background: #D9F1A5;
  bottom: 100px;
  z-index: -1;
  right: 13%;
  border-radius: 20px;
  margin: auto;
} */
.lesson-right-value b {
  text-transform: uppercase;
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #D60000;
  letter-spacing: 1px;
  text-transform: uppercase;

}

.custom-file-label b {
  text-transform: capitalize;
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000;

}

.lesson-right-value ul li b {
  font-size: 18px;
  display: block;
  margin-bottom: 5px;
  margin-top: 30px;
}

.lesson-right-value p,
.lesson-right-value ul li {
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

/* form start */



.contact-text {
  margin: 45px auto;
}

.mail-message-area {
  width: 100%;
  padding: 0 15px;
}

.mail-message {
  width: 100%;
  background: rgba(255, 255, 255, 0.8) !important;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  transition: all 0.7s;
  margin: 0 auto;
  border-radius: 0;
}

.not-visible-message {
  height: 0px;
  opacity: 0;
}

.visible-message {
  height: auto;
  opacity: 1;
  margin: 25px auto 0;
}

/* Input Styles */

.form {
  width: 100%;
  padding: 15px;
  background: #f8f8f8;
  border: 1px solid rgba(0, 0, 0, 0.075);
  margin-bottom: 25px;
  color: #727272 !important;
  font-size: 13px;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.form:hover {
  border: 1px solid #8BC3A3;
}

.form:focus {
  color: white;
  outline: none;
  border: 1px solid #8BC3A3;
}

.textarea {
  height: 200px;
  max-height: 200px;
  max-width: 100%;
}





/* form end */

.serch-icon-btn-lb {
  padding: 0;
  position: absolute;
  left: 15px;
  top: 25px;
  z-index: 3;
  cursor: pointer;
}

.content-box-slide .serch-icon-btn-lb {
  padding: 0;
  position: absolute;
  left: 25px;
  top: 20px;
  z-index: 3;
  cursor: pointer;
}

.progress-selectedoption .MuiFormControl-root {
  margin: 10px 0px !important;
}

.progress-serch .MuiOutlinedInput-input,
.song-form-v2 .MuiOutlinedInput-input,
.song-form .MuiOutlinedInput-input,
.view-search-filters .MuiOutlinedInput-input,
.content-box-slide .MuiOutlinedInput-input {
  padding-left: 50px !important;
  background: #f7f8fc !important;
  /* border: 1px solid #d6d6d6; */
}

.song-form-v2 .MuiSelect-select.MuiSelect-select {
  padding-left: 24px !important;

}

.MuiSelect-icon {
  z-index: 2;
}

.serch-icon-btn-lb svg {
  height: 20px;
  width: 20px;
}

.lb-header-top .MuiInputBase-input,
.MuiOutlinedInput-root {
  position: relative;
  z-index: 2;
  background: #F7F8FC;
  border-radius: 4px !important;
  overflow: hidden;
  border: 1px solid #d6d6d6 !important;
}

.MuiInput-underline:before {
  border: none !important;
}

.custom-progressed {
  width: 240px;
}

.MuiInput-root {
  border: 1px solid #3334;

}


.custom-progressed .ant-progress-outer {
  background: #fff0;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #d6d6d6 !important;
}

.bars-listed .bars-ele .control-logo {
  transform: scale(0.8) translate(0px);
  position: absolute;
  right: -40px;
}

.nav-link svg {
  height: 1.6rem;
  width: 20px;
}

.menu-link-tio {
  min-width: 280px;
  font-family: 'Gotham Rounded';
  font-style: normal;
  display: flex;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 20px;
  justify-content: flex-start;
  align-items: baseline;
  padding: 0px 0 11px 0;
}

.bars-listed .bars-ele {
  display: flex;
  color: #000;
  position: relative;
  text-decoration: none;
  line-height: 20px;
  box-shadow: none !important;
  justify-content: space-between;
  align-items: baseline;
  /* min-width: 15.5vw; */
}

.bars-listed .bars-ele:last-child::after {
  background: transparent;
}

.bars-ele.null:hover {
  color: #000;
  font-weight: bold;
}

.nav-link.active .bars-ele.null {
  color: #666;
  font-weight: bold;
}

.nav-link.active .bars-ele.null:hover {
  color: #000;

}

.bars-listed {
  margin-left: 0;
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 17px;
  /* identical to box height */
}

/* .bars-listed {
  margin-left: 30px;
}
.bars-listed .bars-ele::before {
  content: "";
  position: absolute;
  left: -28px;
  height: 10px;
  width: 10px;
  background: #3f58de;
  border: 2px solid #3f58de;
  border-radius: 100px;
  top: 7px;
} */
.btn-primary {
  color: #fff;
  background-color: #3f58de !important;
  border-color: #3f58de !important;
}

.bars-ele.pointer img {
  border-radius: 20px;
}

.homelayout .left-clbox {
  flex: 0 0 calc(100% + -460px);
  max-width: calc(100% + -460px);
  margin-right: 20px !important;
}

.homelayout .right-clbox {
  flex: 0 0 430px;
  max-width: 430px;
}

/* .lesson-list-wrap *{
color: #fff;
} */

.mt-50box {
  margin-top: -85px;
}

.bars-listed .bars-ele::after {
  content: "";
  position: absolute;
  left: -22px;
  height: 80%;
  width: 3px;
  background: #000;
  top: 14px;
}

.bars-listed .bars-ele.completed::before {
  content: "";
  position: absolute;
  left: -28px;
  height: 10px;
  width: 10px;
  /* background: #6ac259;
  border: 2px solid #6ac259; */
  border-radius: 100px;
  top: 7px;
}

.bars-listed .bars-ele.completed::after {
  content: "";
  position: absolute;
  left: -22px;
  height: 100%;
  width: 3px;
  background: #ffbe00;
  top: 14px;
  display: none;
}

/* .bars-listed .bars-ele.completed {
  color: #6ac259;

} */

.bars-listed .bars-ele.completed .control-logo {
  display: none;
}

.answer-card {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 30px;
  position: relative;
  margin: 10px;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px #000000;
  background: #F7F8FC;
  border-radius: 6px;
}

.answer-card h4 {
  font-size: 25px;
  line-height: 28px;
  font-weight: 500;
  font-family: 'Gotham Rounded';
  text-align: center;
}

.common-row {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
}

.answer-select {
  padding: 20px 0;
}

.d-endof {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ans-option {
  padding: 15px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  margin-bottom: 15px !important;
  font-family: 'Gotham Rounded';
  width: 46%;
  display: inline-block;
  margin: 2%;
}

.exit-btnjumpexit {
  background: #d60000;
  width: 200px;
  padding: 10px;
  display: flex;
  justify-content: center;
  position: static;
  color: #fff;
  margin: auto;
  font-size: 20px;
  border-radius: 10px;
  margin: 0px;
}

.exit-btnjump {
  background: #2d564a;
  width: 200px;
  padding: 10px;
  display: flex;
  justify-content: center;
  position: static;
  color: #d9f1a5;
  margin: auto;
  font-size: 20px;
  border-radius: 10px;
  margin-bottom: 50px;
  margin: 0;
  margin-left: calc(50% - 100px);
}


.uniqetable td,
.uniqetable td b {
  font-weight: 400 !important;
}

.ans-option:focus,
.ans-option:active,
.ans-option:hover {
  background: #A9D2E0;
  cursor: pointer;
}

.greenlighter {
  background: #8cc9dd4a;
}

.bluelighter {
  background: #8cc9dda3;
}

.redlighter {
  background: #b2d9e8a8;
}

.ans-option.correct {
  background: #47CAA2;
  color: #fff;
  border: 1px solid #1E8767;
}

.answer-cardboxs .ans-option.correct {
  color: inherit;
  background: #eee;
  border: 1px solid #000;
}

.answer-cardboxs .ans-option.correct::before {
  background-image: url('../images/Group 238.png');
  background-size: 100%;
  width: 20px;
  height: 100%;
  background-position: center;
  top: 0;
}


.pr-slider-op {
  text-align: center;
  margin-top: 25px;
}

.ans-option.correct::before {
  content: '';
  position: absolute;
  background-color: transparent;
  background-image: url('../images/correctthumb.png');
  background-repeat: no-repeat;
  background-size: 70px;
  width: 61px;
  height: 70px;
  right: 7px;
  top: -10px;
  border-radius: 100px;
}

.ans-option.fail {
  background: #D44F4F;
  color: #fff;
  border: 1px solid #921212;
}

.ans-option.fail::before {
  content: '';
  position: absolute;
  background-color: transparent;
  background-image: url('../images/wrongthumb.png');
  background-repeat: no-repeat;
  background-size: 85px;
  width: 70px;
  height: 70px;
  right: 8px;
  top: 0px;
  border-radius: 100px;
}

.form-control-file {
  background: #fff;
  padding: 5px 0px;
  border: 1px solid #0003;
  cursor: pointer;
  border-radius: 6px;
}

.form-file {
  position: relative;
  /* overflow: hidden; */
  margin-top: 15px;
  z-index: 0;
}

.import-sbt-btn {
  text-align: center !important;
  /* padding: 15px 130px; */
  background-color: #3F58DE !important;
  width: 100% !important;
  color: #fff !important;
}


.import-sbt-info svg {
  fill: #333 !important;
  transform: scale(0.65);

}

.import-sbt-info {
  text-align: center !important;
  /* padding: 15px 130px; */
  background-color: #fff0 !important;
  color: #333 !important;
  padding: 0 !important;
}



.form-file::before {
  content: 'Select File';
  position: absolute;
  left: 0;
  background: #444;
  background-size: 1% auto;
  height: 100%;
  min-width: 85px;
  z-index: 0;
  border-radius: 7px 0 0 7px !important;
  top: 10px !important;
  color: #fff;
  font-weight: bold;
  padding: 1px 11px;
  -webkit-padding-after: 4px !important;
  -webkit-padding-before: 5px !important;
  -webkit-padding-end: 21px !important;
  -webkit-padding-start: 21px !important;
  text-align: center;
  font-size: 12px;
  line-height: 2.8;
}

.student-filemap .form-file::before {
  display: none;
  opacity: 0;
}

.g-color {
  color: #008000 !important;
}

/* .form-file::after {
    content: '';
    position: absolute;
    left: 0;
    background: #3540;
    height: 40px;
    width: 100%;
    z-index: 0;
    border-radius: 7px 0 0 7px !important;
    top: 33px;
    color: #fff;
    font-weight: bold;
    padding: 8px 2px;
    text-align: center;
    border: 1px solid #000;
} */

.exit-assignment {
  padding: 0 !important;
  text-align: center;
  margin-bottom: 0;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.lessons-top-display {
  padding-bottom: 0;
  background: #fff;
  position: fixed;
  right: 0;
  top: 8vh;
  width: calc(100% - 410px);
}

.exit-assignment b {
  cursor: pointer;
  font-size: 20px;
}

.wrapper-assgnment-lb {
  position: relative;
  top: 0;
  height: auto;
  min-height: calc(100vh - 200px);
  max-width: 1920px;
}

.bx-wrap-new {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #000;
}

/* .flex-column {
  position: relative;
  bottom: -35px;
} */

.bx-wrap-new .flex-column.t-tips-left-nav {
  width: 40%;
}

.bx-wrap-new .flex-column.t-tips-left-nav .nav-link {
  padding: 0 !important;
}

.bx-wrap-new .flex-column.t-tips-left-nav .nav-link.active {
  color: #fff;
  background-color: #0003;
  color: #fff;
}

.wrapper-lb-new .exit-assignment,
.wrapper-lb-new .lessons-top-display {
  width: calc(100% - 76px);
  transition: 0.5s;
}

.react-responsive-modal-modal {
  min-width: 700px;
}

.MuiListItem-gutters {
  border-bottom: 1px solid #d6d6d6 !important;
}

.MuiListItem-gutters:last-child {
  border-bottom: 0px solid #d6d6d6 !important;
}

.rt-deatils-song {
  padding: 15px;
  border-right: 2px solid #000;
  margin-bottom: 0px;
  padding-left: 30px;
  display: flex;
}

.ant-btn-sm.ant-btn-secondary,
.ant-btn-sm.ant-btn-secondary:focus,
.ant-btn-sm.ant-btn-secondary:hover,
.ant-btn-sm.ant-btn-secondary:visited,
.ant-btn-sm.ant-btn-secondary:active {
  color: #fff;
  background-color: #171717;
  border-color: #171717;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  line-height: 35px;
  height: 35px;
}

.select-right-ele-triger .tabcoloum .anticon.anticon-down.ant-dropdown-trigger {
  width: 50px;
  height: 20px;
  position: absolute;
  right: 5%;
  top: 10%;
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 5px;
  line-height: 15px;
}

.select-right-ele-triger .ant-dropdown-placement-bottomRight .ant-dropdown-menu {
  max-width: 300px;
}

.ant-btn-sm.ant-btn-secondary:hover {
  opacity: 0.8;
}

.ant-btn-sm.ant-btn-secondary i {
  position: relative;
  top: -3px;
  transform: scale(1.2);
}

.select-right-ele-triger {
  width: 100%;
  padding-left: 40px;
}

.activity-details.details.tips-t {
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}


.activity-details.details.tips-t .title {

  align-items: baseline;


}

.activity-details-concepts,
.exercise-details {
  background-color: #F7F8FC;
  min-height: calc(100vh - 280px);
}

.activity-title.title h2 {
  font-family: 'GothamRoundedBold';
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 0;
  /* identical to box height, or 144% */


  color: #000000;
}

.tittle-pt b {
  margin-right: 0;
  min-width: 200px;
  font-weight: 700 !important;
  font-size: 18px;
  line-height: 30px;
  display: inline-block;
  margin-bottom: 10px;
}

.tittle-pt span {
  margin-right: 0;
  min-width: 150px;
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
}

.rt-deatils-song::before {
  content: '';
  position: absolute;
  left: 9px;
  top: 0px;
  height: 100%;
  width: 2px;
  background: #000;
}

.rt-deatils-song {
  padding: 0;
  position: relative;
}

.rt-deatils-song.left-on {
  width: 0;
  min-width: 20px;
  overflow: hidden;
}

.bx-wrap-new .flex-column.t-tips-left-nav.left-on {
  width: 98%;
}

.rt-deatils-song.left-on .select-arrow-r svg {
  transition: 0.6s;
  transform: rotate(180deg) scale(1.4);
}

.rt-deatils-song.left-on .select-right-ele-triger {
  display: none;
}

.select-arrow-r {
  background: #000;
  height: 114%;
  width: 2px;
  position: relative;
  left: 9px;
  top: -20px;
}

.select-arrow-r svg {
  position: absolute;
  top: 45vh;
  left: -6px;
  background: #fff;
  transform: scale(1.5);
  transition: 0.6s;
  cursor: pointer;
}

.tabcoloum {
  display: flex;
  flex-direction: column;
}

.lession-second-ele-box {
  border: 0px solid #000;
  box-shadow: none !important;
  background: #ffdecc;
  border-radius: 0;
  width: 35%;
  min-width: 250px;
  padding: 10px;
  display: flex;
  justify-content: center;
}


#scrollableDiv .t-tips-left-nav {
  width: 100% !important;
  height: 300px !important;
}

.curriculum-active {
  background: #98cdff;
}

.assgnmet-wrping {
  position: relative;
}


.click-arrow {
  height: 55px;
  width: 55px;
  background: #ffffff;
  position: absolute;
  top: 101%;
  z-index: 99;
  border: none;
}

.click-arrow svg {
  font-size: 25px;
  color: #000;
}


.left-ico-click {
  left: -20px;
}


.right-ico-click {
  right: -20px
}

.custom-progressed .ant-progress-outer {
  border: none !important;
}

.sectionScroller {
  height: 400px;
  overflow-y: auto;
}

.gradeScroller {
  height: 400px;
  overflow-y: auto;
}

.br-r2 {
  border-right: 2px solid #333;
}

.p20 {
  padding: 20px;
}

.score-input {
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid #333;
  font-size: 20px;
  width: 200px;
}

.d-flex-scroe label {
  margin: 0px !important;
}

.d-flex-scroe {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin-bottom: 20px;
}

.filter-wrap-fix-homework {
  background: #f7f8fc;
  padding: 0px;
  height: auto;
  border-left: 0px solid #000;
  border-right: 0px solid #000;
  border-top: 0px solid #000;
  border-bottom: 0px solid #f7f8fc;
  border-radius: 10px 10px 0px 0px;

}

.filter-wrap-fix-homework button:hover {
  background: #3F58DE !important;
  color: #fff !important;
  border-left: 0.5px solid #fff;
  border-right: 0.5px solid #fff;
}

/* .filter-wrap-fix-homework {
  background: #D4EAFF;
  padding: 0px;
  height: auto;
  border-left: 2px solid #0000;
  border-right: 2px solid #0000;
  border-top: 2px solid #0000;
  border-bottom: 2px solid #0000;
  border-radius: 10px 10px 0px 0px;
} */

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.filter-wrap-fix-homework button {
  background-color: inherit;
  height: auto;
  width: 25%;
  min-width: 200px;
  max-width: 400px;
  border: 0px solid #0003;
  outline: none;
  font-size: 1.1rem;
  cursor: pointer;
  padding: 15px 3.5vw;
  transition: 0.3s;
  border-bottom: 0px solid #3f58de;
  font-weight: 500;
  margin: 0 0px 0 0px;
}

.tab-bar {
  background: #0000;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px auto;
  border-radius: 10px !important;
  overflow: hidden;
  width: max-content;
}

#form.alerts .MuiSelect-outlined {
  padding-right: 32px;
  border: 2px solid #d40000;
  box-shadow: 0px 0px 4px 0px #d40000;
}

.tab-bar button {
  padding: 15px 20px;
  font-size: 1.1rem;
  background: #7c7c7c;
  border: none;
  border-bottom: 2px solid #7c7c7c;
  border-radius: 0 !important;
  font-weight: 600;
  color: #fff !important;
}

/* Change background color of buttons on hover */
.filter-wrap-fix-homework button:hover {
  background-color: rgb(222 205 205);
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

.speech-bubble {
  position: relative;
  background: transparent;
  border-radius: .4em;
}

.speech-bubble:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: #d1c5be;
  border-bottom: 0;
  margin-left: -20px;
  margin-bottom: -20px;
}

.teaching-tips-icon .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 1px;

  /* Position the tooltip */
  position: relative;
  width: 120px;
  bottom: 100%;
  left: 50%;
  margin-left: -105px;
}

.teaching-tips-icon:hover .tooltiptext {
  visibility: visible;
  overflow: visible;
}

.complete-tick-image .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 1px;

  /* Position the tooltip */
  position: relative;
  width: 120px;
  bottom: 100%;
  left: 50%;
  margin-left: -35px;
  padding: 4px 8px;
}

.complete-tick-image:hover .tooltiptext {
  visibility: visible;
  overflow: visible;
}

.question-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 200px;
}

.quiz-full {
  width: 100%;
  height: auto;
}


.quiz-full.full-width {
  /* background: red !important; */
}

.quiz-full.normal-width {
  /* background: green !important; */
}

.quiz-full.full-width .question-image {
  max-width: 100%;
  height: auto;
  max-height: 200px;
}

.quiz-full.normal-width .question-image {
  max-width: 100%;
  height: auto;
  max-height: 200px;
  width: auto;
}



.customModal {
  overflow-y: 'scroll';
  max-height: "calc(100vh - 200px)";
  width: "auto";
  border-radius: 12px;
}

/* .objective-list {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 185px;
}

.objective-list b {
  display: block;
  font-size: 18px;
  background-color: #ddd;
  color: black;
  text-decoration: none;
  padding: 10px;
} */

.obj-title-song-page:hover {
  background: #f7f8fc;
}

.customModalLessonPage {
  overflow-y: 'scroll';
  max-height: "calc(100vh - 200px)";
  width: "auto";
  border-radius: 12px;
  background: #fff !important;
  border-spacing: -10px;
  box-shadow: none;
  border: none;
  padding: 0 !important;
  min-height: 100px;
}

.signuppoup-modal button.react-responsive-modal-closeButton {
  top: 6px;
  position: absolute;
  right: 6px;
  /* background: #8cc9dd; */
  border: 0px;
  font-size: 2em;
  transform: scale(1.2);
}

.signuppoup-modal {
  overflow-y: 'scroll';
  max-height: "calc(100vh - 200px)";
  width: "auto";
  border-radius: 15px;
  background: #ffdecc !important;
  border-spacing: -10px;
  box-shadow: none;
  border: none;
  flex-grow: 0;
  max-width: 400px !important;
  flex-basis: 400px;
  padding: 0 !important;
  min-height: 100px;
  min-width: 250px;
  position: absolute !important;
  left: calc(50% - 250px);
  right: 0;
  top: 15%;
  /* transform: translate(-40%, 25%); */
}

.box-shadow-lb-wraping {
  max-width: 400px !important;
  flex-basis: 400px;
  padding: 0 !important;
  min-height: 100px;
  min-width: 250px;
}

.signuppoup-modal input {
  background: #fff !important;
}



.box-shadow-login.react-responsive-modal-overlay {
  background: #ffdecc !important;
}

.react-responsive-modal-overlay {
  background: #000000c9 !important;
}

.customOverlay .react-responsive-modal-overlay {
  background: #000000c9 !important;
  /* position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1; */
}

/* .lesson-right-value .react-responsive-modal-root .react-responsive-modal-overlay {
  background: rgba(0, 0, 0,0.9);
} */
.customModalBPMSong {
  overflow: hidden;
  min-width: 70em;
}

.customModalMuseScore {
  /* max-width: 95% !important;
  max-height: 95%; */
  /* margin:0px;
  padding:0px;
  overflow:hidden; */
  position: "fixed";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: auto;
  border: "none";
  margin: 0 !important;
  padding: 0 !important;
  overflow-y: "hidden" !important;
  z-index: 9;
  /* background: rgb(255, 233, 236) !important; */
  background: #fff !important;
  border-radius: 12px;
}

.customModalMuseScore embed {
  height: 85% !important;
}


/* import File data ui */

.uploadliston {
  line-height: 25px;
  margin: 0;

}

.uploadliston .ant-btn {
  height: 40px;
  width: 100%;
  color: #fff;
  font-weight: bold;
  background: #444;
}

.ant-upload-list-item-info span:first-child {
  font-size: 12px;
}

.ant-upload-list-item-info span:first-child i {
  display: none !important;
}

.ant-upload-list-item-info {
  display: flex;
  width: 110%;
  overflow: hidden;
  height: 27px;
}

/* lesson objective modal new design */

.main-info {
  width: 85%;
  margin: 0 auto;
  text-align: left;
}

.Lesson-Plan p {
  font-size: 20px;
  font-weight: 600;
}

.main-top-content h4 {
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
}

.main-top-content p {
  font-size: 20px;
  font-weight: 600;
  margin: 25px 0;
}

.main-info p>strong {
  color: black;
  margin: 0 10px;
}

.goal-objective h5 {
  text-decoration: underline;
  font-size: 22px;
}

.goal-objective p {
  font-size: 20px;
  font-weight: 600;
}

.thought-provoker {
  margin: 40px 0;
  border: 1px solid #000;
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
}



.Lesson-Plan h4 {
  text-align: left;
  font-weight: bold;
  text-decoration: underline;
}

.Lesson-Plan h5 {
  font-weight: bold;
}

.goal-objective {
  border: 1px solid;
  padding: 15px;
}

p.objective-details-data ol {
  padding-left: 0;
  /* padding: 20px; */
  margin: 0 25px;
}

p.objective-details-data ol li {
  padding: 5px 0;
}



/* muse score button hide */

.hide-download-patch {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 100;
  top: 60px;
  height: 48px;
  width: 20%;
  float: right;
}

.hide-download-patch h1 {
  background-color: #f6f6f6;
  border-radius: 10px;
  padding: 12px;
  margin: 10px;
  font-size: 22px;
  width: 180px;
  text-align: center;
  color: #f6f6f6;
}

.tableGradeSection {
  margin: "auto";
  text-align: center;
}

.box-shadow-login .react-responsive-modal-overlay[style] {
  background: #ffdecc;
}

/*  Song sub-details color box */

.rec-det-boxes {
  /* border-radius: 16px; */
  /* border-style: ridge; */
  /* border-spacing: 10px; */
  padding: 0px 8px;
  /* border-width: 2px; */
  /* margin: 0px 3px; */
  font-size: 16px;
  font-weight: bold;
  /* background-color: #099926; */
  /* display: inline-block; */
}


.rec-det-boxes .redtt {
  color: red;
  font-size: 16px;
  display: flex;
}
.value-wr{
  width: 100%;
  overflow-x: auto;
  padding-bottom: 15px;
}

.box-nwtexam .rec-intrm {
  position: relative;
  display: inline-block;
  /* float: left; */
  background: #fff0;
  border: 0px solid #e4e4e4;
  margin: 0px;
  padding: 5px !important;
}

.box-nwtexam .content-box-lb {
  width: 100%;
}



.box-nwtexam .rec-intrm .import-sbt-btn.icon-small,.certificate-icon {
  height: 50px;
  margin-top: 5px;
  background-color: #fdbd10 !important;
  width: 100% !important;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 172px;
}

.box-nwtexam .rec-intrm .import-sbt-btn.icon-small,
.box-nwtexam .rec-intrm a {
  height: 45px;
  margin-top: 5px;
  background-color: #fdbd10 !important;
  width: 100% !important;
  color: #fff !important;
  font-weight: 400 !important;
}

.box-nwtexam .rec-intrm .import-sbt-btn.icon-small svg,
.box-nwtexam .rec-intrm .import-sbt-btn.icon-small img {
  height: 30px !important;
  width: 30px;
  padding: 4px;
  margin: 0px;
}

/* .lssn-btn:-child {

  justify-content: flex-end;
  display: flex;
} */

/* .lssn-btn.compl-sess {
  position: relative;
  right: -22%;
} */

.lssn-btn {
  display: inline;
  flex-wrap: nowrap;
  text-align: center;
}

.comp-sess {
  display: flex;
  position: absolute;
  top: 8%;
  right: 5%;
  padding: 5px;
}

.btn-lb-comp-sess:focus,
.btn-lb-comp-sess:active,
.btn-lb-comp-sess:hover {
  background: rgb(93, 172, 14) !important;
  border: 1px solid #000 !important;
  color: #fff !important;
}

.filter-wrap-fix-studentList {
  background: #f7f8fc;
  padding: 10px;
  height: auto;
  border-left: 0px solid #000;
  border-right: 0px solid #000;
  border-top: 0px solid #000;
  border-bottom: 0px solid #f7f8fc;
  border-radius: 10px 10px 0px 0px;
  margin-top: 20px;
  text-align: left !important;
}

.song-form-custom .css-2613qy-menu {
  font-size: 2em !important;
}

/* .css-2613qy-menu {
display: none !important;
} */


.studentlogout {
  position: fixed !important;
  bottom: 10vh;
  width: 260px !important;
}

.css-yk16xz-control {
  background: #f7f8fc !important;
  border: 1px solid #d6d6d6 !important;
  border-radius: 4px !important;
  min-height: 50px !important;
}

/* .css-1wa3eu0-placeholder {
  color: #a5a5a8 !important;
} */

.MuiInputBase-input::-webkit-input-placeholder {
  opacity: 1 !important;
}

.selectdates {
  position: absolute !important;
  top: -40px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  left: -12px !important;
}

.loadingcss {
  display: flex;
  justify-content: center !important;
  /* margin-top:15px; */
}

.customchk button {
  width: 100%;
  background: #f9fafd !important;
  height: 50px !important;
  border-radius: 4px;
  border: 1px solid #d6d6d6 !important;
  position: relative;
}

.customchk .css-1pcexqc-container {
  background: #f7f8fc !important;
  border: 1px solid #d6d6d6 !important;
  min-width: 280px !important;
}

.css-6y1x9t-control,
.css-6y1x9t-control:hover {
  border-color: #fff !important;
  box-shadow: none !important;
}

.customchk .css-1pcexqc-container input[type='radio'],
.customchk .css-1pcexqc-container input[type='checkbox'] {
  transform: scale(1.4) !important;
}


.desktop-downLinkContainer {
  display: flex;
  width: 100%;
  padding: 0px;
  margin-left: -10px;
}

.desktop-downLinkContainer a:hover,
.desktop-downLinkContainer a {
  padding: 0px !important;
  background-color: #fff !important;
  text-align: center !important;

}

.desktop-appExperience {
  padding: 0vh 0px 1vh 25px;
  position: fixed;
  border: 0;
  width: 250px;
  bottom: 16vh;
}

.desktop-downLinkContainer img {
  height: auto;
  margin: 2px 10px !important;
  width: 95%;
  border-radius: 4px;
  border: 2px solid #d6d6d6;
}


select {
  /* appearance: none !important; */
  outline: 0;
  background: #fff;
  background-image: none;
  width: 100%;
  height: 100%;
  color: #000;
  cursor: pointer;
  padding: 10px 10px;
  border: 2px solid #d6d6d6;
  border-radius: 4px;
  height: 50px;
}

.selectede {
  position: relative;
  display: block;
  width: 15em;
  height: 5em;
  line-height: 5;
  overflow: hidden;
  border-radius: .25em;
  padding-bottom: 10px;
}


.video-frame-full {
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  min-height: 70vh;
  position: relative;
}

.video-frame-full {
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  min-height: 70vh;
  position: relative;
}

.practicevideos {
  padding: 6px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}


.practicevideos label {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 10px;
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 400;
  text-align: left;
  /* margin-top: 50px; */
  line-height: 18px;
  color: #2D564A;
  margin-top: 0px;
  text-align: left;
  margin-right: 10px;
}

.practicevideos input {
  width: 100px;
  height: auto;
  font-size: 20px;
  background: #e6e6e696;
  border-radius: 0;
  text-align: center;
  border: 2px solid #3f58de;
  max-height: 38px;
}

.practicevideos .lesson-down-btns {
  cursor: pointer;
  margin-left: 0;
  border-radius: 0;
}


.box-selecter:hover {
  border: 1px solid #94edd2;
  background: #94edd2;
  box-shadow: 0px 0px 2px 2px #94edd2;
}

.box-selecter {
  border: 2px solid #3f58de;
  border-radius: 8px;
  background: #fff;
  padding: 5px;
  color: #000;
  margin: 10px 0;
  width: 300px;
  transform: scale(0.90);
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editstudentbox {
  width: 100% !important;
  margin: 0px auto !important;
  padding: 5% 10% !important;
}

.editstudentbox .editname.newName {
  font-size: 15px !important;
  color: #333 !important;
  font-family: "Gotham Rounded" !important;
  text-transform: capitalize;
}

.select-grouped .css-1pahdxg-control {
  box-shadow: 0px 0px 0px 1px #000 !important;
  border-color: #000 !important;
}

.flex-selecter {
  display: flex;
  width: 100%;
  max-width: 60vw;
  justify-content: center;
  align-items: stretch;
  margin: auto;
  flex-wrap: wrap;
  max-height: 35vh;
  overflow: auto;
}

.gotacenter svg {
  height: 80px;
}

.clasessnamestittle,
.schoolnamestittle {
  padding: 6px;
  display: flex;
  justify-content: center;

}

.clasessnamestittle h3,
.schoolnamestittle h3,
.clasessnamestittle b,
.schoolnamestittle b {
  color: #000 !important;
  font-family: 'Gotham Rounded';
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px !important;
  line-height: 30px !important;
  padding: 3px 0px 3px 0px;
  text-align: center;
}

.clasessnamestittle b,
.schoolnamestittle b {
  color: #333 !important;
  font-size: 18px !important;
  line-height: 26px !important;
  text-align: center;
}

.clasessnamestittle img,
.schoolnamestittle img {
  margin-right: 15px;
  height: 50px;
  width: auto;
}

.gotacenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}

.clasessnamesmodal.h-10 {
  height: 100vh !important;
}

.clasessnamesmodal {
  /* background: #3f58de !important; */
  background: #fff !important;
  box-shadow: none !important;
  height: 100vh !important;
  margin: 0 !important;
  width: 100vw;
  min-width: 100vw !important;
  display: flex;
  border-radius: 0px;
  justify-content: flex-start;
  align-items: center;
  position: fixed !important;
  left: 0;
  top: 0;
  overflow-y: hidden !important;
}

.clasessnamesmodal .school-teacher-name-wrap p,
.clasessnamesmodal .school-teacher-name-wrap h3 {
  color: #000;
}


.threedots-css2.w-100 {
  display: flex;
  justify-content: center;
  position: absolute;
  margin: 0;
  bottom: -60px;
  left: 0;
}

.clasessnamesmodal .toplevelimg {
  height: auto;
  width: 25%;
  position: fixed;
  bottom: -9px;
  right: 0px;
}

.clasessnamesmodal .react-responsive-modal-closeButton {
  background: #fff;
  padding: 10px;
  opacity: 0 !important;
}

.clasessnamesmodal .logout svg {
  margin-right: 10px !important;
}

.clasessnamesmodal .logout {
  padding: 1.1vh 15px;
  padding-left: 15px;
  margin: 6px 0px;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  font-family: "Gotham Rounded";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  left: calc(50% - 65px);
  width: 140px !important;
  /* position: absolute !important; */
  z-index: 9;
  border-radius: 10px;
}

.flex-selecter-t {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.ant-input::-webkit-input-placeholder {
  color: #666 !important;
  font-weight: 600;
}

.music-session-data .ant-calendar-picker-input.ant-input,
.ant-time-picker input,
.ant-calendar-picker input {
  background: #fff;
  border-radius: 4px !important;
  overflow: hidden;
  border: 1px solid #d6d6d6 !important;
  color: #000;
  font-weight: 400;
}

.music-session-data .ant-calendar-picker svg,
.ant-time-picker-icon svg,
.music-session-data .ant-calendar-picker input #placeholder {
  color: #000 !important;
}

.ant-time-picker-input::-webkit-input-placeholder,
.music-session-data .ant-calendar-picker input::-webkit-input-placeholder {
  /* Edge */
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: 'Gotham Rounded' !important;
  color: #000 !important;
}


.ant-time-picker-input:-ms-input-placeholder,
.music-session-data .ant-calendar-picker input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: 'Gotham Rounded' !important;
  color: #000 !important;
}

.ant-time-picker-input::placeholder,
.ant-time-picker input,
.ant-calendar-picker input,
.music-session-data .ant-calendar-picker input::placeholder {
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: 'Gotham Rounded' !important;
  color: #000 !important;
}

.ant-calendar-picker {
  width: 100%;
}

.music-session-data button {
  height: 45px !important;
}

.timepicker-backdrop {
  z-index: 1 !important;
}

.btncoursesgroup {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  background-image: url(../images/smallbg.png);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: auto;
}

.btncourses svg {
  margin-right: 5px;
}

.btncourses {
  background: #fff;
  padding: 10px;
  width: 45%;
  max-width: 150px;
  text-align: center;
  border-radius: 7px;
  color: #000;
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 400 !important;
  font-family: 'Gotham Rounded' !important;
  color: #000 !important;
  cursor: pointer;
  text-decoration: none !important;
  border: none;
}

.btncourses:hover {
  background: #fff9;
}

.flex-selecter-t p {
  padding: 5px !important;
  margin-bottom: 0 !important;
}

.threedots-css {
  position: relative;
  text-align: center !important;
  color: #3f58de !important;
  display: block;
  width: 100%;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 999;
  height: 0;
}

.threedots-css svg {
  margin: auto !important;
}

.mainlogindesk input::placeholder,
.mainlogindesk input:-ms-input-placeholder,
.mainlogindesk input:-moz-placeholder,
.mainlogindesk input::-webkit-input-placeholder,
.mainlogindesk input div {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #666 !important;
  font-family: 'Gotham Rounded' !important;
}

/* .table-bordered td .editinput,
textarea {
  border: 2px solid #ccc !important;
  min-height: 40px !important;
  border-radius: 4px;
  font-size: 16px;
  padding: 15px;
} */

.table-bordered td .editinput, textarea {
  border: 1px solid #ccc !important;
  min-height: 40px !important;
  border-radius: 4px;
  font-size: 14px;
  padding: 10px;
  line-height: 16px;
  height: 55px !important;
}

.Student-details-data .editchecklabel {
  position: relative;
  top: 5px;
}

.instrument-flipbook .instrument img,
.instrument-flipbook .instrument-box-one {
  padding: 0;
  margin: 0;
  border-radius: 0;


}

.instrument-flipbook:hover {
  transform: scale(1.05);
  transition: 0.3s;
}

.instrument-flipbook .instrument-box-one h3 {
  margin: 15px 10px;
  font-size: 1.5vw;
  text-align: center;
}

.instrument-flipbook {
  background: #fff;
  padding: 0px;
  margin: 1%;
  border-radius: 8px;
  width: 31.1%;
  height: auto;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
  transform: scale(1);
  transition: 0.3s;
}

.editcheck {
  /* border: 1px solid #dee2e6 !important;
    background: #008000; */
  transform: scale(1.3);
  display: inherit;
}

.table-bordered td,
.table-bordered th {
  font-size: 16px !important;
  font-family: 'Gotham Rounded' !important;
  vertical-align: middle !important;
}

.editname b {
  font-size: 18px !important;
  color: #333 !important;
  font-family: 'Gotham Rounded' !important;
  text-transform: capitalize;
}

.box-wrap-lb-imports .editname.Email b {
  text-transform: initial;
}

.editname.Name b {
  text-transform: capitalize;
}

.editname.newName {
  font-size: 18px !important;
  color: #333 !important;
  font-family: 'Gotham Rounded' !important;
  text-transform: capitalize;
}



.lesson-down-btns,
.lesson-down-btns-student {
  background: #f7f8fc;
  border-radius: 8px;
  display: flex;
  height: auto;
  padding: 13px 25px;
  font-size: 16px;
  font-weight: 400 !important;
  align-items: center;
  margin: 5px 6px 5px 16px;
  justify-content: center;
  border: none;
  box-shadow: 0 1px 2px #666 !important;
}

.lesson-down-btns-student {
  width: 15vw !important;
  min-width: 200px;
}


 
.list-order-set{
  padding-left: 20px;
  list-style: circle;
  color: #000;
}
ul.list-order-set li {
  position: relative;
  padding-left:0px;
  margin-bottom: 10px;
  line-height: 1.5;
}

 

/* checkbox css3  */

input.editcheck {
  position: relative !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 2px 0px;
  box-sizing: content-box;
  overflow: hidden;
}

input.editcheck:before {
  content: '';
  display: block;
  box-sizing: content-box;
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  transition: 0.2s border-color ease;
}

input.editcheck:checked:before {
  border-color: #09c756;
  transition: 0.5s border-color ease;
}

input.editcheck:disabled:before {
  border-color: #ccc;
  background-color: #ccc;
}

input.editcheck:after {
  content: '';
  display: block;
  position: absolute;
  box-sizing: content-box;
  top: 50%;
  left: 50%;
  transform-origin: 50% 50%;
  background-color: #09c756;
  width: 16px;
  height: 16px;
  border-radius: 100vh;
  transform: translate(-50%, -50%) scale(0);
}

input[type="radio"]:before {
  border-radius: 100vh;
}

input[type="radio"]:after {
  width: 16px;
  height: 16px;
  border-radius: 100vh;
  transform: translate(-50%, -50%) scale(0);
}

input[type="radio"]:checked:after {
  -webkit-animation: toggleOnRadio 0.2s ease forwards;
  animation: toggleOnRadio 0.2s ease forwards;
}

input[type="checkbox"]:before {
  border-radius: 4px;
}

input[type="checkbox"]:after {
  width: 9.6px;
  height: 16px;
  border-radius: 0;
  transform: translate(-50%, -85%) scale(0) rotate(45deg);
  background-color: transparent;
  box-shadow: 4px 4px 0px 0px #09c756;
}

input[type="checkbox"]:checked:after {
  -webkit-animation: toggleOnCheckbox 0.2s ease forwards;
  animation: toggleOnCheckbox 0.2s ease forwards;
}

input[type="checkbox"].filled:before {
  border-radius: 4px;
  transition: 0.2s border-color ease, 0.2s background-color ease;
}

input[type="checkbox"].filled:checked:not(:disabled):before {
  background-color: #09c756;
}

input[type="checkbox"].filled:not(:disabled):after {
  box-shadow: 4px 4px 0px 0px white;
}

@-webkit-keyframes toggleOnCheckbox {
  0% {
    opacity: 0;
    transform: translate(-50%, -85%) scale(0) rotate(45deg);
  }

  70% {
    opacity: 1;
    transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
  }

  100% {
    transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
  }
}

@keyframes toggleOnCheckbox {
  0% {
    opacity: 0;
    transform: translate(-50%, -85%) scale(0) rotate(45deg);
  }

  70% {
    opacity: 1;
    transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
  }

  100% {
    transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
  }
}

@-webkit-keyframes toggleOnRadio {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }

  70% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0.9);
  }

  100% {
    transform: translate(-50%, -50%) scale(0.8);
  }
}

@keyframes toggleOnRadio {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }

  70% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0.9);
  }

  100% {
    transform: translate(-50%, -50%) scale(0.8);
  }
}



.completesesson {
  width: 250px !important;
  background: #F7F8FC;
  border-radius: 8px;
  display: flex;
  /* min-width:150px; */
  height: auto;
  padding: 13px 25px;
  font-size: 16px;
  font-weight: 400 !important;
  align-items: center;
  margin: 5px 6px 5px 16px;
  justify-content: center;
  border: none;
  box-shadow: 0px 1px 2px #666 !important;
}

.navbar-light .navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url(../images/menu.png);
  background-repeat: no-repeat;
  background-size: 28px auto;
}


.navbar-light .navbar-toggler .navbar-toggler-icon {
  background-image: url(../images/closed.png);
  background-repeat: no-repeat;
  background-size: 24px auto;
  /* background: red; */
}

.bg-red a {

  color: #333 !important;
  font-size: 16px;
}

.bg-red {
  color: #333 !important;
  /* font-weight: 400 !important; */
  font-size: 16px;
}

.lesson-not {
  line-height: 30px;
  margin-top: auto;
  float: left;
  display: flex;
  width: 100%;
  height: 90vh;
  align-items: center;
  justify-content: center;
}

/* .instrument-flipbook {
  width: 100%;
  transform: scale(0.95);
  transition: 0.3s;
} */

.instrument-flipbook .instrument-box-one h3 {
  font-size: 18px;
}

.maxvaluew {
  max-width: 300px;
}

.flexwraping{
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.box-shadow-lb select,
.box-shadow-lb input,
.box-shadow-lb input:hover,
.box-shadow-lb input:focus,
.box-shadow-lb input:active,
.box-shadow-lb .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu {
  border: 2px solid #000 !important;
  border-color: #000 !important;
  padding: 10px;
  height: auto;
  font-size: 18px;
  font-weight: 600;
  color: #666 !important;
  box-shadow: none;
  background: #fff !important;
}

.box-shadow-lb input,
.box-shadow-lb input:hover,
.box-shadow-lb input:focus,
.box-shadow-lb input:active,
.box-shadow-lb *::-webkit-input-placeholder,
.box-shadow-lb *::-moz-placeholder,
.box-shadow-lb *:-ms-input-placeholder,
.box-shadow-lb *:-moz-placeholder {
  background: #fff !important;
  color: red !important;
}


.filter-value.filter-value-import-data .MuiFormControl-root .css-yk16xz-control:hover,
.filter-value.filter-value-import-data .MuiFormControl-root .css-yk16xz-control:focus,
.filter-value.filter-value-import-data .MuiFormControl-root .css-yk16xz-control:visited,
.filter-value.filter-value-import-data .MuiFormControl-root .css-yk16xz-control:active,
.filter-value.filter-value-import-data .MuiFormControl-root .css-yk16xz-control {
  border: 2px solid #000 !important;
  border-color: #000 !important;
  box-shadow: none !important;
}

.box-shadow-lb .MuiFormControl-root input {
  border: 2px solid #0000 !important;
  border-color: #0000 !important;
}


.lb-header-top .mainlogindesk .MuiInputBase-input,
.MuiOutlinedInput-root {}


.mainlogindesk input::placeholder {
  color: #666 !important;
  font-weight: 600 !important;
  opacity: 1;
}

.mainlogindesk input:-ms-input-placeholder {
  color: #666 !important;
  font-weight: 600 !important;
}

.mainlogindesk input::-ms-input-placeholder {
  color: #666 !important;
  font-weight: 600 !important;
}


.box-shadow-lb .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(0px, -14px) scale(0.75) !important;
}

.shadow-lb-common {
  max-width: 500px !important;
  flex-basis: 500px !important;
}

.react-responsive-modal-modal .shadow-lb-common {
  max-width: 100% !important;
  flex-basis: 100% !important;
}


.hypertxt {
  text-decoration: underline !important;
  color: #000;
  cursor: pointer;
}


/* autosuggtion changes start */


.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__container input {
  width: 100%;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  text-transform: capitalize;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  max-height: 20vh;
  overflow: auto;
}


.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}





/* specially for signup css start */


.p-2.mainlogindesk .MuiFormControl-root .css-2613qy-menu {
  z-index: 99 !important;
}

.p-2.mainlogindesk .css-yk16xz-control {
  position: relative !important;
  z-index: 10 !important;
}

.p-2.mainlogindesk .css-2b097c-container {
  position: relative;
  box-sizing: border-box;
  z-index: 11;
  height: 60%;
}

/* specially for signup css end */


.autosuggestionschool .react-autosuggest__suggestions-container {
  z-index: 99 !important;
}


/* start certficate changes */


.outer-border {
  width: 900px;
  height: 640px;
  padding: 20px;
  text-align: center;
  border: 10px solid #fdbd10;
  border-style: double;
  margin: 50px 0px;
  font-family: 'Gotham Rounded';
}

.inner-dotted-border {
  width: 750px;
  height: 600px;
  padding: 20px;
  text-align: center;
}

.certification {
  font-size: 50px;
  font-weight: bold;
  color: #3F58DE;
  margin-top: 30px;
  display: block;
  line-height: 1;
}

.certify {
  font-size: 25px;
}

.name {
  font-size: 40px;
   color: #3f58de;
  font-family: 'Gotham Rounded';
}

.fs-30 {
  font-size: 30px;
  font-family: 'Gotham Rounded';
}

.fs-20 {
  font-size: 20px;
  font-family: 'Gotham Rounded';
}





/* exam css design */
.examserial {
  background: #3f58de;
  display: block;
  float: left;
  text-align: center;
  padding: 10px;
  color: #fff;
  margin: -18px 18px 0 0;
}

.questionbox {
  /* background: #f00; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
}

.questionbox .checkboxis {
  transform: scale(1.5);
  margin: 10px;
}

.timeremaining {
  position: static;
  display: flex;
  font-size: 20px;
  font-weight: 400;
  color: #333;
  padding: 10px;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: -140px;
}

.question-listing {
  background: #fff;
  border: 1px solid #eee;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 18px;
  text-align: left;
}



/* pagination css */

.pagination {
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  padding-left: 0;
  list-style: none;
  border-radius: .25rem;
  margin: 50px 0;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center
}

.pagination li a,
.pagination span {
  text-align: center;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 30px !important;
  font-size: 20px !important;
  line-height: 50px;
  border-radius: 0;

  color: #333;
  font-weight: 700;
  background: #fff;
  -webkit-box-shadow: 0 10px 27px -20px rgb(0 0 0/33%);
  box-shadow: 0 10px 27px -20px rgb(0 0 0/33%);
  margin: 1px !important;
  border: 1px solid #333;
}

.pagination a.active,
.pagination a:hover,
.pagination span.active,
.pagination span:hover {
  background: #3f58de !important;
  color: #fff !important;
  border: 1px solid #0000;
}

.page-item.active .page-link,
.pagination li:first-child a,
.pagination li:last-child a {
  z-index: 3;
  color: #fff !important;
  background-color: #3f58de !important;
  line-height: 30px !important;
  font-size: 20px !important;
  font-weight: 700;

  border: 1px solid #3f58de !important;
  outline: none;
  box-shadow: none;
}

.banner-Register-link {
  position: absolute;
  right: 25px;
  bottom: 25px;
  z-index: 0;
  font-size: small;
  padding: 21px 10px !important;
  opacity: 0;
  cursor: pointer;
}

.bandit button svg{
  background-color: red !important;

  }
.bandit button{
    position: absolute;
    top: -31px !important;
    right: 0px !important;
  }
.bandit{
  overflow-y: initial !important;
}

.modal-bodybox {
  position: relative;
}

.banner-Register-link {
  position: absolute;
  right: 1%;
  bottom: 5%;
  z-index: 0;
  font-size: small;
  padding: 26px 1px !important;
  opacity: 0;
  cursor: pointer;
  width: 97px !important;
}


@media (min-width:755px) and (max-width:960px) {
  .banner-Register-link {
    position: absolute;
    right: 31px;
    bottom: 71px;
    z-index: 0;
    font-size: medium;
    padding: 22px !important;
  }
 


  .login-player iframe {
    height: 80vh;
    max-height: 600px;
  }

  .portfolio .name {
    font-size: 35px !important;
}
.portfolio .from img {
  width: 50px;
  height: auto;
  padding: 5px;
  border-radius: 8px;
}

}

@media (min-width:640px) and (max-width:767px) {
  .banner-Register-link {
    position: absolute;
    right: 44px;
    bottom: 44px;
    z-index: 0;
    font-size: small;
    padding: 8px !important;
  }

  .login-player iframe {
    height: 80vh;
    max-height: 600px;
  }

  .portfolio .name {
    font-size: 35px !important;
}
.flexwraping{
  display: block;
}

.nxt.inactiveButton, .nxt.inactiveButton:visited, .nxt.inactiveButton:focus, .nxt.inactiveButton:hover {
  font-size: 14px;
  border-radius: 1px;
  padding: 4px 15px;
  width: 60px;
  height: 35px;
}
 
.prev.inactiveButton, .prev.inactiveButton:visited, .prev.inactiveButton:focus, .prev.inactiveButton:hover {
font-size: 14px;
 padding: 8px 15px;
width: 50px;
height: 35px;
}

.nxt.activeButton, .nxt.activeButton:visited, .nxt.activeButton:focus, .nxt.activeButton:hover,
.prev.activeButton, .prev.activeButton:visited, .prev.activeButton:focus, .prev.activeButton:hover {
  font-size: 14px;
   padding: 4px 15px;
  }
}

@media screen and (max-width:639px) {
  .banner-Register-link {
    position: absolute;
    right: 25px;
    bottom: 55px;
    z-index: 0;
    font-size: small;
    padding: 8px !important;
  }
  
.slick-next {
  right: 25px;
}
  .menucontrol{
    height: auto;
    overflow: inherit;
  }

  .menucontrol {
    min-height: 50vh;
    height: 51vh;
    overflow: auto;
} 
  .logout, .logout:hover {
    position: static !important;
    bottom: 4vh !important;
    width: 250px !important;
}
  .flexwraping{
    display: block;
  }
  .login-player iframe {
    height: 80vh;
    max-height: 600px;
  }


  .nxt.inactiveButton, .nxt.inactiveButton:visited, .nxt.inactiveButton:focus, .nxt.inactiveButton:hover {
    font-size: 14px;
    border-radius: 1px;
    padding: 4px 15px;
    width: 60px;
    height: 35px;
  }
   
  .prev.inactiveButton, .prev.inactiveButton:visited, .prev.inactiveButton:focus, .prev.inactiveButton:hover {
  font-size: 14px;
  padding: 4px 15px;
  width: 50px;
  height: 35px;
  }

  .nxt.activeButton, .nxt.activeButton:visited, .nxt.activeButton:focus, .nxt.activeButton:hover,
  .prev.activeButton, .prev.activeButton:visited, .prev.activeButton:focus, .prev.activeButton:hover {
    font-size: 14px;
     padding: 4px 15px;
     width: 50px
    }

}


@media screen and (max-width:480px) {
  .banner-Register-link {
    position: absolute;
    right: 5px;
    bottom: 19px;
    z-index: 0;
    font-size: small;
    padding: 8px !important;
  }

  .login-player iframe {
    min-height: 70vh;
    width: 92%;
  }

  .filter-value.row {
    margin: 0 !important;
  }
  .portfolio .from img {
    width: 60px;
  }

  .portfolio .name {
    font-size: 35px !important;
}

}

@media screen and (max-width:450px) {
  .banner-Register-link {
    position: absolute;
    right: 5px;
    bottom: 31px;
    z-index: 0;
    font-size: small;
    padding: 8px 2px !important;
  }
}

@media screen and (max-width:360px) {
  .banner-Register-link {
    position: absolute;
    right: 5px;
    bottom: 29px;
    z-index: 0;
    font-size: small;
    padding: 8px 2px !important;
  }
}

@media screen and (max-width:320px) {
  .banner-Register-link {
    position: absolute;
    right: 5px;
    bottom: 29px;
    z-index: 0;
    font-size: xx-small;
    padding: 4px 2px !important;
  }
}



@media only screen and (max-width: 960px) {

  .slick-next {
    right: 25px;
  }
  .pagination li a,
  .pagination span {
    width: auto;
    height: auto;
    line-height: 27px !important;
    font-size: 18px !important;
    margin: 1px !important;
    padding: 15px;
  }

  .lesson-list-home2 {
    background: #F7F8FC !important;
    margin: 30px 0px !important;
  }
}

@media only screen and (max-width: 480px) {
  .pagination {
    -webkit-justify-content: start;
    -moz-box-pack: start;
    justify-content: start;
    justify-content: center;
  }

  .pagination li a,
  .pagination span {
    width: auto;
    height: auto;
    line-height: 18px !important;
    font-size: 16px !important;
    margin: 2px !important;
    padding: 9px
  }
}


.table-responsive.exam tr td,
.table-responsive.exam th,
.table-responsive.exam thead th {
  text-align: center !important;
  vertical-align: middle !important;
}

.table-responsive.exam {
  width: 100%;
}

.scrollfix-setb {
  position: fixed !important;
  top: 0;
  background: #fff;
  transition: 1.5s;
  width: calc(100% - 330px);
  z-index: 999;
}

.create-exam {
  padding: 3vw !important;
  padding-bottom: 60px;
}

.create-exam input,
.create-exam .filter-value.filter-value-import-data {
  /* background-color: greenyellow !important; */
  border: 1px solid #d6d6d6 !important;
  border-color: #d6d6d6 !important;
  box-shadow: none !important;
  border-radius: 6px;
}

.create-exam input[type="radio"] {
  /* background-color: blue !important; */
  border: 0px solid #d6d6d6 !important;
}

.create-exam .ant-calendar-picker-input.ant-input {
  line-height: 1.5;
  height: 50px !important;
}

.create-exam .filter-value.filter-value-import-data .MuiFormControl-root .css-yk16xz-control * {
  border: 0px solid #d6d6d6 !important;
  border-color: #d6d6d6 !important;
  box-shadow: none !important;
}


.create-exam .filter-value.filter-value-import-data .MuiFormControl-root .css-yk16xz-control {
  border: 1px solid #d6d6d6 !important;
}


.assessmentbg-frame {
  background-image: url('../images/assessmentbg.png');
  background-size: 28px;
  background-repeat: repeat;

  /* Assessmentbg.png */
}

.assessmentbg-frame .card {
  background: #fff0 !important;
}

.assessmentbg-frame .scorebox {
  background: #d60000;
  padding: 10px 20px;
  display: inline-block;
  margin: 15px;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

.scoresbtn,
.scoresbtn b {
  color: #fff !important;
}

.serchelement input,
.serchelement select,
.serchelement input {
  /* background: red !important; */
  height: 32px !important;
}

.serchelement .MuiFormControl-root {
  margin: 0px !important;
  padding: 0px !important;

}

.timesetanimation {
  background: #fff;
  display: inline-block;
  padding: 0px;
  float: right;
}

.timesetanimation b {
  background: #fff;
  display: block;
  padding: 6px 10px;
  text-align: center;
  color: #333;
  font-weight: 500;
  font-size: 14px;
}

.timesetanimation p {
  text-align: center;
  color: #333;
  font-weight: 600;
  font-size: 17px;
}

.timesetanimation b span {
  background: #3F58DE;
  color: #fff;
  font-size: 2em !important;
  font-weight: bold !important;
  display: block;
  margin-bottom: -20px;
  float: initial;
}

.timeremaining.red .timesetanimation b span {
  background: red;
}

.selected-questions {
  /* background-color: #d6d6d6; */
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  display: block;
  padding: 5px;
  float: left;
  margin-top: -25px;
  color: white;

}

.selected-questions b {
  color: red;
  background-color: white;
  padding: 5px;
  margin: 5px;
  height: 20px;
  width: 20px;
}

/* media show grid format */

/* tile uploaded pictures */
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-inline-end: 8px;
}

.ant-upload-rtl.upload-list-inline .ant-upload-list-item {
  float: right;
}

.filter-value.filter-value-import-data .ant-upload.ant-upload-select {
  display: block;
}

.newtable-responsive .css-1s2u09g-control,
.newtable-responsive .css-1pahdxg-control {
  min-height: 50px !important;
  height: 50px !important;
}

.newtable-responsive.summary #react-select-34-placeholder,
.css-14el2xx-placeholder,
.newtable-responsive.summary input::placeholder,
.newtable-responsive.summary select::placeholder {
  font-size: 16px !important;
}

.newtable-responsive.summary .instrument-tools,
.newtable-responsive.summary .instrument-toolsbox {
  margin: 20px 0px 20px 10px !important;
  height: 46px !important;
  width: 50px !important;
}

.newtable-responsive.summary td b {
  font-weight: 400 !important;
  width: 100%;
}

.error-message {
  font-weight: 400 !important;
  color: red !important;
}

.form-control:focus,
.select:focus {
  box-shadow: none !important;
  border-color: #d6d6d6 !important;
}

.desktop-img {
  display: block;
  /* Default display for desktop */
}

.mobile-img {
  display: none;
  /* Default display for mobile */
}


/* Media query for mobile devices */
@media (max-width: 768px) {
  .desktop-img {
    display: none;
    /* Hide on mobile */
  }

  .mobile-img {
    display: block;
    /* Show on mobile */
  }
}

.lesson-right-slider {
  margin: 30px 20px;
  display: block;
  width: 100%;
  overflow: hidden;
  padding: 10px 50px !important;
}

.imgslider_fram {
  height: auto;
  width: 100%;
  /* background: #8BC3A3; */

}

.imgslider_fram img {
  display: block;
  border: 2px solid #3332;
  width: 100%;
  height: auto;
  padding: 15px;
}

.lesson-right-slider .slick-arrow {
  background: #3f58de !important;
  height: 45px;
  width: 45px;
  border-radius: 100px;
  overflow: hidden;
  z-index: 99 !important;
}
.slick-next, .slick-prev{
  top: 40%;
}
.slick-next:before,
.slick-prev:before {
  font-size: 34px;
  line-height: 0;
  opacity: .75;
  color: #fff;
  /* background: #3f58de !important; */
  
}
.Instrumentsbx .slick-disabled{
  opacity: 0.3 !important;
}
.slick-prev:before {
  content: "\2039" !important;
  left: 5%;
}

.slick-next:before,
[dir=rtl] .slick-prev:before {
  content: '\203A' !important;
  right: 5%;
}

.slick-prev {
  left: 25px;
}

.slick-next {
  right: 30px;
}

.Instrumentsbx .slick-arrow{
  height: 40px;
width: 40px;
background: #3F58DE;
line-height: 1;
border-radius: 100px;
z-index: 99; 
}
.lesson-right-slider .slick-slider video,
.lesson-right-slider .slick-slider img {
  border: 1px solid #fff;
  background: #fff;
  padding: 0;
  transform: scale(0.96);
  border-radius: 10px;
  width: 100%;
  margin: 30px auto;
  height: 30vh;
  min-height: 200px;
  object-fit: contain;
}


.icon-small svg,
.icon-small img,
.ant-tooltip-open svg {
  height: 30px;
  width: 30px;
  fill: #fff;
  color: #fff;
  margin-left: 5px;
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

.newwrap-rt {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;
  padding-right: 20px;
}

.box-nwtexam {
  border-radius: 25px;
  padding: 20px;
  background-color: #f2f2f2;
}

.box-nwtexam .rec-det-boxes {
  margin-left: 8px;
}

.box-nwtexam .content-box-lb span {
  color: #161616;
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  padding: 5px 0px;
  /* border-right: 5px solid #f2f2f2; */
  cursor: pointer;
}

.rec-intrm {
  /* Your span styles */
  position: relative;
  /* Add other styles as needed */
}

.tooltipexm {
  /* Tooltip styles */
  position: absolute;
  left: 0;
  transform: translateX(20%);
  padding: 5px;
  background-color: #333;
  color: white;
  border-radius: 4px;
  z-index: 99999;
  /* Additional styles for the tooltip */
}

.d-audiobox audio{
height: 30px;
}
.d-audiobox b {
  font-size: 14px;
  padding-left: 23px;
  text-transform: capitalize;
  color: #000;
}
  .d-audiobox{
  display: flex !important;
  flex-direction: column;
  background: #f1f3f4;
  padding: 10px;
  margin:0px 10px 0px 0px;
  text-align: left;
}
.lesson-topic-name-wrap{
  margin-bottom: 0px !important;
}

.justify-content-start.lesson-topic-name-wrap{
  background-color: #0000;
}

.lesson-down-time {
  background: #f06062;
}

.refbooksetup{
  position: relative;
}
.lessontitlefsm h4 {
  margin-top: 10px;
  color: #333;
  font-size: 22px;
  border-top: 1px solid #3333;
  padding-top: 10px;
}
.lessontitlefsm h4:first-child {
   border-top: 0px solid #3333;
 }

 .lessontitlefsm .list-group-item {
  position: relative;
  display: block;
  padding: 5px 10px;
  background-color: #fff;
  border: 0px solid rgba(0, 0, 0, .125);
  font-size: 16px;
  line-height: 19px;
  border: 1px solid #3333;
  margin: 4px;
}

.lessontitlefsm .list-group {
  display: inline-block;}


/* .lessontitlefsm .list-group-item::before {
  content: '';
  position: absolute;
  left: -5px;
  top: 14px;
  background: #333;
  height: 7px;
  width: 7px;
  border-radius: 10px;
} */


/* new profile roadmap */


.refbooksetup::before {
  content: '';
  position: absolute;
  bottom: 50px;
  background: #fff;
  width: 100%;
  height: 50px;
}

.roadmaploop .list-group {
  position: absolute;
  background: #0000;
  padding: 12px;
  border-radius: 0;
  height: 20px;
  width: 100px;
  text-align: center;
  line-height: 1;
  font-size: 2em;
}

.roadmap-img {
  height: 100px;
  width: 125%;
  transform: skew(45deg, 0deg);
  bottom: 0;
  position: absolute;
  left: -10%;
}

.roadmap-screen{
    left: -1px;
    position: absolute;
    width: 103%;
    bottom: -12px;
    z-index: -2;
    background-clip: content-box;
  }

.roadmaploop .list-group::before {
  content: '';
  position: absolute;
  bottom: -43px;
  width: 0px;
  height: 150px;
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 0px solid #3F58DE;
  left: 117px;
  z-index: 4;
  transform: rotate(180deg);
  border: 0px solid #3F58DE;
}
.roadmaploop .list-group::after {
   content: '';
   position: absolute;
   bottom: -72px;
   width: 150px;
   height: 68px;
   background: #0000;
   left: 130px;
   border-radius: 0;
  background-image: url('../images/redcar.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  z-index: 4;
}
.roadmaploop .list-group:nth-child(2)::after
{
  background-image: url('../images/bluecar.png');

}

.roadmaploop .list-group:nth-child(3)::after
{
  background-image: url('../images/yellowcar.png');

}

.usermapfsm {
  width: auto;
  height: 60px;
  background: #fff;
  padding: 5px;
  border-radius:0px;
  margin: 10px;
  z-index: 1;
}

.roadmaploop .list-group:nth-child(1) {
 left: 8.5%;
  top: 150px;
}

.roadmaploop .list-group:nth-child(2) {
 left: 40%;
  top: 150px;
}
.roadmaploop .list-group:nth-child(3) {
 left: 70%;
  top: 150px;
}

.roadmap {
  height: 100px;
  width: 104%;
  background-color: #3F58DE;
  background-image: url('../images/roadway.png');
  background-size: auto 100%;
  margin-left: -15px;
  animation: roadmove 130s linear infinite;
  -webkit-animation: roadmove 130s linear infinite;
  -moz-animation: roadmove 130s linear infinite;
  -o-animation: roadmove 130s linear infinite;
  position: absolute;
  top:185px;
}
.pollcss {
  width: 84px;
  position: absolute;
  left: -46px;
  bottom: -50px;
  z-index: -1;
}
.gradient-background {
  background-color: #fff;

  background: linear-gradient(to bottom, #fff, yellow);

  background: -webkit-linear-gradient(top, #fff, yellow);

  background: -moz-linear-gradient(top, #fff, yellow);

  background: -o-linear-gradient(top, #fff, yellow);

  background: -ms-linear-gradient(top, #fff, yellow);

  background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(yellow));
}


@keyframes roadmove {
  0% {
    background-position: 1500% 0px;
  }
  100% {
    background-position: 100% 0px;
  }
}

@-webkit-keyframes roadmove {
  0% {
    background-position: 1500% 0px;
  }
  100% {
    background-position: 100% 0px;
  }
}

@-moz-keyframes roadmove {
  0% {
    background-position: 1500% 0px;
  }
  100% {
    background-position: 100% 0px;
  }
}

@-o-keyframes roadmove {
  0% {
    background-position: 1500% 0px;
  }
  100% {
    background-position: 100% 0px;
  }
}


@keyframes roadmovetree {
  0% {
    background-position:  700% 0px;
  }
  100% {
    background-position: 100% 0px;
  }
}
.overscreen_roadmap{
  border: 0px solid red;
}
  .roadmaploop {
  position: relative;
  height: 300px;
  background-image: url('../images/roadwayscreen2css.png');
  animation: roadmovetree 30s linear infinite;
  -webkit-animation: roadmovetree 30s linear infinite;
  -moz-animation: roadmovetree 30s linear infinite;
  -o-animation: roadmovetree 30s linear infinite;
  background-size: 95% auto;
  background-position: left -59px;
  background-repeat: no-repeat;
}
  .roadmaploop .list-group span b {
    font-size: 35px;
    line-height: 35px;
    font-family: 'Gotham Rounded';
    font-weight: 700;
    color: #d60000;
    text-wrap: nowrap;
    transform: rotate(270deg);
    position: relative;
    background: #fff;
    display: block;
    top: -37px;
    width: 223px;
    height: auto;
    left: -133px;

}

.roadmaploop .list-group span {
  padding: 0;
  background: #fff0;
  width: 62px;
  height: 85px;
  text-align: center;
  color: #3F58DE;
  font-size: 16px;
  line-height: 20px;
  font-family: 'Gotham Rounded';
  font-weight: 300;
  position: absolute;
  left: 87px;
  bottom: 1px;
  z-index: 2;
  border-radius: 0px;
  border-top: 0px solid #3F58DE;
}
.roadmaploop .linksd a img{
border: 0px solid #3333;
background: #eee0;
padding: 5px;
height: 40px;
width: auto;
opacity: 0;
}
.roadmaploop .linksd a {
  padding: 0px;
  background-image: url('../images/btn11.png');
  display: block;
  position: relative;
  margin: 0;
  transform: translate3d(0px, -100px, 100px) scale(1);
  border: 0px solid #3f58de;
  font-size: 14px;
  width: 262px;
  border-radius: 0;
  text-align: left;
  background-size: 265px 50px;
  background-repeat: no-repeat;
  background-position: left;
  color: #3f58de;
  font-weight: bold;
  text-align: left;
}

.roadmaploop .linksd a strong {
  display: inline-block;
  text-align: center;
  width: calc(100% - 65px);
  font-weight: 500;
}

.linksd {
  position: relative;
  top: -126px;
  left: 203px;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.roadmaploop .linksd a:nth-child(1) {
  top: 0px;
  left: -35px;
}

.roadmaploop .linksd a:nth-child(2) {
  top: 28px;
  left: -35px;
}

.roadmaploop .linksd a:nth-child(3) {
  top: 37px;
  left: -35px;
}

.roadmaploop .linksd a:nth-child(4) {
  top: 46px;
  left: -35px;
}






/* new profile roadmap */


.refbooksetup::before {
  content: '';
  position: absolute;
  bottom: 50px;
  background: #fff;
  width: 100%;
  height: 50px;
}

.roadmaploop .list-group {
  position: absolute;
  background: #0000;
  padding: 12px;
  border-radius: 0;
  height: 20px;
  width: 100px;
  text-align: center;
  line-height: 1;
  font-size: 2em;
}
/*
.roadmap-img {
  height: 100px;
  width: 125%;
  transform: skew(45deg, 0deg);
  bottom: 0;
  position: absolute;
  left: -10%;
}
.roadmaploop .list-group::before {
  content: '';
  position: absolute;
  bottom: -43px;
  width: 0px;
  height: 150px;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 30px solid #3F58DE;
  left: 117px;
  z-index: 4;
  transform: rotate(180deg);
  border: 1px solid #3F58DE;
}
.roadmaploop .list-group::after {
  content: '';
  position: absolute;
  bottom: -75px;
  width: 40px;
  height: 40px;
  background: #3F58DE;
  left: 5vw;
  border-radius: 100px;
} */

.usermapfsm {
  width: auto;
  height: 60px;
  background: #fff;
  padding: 5px;
  border-radius:0px;
  margin: 10px;
  z-index: 1;
}

.roadmaploop .list-group:nth-child(1) {
 left: 8.5%;
  top: 150px;
}
.roadmaploop .list-group:nth-child(2) {
 left: 40%;
  top: 150px;
}
.roadmaploop .list-group:nth-child(3) {
 left: 70%;
  top: 150px;
}

  .roadmaploop {
  position: relative;
  height: 300px;
}
  .roadmaploop .list-group span b {
    font-size: 35px;
    line-height: 35px;
    font-family: 'Gotham Rounded';
    font-weight: 700;
    color: #d60000;
    text-wrap: nowrap;
    transform: rotate(270deg);
    position: relative;
    background: #fff;
    display: block;
    top: -37px;
    width: 223px;
    height: auto;
    left: -133px;

}

/* .roadmaploop .list-group span {
  padding: 0;
  background: #fff;
  width: 62px;
  height: 85px;
  text-align: center;
  color: #3F58DE;
  font-size: 16px;
  line-height: 20px;
  font-family: 'Gotham Rounded';
  font-weight: 300;
  position: absolute;
  left: 87px;
  bottom: 1px;
  z-index: 2;
  border-radius: 0px;
  border-top: 2px solid #3F58DE;
} */
.roadmaploop .linksd a img{
border: 1px solid #3333;
background: #eee;
padding: 5px;
height: 40px;
width: auto;
}
/* .roadmaploop .linksd a {
  padding: 0px;
  background: #ffffff;
  display: block;
  position: relative;
  margin: 0;
  transform: translate3d(0px, -100px, 100px) scale(1);
  border: 2px solid #3f58de;
  font-size: 16px;
  width: 240px;
  border-radius: 0;
  text-align: left;
}

.roadmaploop .linksd a strong {
  display: inline-block;
  text-align: center;
  width: calc(100% - 40px);
  font-weight: 500;
}

.linksd {
  position: relative;
  top: -126px;
  left: 203px;
  display: flex;
  flex-direction: column;
  align-items: center;
} */
/* .roadmaploop .linksd a::before {
  content: '';
  position: absolute;
  left: -48px;
  top: 50%;
  height: calc(100% + 60px);
  width: 2px;
  background: #3f58de;
  z-index: -3;
}

.roadmaploop .linksd a::after {
  content: '';
  position: absolute;
  left: -20%;
  top: 50%;
  height: 2px;
  width: 20%;
  background: #3f58de;
  z-index: -2;
}
.roadmaploop .linksd a:nth-child(1)::before {
  height: 200px;
}
.roadmaploop .linksd a:nth-child(1) {
  top: 0;
  left: 0;
}

.roadmaploop .linksd a:nth-child(2) {
  top: 10px;
  left: 30px;
}

.roadmaploop .linksd a:nth-child(3) {
  top: 20px;
  left: 60px;
} */

.homelayout .left-clbox.principal {

  flex-grow: 0;

  flex-shrink: 0;

  flex-basis: 100%;

  max-width: 100%;

  margin-right: 0px !important;

}



.principal .instrument-name-progress-boxes {

max-width: 15.4vw;

min-width: 260px;

}


.Toastify{
  position: absolute;
  z-index: 999;
  left: calc(50% - 150px);
  top: 0px;
}
/* toast ui css satrt and end */ 
.Toastify__toast-container {
  font-family: Arial, sans-serif;
}

/* Customizing individual toasts */
.Toastify__toast {
  /* background-color: #1e90ff; */
  color: #fff;
  border-radius: 8px;
}

/* Customizing close button */
.Toastify__close-button {
  color: #fff;
}

/* Customizing progress bar */
.Toastify__progress-bar {
  background: #ff4500;
}
/* custom-toast.css */
.custom-success-toast {
  background-color: #4caf50 !important;
  color: #fff !important;
}

.custom-error-toast {
  background-color: #f44336 !important;
  color: #fff !important;
}
 
.custom-progress-bar {
  background-color: #ffeb3b !important;
}
.Toastify__toast-body { 
  min-width: calc(100% - 30px) !important;
}


