







@media (min-width: 1921px) and (max-width: 4000px) {
  body {
    width: 1920px;
    margin: auto !important;
  }
}


@media (min-width: 1280px) and (max-width: 4000px) {
  nav ul li {
    display: inline-block;
    padding: 16px 10px;
  }
  .fix-menu-lb .navbar-nav a, .fix-menu-lb-two .navbar-nav a {
    padding: 0.6vh 8px;
    font-size: 14px; 
    margin: 1px 0;
  }
}

@media screen and (max-width: 1681px) {
  /* .marktable_att{
  min-width: 1400px;
  } */
  .left-box-lb {

    width: 67%;
  }
  .detail-row div >div {
     padding-bottom: 5px !important;
}
  .lesson-right-value video,.lesson-right-value iframe {
    height: 30rem !important;
    width: 52rem !important;
  }

  .bars-listed .bars-ele {
    font-size: 14px;

  }
  .exit-assignment {
    position: relative;
    top: 3vh;
    width: 100%;
}
 

  .bars-listed {
    /* margin-left: 30px; */
  }

  .bars-listed .bars-ele .control-logo {
     right: -60px;
   }
   .instrument-box-one .instrument {
    height: auto;
    /* min-height: 140px; */
}
.content-box-lb {
    width: 90%;
}
.fix-menu-lb .navbar-nav a,.fix-menu-lb-two .navbar-nav a {
  padding:0.80vh 15px; 
  font-size: 14px;
}
/* .box-wrap-lb-imports .col-lg-2 {
  flex: 0 0 24% !important;
  max-width: 24% !important;
} */

.activity-title-concepts.title ,.activity-title.title{
   padding: 15px 20px;
}
/* .studentlogout {
   bottom: -50vh;
} */
.logout, .logout:hover { 
  /* bottom: -23vh !important; */
}
.fix-menu-lb .navbar-nav a, .fix-menu-lb-two .navbar-nav a {
  padding: 1vh 15px;
  font-size: 14px !important; 
  margin: 1px 0;
}
 
}

@media screen and (max-width: 1560px) {
  .logout, .logout:hover { 
    /* bottom: -18vh !important; */
  }
  .fix-menu-lb .navbar-nav a, .fix-menu-lb-two .navbar-nav a {
    padding: 0.9vh 15px;
}
.fix-menu-lb .navbar-brand,
.fix-menu-lb-two .navbar-brand {
   margin-bottom: 10px;
 }
 .row-performance .col-lg-4{
  /* max-width: 250px !important; */
}
 .lesson-right-value video,.lesson-right-value iframe {
  height: 24rem !important;
  width: 41rem !important;
}
.exit-assignment {
  position: relative;
  bottom: 1vh;
  width: 100%;
}
.box-wrap-lb-imports .col-lg-2, .box-wrap-lb-imports .col-lg-3, .row-performance .col-lg-2 {
  min-width: 15%!important;
  margin-bottom: 15px;
} 
.roadmaploop .list-group {
   transform: scale(0.7);
}
}

  @media screen and (max-width: 1440px) {
    .prog-min{
      width: 250px !important;
  }
  .filter-wrap-fix .MuiFormControl-root {
    min-width: 200px !important;
    width: 250px !important;
  }
  .prog-min{
    width: 250px !important;
}
  .menu-link-tio {
    min-width: 255px;
  }

  
.exit-assignment { 
  margin-top: 0px;
}
  .lesson-topic-name-wrap {
     margin-bottom: 8vh;
  }
.exit-btnjump {
   bottom: 5vh;
}
  
  .filter-wrap-fix .col-lg-2,
  .filter-wrap-fix .col-lg-3 {
    flex: 0 0 26.666667% !important;
    max-width: 26.666667% !important;

  }
  .prog-min .ant-progress-line {
    position: relative;
    width: 100%;
    min-width: 150px; 
}
  .gadefull-pixel {
    width: 33%!important;
    max-width:33%!important;
    flex: 0 0 33% !important;
}
.gadefull-pixel-date {
  width: 32%!important;
  max-width: 32%!important;
  flex: 0 0 32% !important;
}
  .lesson-right-value video,.lesson-right-value iframe {
    height: 23rem !important;
    width: 39.5rem !important;
    box-shadow: 0px 0px 0px 15px #e6e6e6;
  }
  .instrument-name-progress-boxes { 
    width: calc(50% - 20px);
  }
  .lesson-list-wrap {
    padding-left: 0;
    list-style: none;
    padding: 5px 25px; 
    overflow-y: scroll;
    padding-bottom: 20px !important;
}
.content-box-lb {
  width: 70%;
}
.video-player iframe {
  height: 20rem !important;
  min-height: 15rem;
  max-height: 500px;
  width: 35rem !important;
}

.fix-menu-lb .navbar-nav a,.fix-menu-lb-two .navbar-nav a {
  padding:1.5vh 15px; 
}
.video-player {
  width: 40vw !important;
}
/* .homelayout .col-lg-9 {
margin-right: 3%;} */

.school-search {
  max-width: 60%;
  flex: 0 0 60%;
}
.song-namest4{
  width: 36%;
  max-width: 250px;
}
 
.fix-menu-lb .navbar-nav a, .fix-menu-lb-two .navbar-nav a {
  padding: 1vh 15px;
  font-size: 14px; 
  margin: 2px 0;
}
/* .instrument-box-one .instrument {
  height: 130px;
  min-height: 130px;
} */
.instrument-box-one h3 {
  font-size: 140%;
  line-height: 26px;
  min-height: 60px; 
 }

 .homelayout .left-clbox {
   flex-basis: calc(100% + -440px);
  max-width: calc(100% + -440px);
  margin-right: 0px !important;
  padding-right: 0px;
}
/* .left-clbox.col .p-4{
  padding-right:5px !important;
} */
.row-performance .col-lg-2 {
  min-width: 33% !important;
  margin-bottom: 25px;
}
.fix-menu-lb-two .navbar-nav a {
  font-size: 14px !important;
  margin: -2px !important;
  padding-left: 7px !important;
  display: block !important;
}
.fix-menu-lb .navbar-nav a svg,
.fix-menu-lb-two .navbar-nav a svg{ 
  width: 15px;
}
.t-tips-lb .nav-item:first-child a {
   padding: 15px 10px;
   font-size: 16px;
   line-height: 22px;
}
.activity-title-concepts.title h2 ,.activity-title.title h2{
  font-size: 20px;
  line-height: 1.2 !important;
  }
  .concepts-excercise-value p {
    font-size: 14px; 
    line-height: 20px;
  }

  .customModalLessonPage .video-player, .newrcustomModal .video-player, .customModalLessonPage .video-player iframe, .newrcustomModal .video-player iframe {
    width: 36rem !important;
    height: 21rem !important;
  }
  .filter-wrap-fix-homework button {
     padding: 15px 10px;
     min-width: 150px;
  }
  .detail-row div >div {
     padding-bottom: 5px;
}
.logout,
.logout:hover {
   /* bottom: -24vh !important;  */
}
/* .studentlogout {
  bottom: -50vh;
} */

.video-frame-full {
  min-height: 500px;
}
.lesson-topic-name-wrap {
   margin-bottom: 1vh;
}

.calender-timeslote.normal {
   top: -30px;
 }
 .fix-menu-lb .navbar-brand, .fix-menu-lb-two .navbar-brand {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.fix-menu-lb .navbar-brand svg, .fix-menu-lb-two .navbar-brand svg {
  transform: scale(1.1) !important;
}
.events__title-upcoming {
   font-size: 16px;
   margin-bottom: 0px;
 }
 .editname b {
  font-size: 14px !important;
 }
 .table-bordered td, .table-bordered th {
  font-size: 14px !important;
  font-family: 'Gotham Rounded' !important;
}
.signuppoup-modal{
  top: 50px;
}

.menucontrol {
  min-height: 45vh;
  height: 65vh;
  overflow: auto;
}
 
}


@media screen and (max-width: 1366px) {

  .lesson-topic-name-wrap {
     min-width: 400px;
  }
  .select-instument-show h3 {
    font-size: 16px;
    line-height: 18px;
}

.lesson-topic-name-wrap {
  margin-bottom:2vh;
}
.lesson-right-value h3 {
  font-size: 18px !important;
  line-height: 24px !important;
  font-weight: 500 !important;
}

  .exit-assignment {
     /* width: calc(100% - 350px); */
      transition: 0.5s;
  }
  .lessons-top-display {
     width: calc(100% - 350px);
}
.filter-wrap-fix-homework button {
  padding: 15px 10px;
  min-width: 150px;
}

  .wrapper-lb-new .exit-assignment {
    width: calc(100% - 50px); 
    transition: 0.5s;
 }
  .lesson-list {
    width: 350px;
  }
  .logout,
  .logout:hover {
     /* bottom: -20vh !important;  */
  }
  .instrument-box-one .instrument {
    height: 130px;
    /* min-height: 140px; */
  }
  .lesson-m-panel {
    width: calc(100% - 350px);
  }

  .custom-progressed {
    width: 100%;
  }

  .select-instument-show {
    padding: 25px;
  }

  .lesson-list-wrap {
    padding: 5px 25px;
  }

  .lesson-list-wrap li {
    padding: 10px 5px;
  }

  .filter-wrap-fix .MuiFormControl-root {
    min-width: 150px !important;
    width: 100% !important;
  }

  .filter-wrap-fix .col-lg-2,
  .filter-wrap-fix .col-lg-3 {
    flex: 0 0 24.666667% !important;
    max-width: 24.666667% !important;

  }
  .fix-menu-lb .navbar-nav a, .fix-menu-lb-two .navbar-nav a {
    padding: 1vh 15px;
    font-size: 14px; 
    margin: 2px 0;
}
  .menu-link-tio {
    min-width: 192px;
  }
  .menucontrol { 
    height: 50vh; 
}

  .wrapper-assgnment-lb .col-lg-4.col-md-6.col-sm-10 {
    min-width: 500px;
  }
  .video-player iframe {
    height: 17.6rem !important;
    min-height: 15rem;
    max-height: 500px;
    width: 30rem !important;
    background: #000;
  }
.school-teacher-name-wrap h3 { 
  font-size: 35px;
}
.importdata .col{
  max-width: 50%;
  width: 50%;
  min-width: 335px;
}
/* .studentlogout {
  bottom: -50vh;
} */
/* .box-wrap-lb-imports .col-lg-4{
  flex: 0 0 50% !important;
  max-width: 50% !important;
} */
.music-session-data {
   padding: 0 0px 0 30px;
 }
 .exit-lbs span,
 .exit-lbs a{
   font-size: 14px !important;
 }
 /* .homelayout .left-clbox {
   flex-basis: calc(100% + -360px);
  max-width: calc(100% + -360px);
 }
 .homelayout .right-clbox {
    flex: 0 0 330px;
    max-width: 330px;
}
.calende-multi-function {
   max-width: 325px;
}
.instrument-detail-box {
  flex: 0 0 68%;
  max-width: 68%;
} */
.lesson-down-btns {
  display: flex;
  padding: 10px 20px;
 font-size: 14px;
  margin: 5px 15px;
 justify-content: center;
 border: none;
 width: 160px !important;
}
 
.full-right-control .lesson-right-value {
   min-height: 72vh;
}
.right-input-lb-wrap {
  width: 250px;
  min-width: 250px;
 }
 .instrument-box-one .instrument {
  height:auto;
  max-height: auto;
}
.box-wrap-lb-imports .col-lg-2, .box-wrap-lb-imports .col-lg-3, .row-performance .col-lg-2 {
  min-width: 33%!important;
 }
.box-wrap-lb-imports .d-flex{
  flex-wrap: wrap;
}
 .box-wrap-lb-imports .mb-3.form-group.col-lg-6.col-md-6, .box-wrap-lb-imports .mb-3.form-group.col-lg-6.col-md-6, .row-performance .mb-3.form-group.col-lg-6.col-md-6
 {
  min-width: 100%!important;
  
 }
 
.table-responsive-mobile{
  overflow: auto;
}
 
}


@media  (min-width: 1024px) and (max-width:1200px){
  .left-clbox.col .p-4 {
    padding: 15px !important;
    
}





.fix-menu-lb .navbar-nav a, .fix-menu-lb-two .navbar-nav a {
  padding: 1vh 15px;
  font-size: 14px;
  margin: 2px 0;
}

.calende-multi-function {
  max-width: 700px !important;
}

.calende-multi-function {
  height: 100%;
  padding: 10px;
  /* max-width: 425px; */
  /* float: right; */
  /* background: rgba(233, 232, 252, 0.3) !important; */
} 

.events-container {
  padding: 0 0 0 0px;
}

.events__tag, .events__item--left span:nth-child(2){
  font-size: 12px;
}

.events__name {
  font-size: 12px;
}


.events__title-upcoming {
  font-size: 18px;
}

.fix-menu-lb-two .navbar-nav a {
  padding: 1vh 15px;
  font-size: 14px;
  margin: 2px 0;
}

.prog-min {
  width: 200px !important;
}

.content-box-lb h4 {
  font-size: 18px;
}

.right-input-lb-wrap {
  width: 250px;
  min-width: 200px;
}

.MuiFormControl-root *, .MuiList-root.MuiMenu-list * {
  font-size: 15px !important;
}

.content-box-lb span {
  font-size: 14px;
}

.lesson-right-value video, .lesson-right-value iframe {
  height: 22rem !important;
  width: 35rem !important;
}
}

@media (min-width: 768px) and (max-width: 1024px) {

  .school-teacher-name-wrap h3 {
    font-size: 28px;
}
.refbooksetup::before {
   bottom: 0px;
   width: 100%;
   height: 100px;
}

/* .ac-box-arrow{
  visibility: visible;
} */

.school-teacher-name-wrap p {
  font-size: 14px;
}
  .calender-timeslote.normal{
    position:static;
  }

  .events__title-upcoming {
    font-size: 24px;
  }
  
  .events__name {
    font-size: 15px;

  
}

.events__tag, .events__item--left span:nth-child(2) {
  font-size: 14px;
}

.framel {
  display: inline-block;
  width: 100%;
}
.calender-timeslote.normal {
  top: -30px;
  position: static;
}

.mb-0.select-gap{
  margin:10px !important;
  margin-left: 5px;
}

}

	
@media  (min-width: 1024px) and (max-width:1200px){
  .left-clbox.col .p-4 {
    padding: 15px !important;
    
}
.fix-menu-lb .navbar-nav a, .fix-menu-lb-two .navbar-nav a {
  padding: 1vh 15px;
  font-size: 14px;
  margin: 2px 0;
}
.calende-multi-function {
  max-width: 700px !important;
}
.calende-multi-function {
  height: 100%;
  padding: 10px;
  /* max-width: 425px; */
  /* float: right; */
  /* background: rgba(233, 232, 252, 0.3) !important; */
}
.events-container {
  padding: 0 0 0 0px;
}
.events__tag, .events__item--left span:nth-child(2){
  font-size: 12px;
}
.events__name {
  font-size: 12px;
}
.events__title-upcoming {
  font-size: 18px;
}
.fix-menu-lb-two .navbar-nav a {
  /* font-size: 22px !important; */
}
.prog-min {
  width: 200px !important;
}
.content-box-lb h4 {
  font-size: 18px;
}
.right-input-lb-wrap {
  width: 250px;
  min-width: 200px;
}
.MuiFormControl-root *, .MuiList-root.MuiMenu-list * {
  font-size: 15px !important;
}
.content-box-lb span {
  font-size: 14px;
}
.lesson-right-value video, .lesson-right-value iframe {
  height: 22rem !important;
  width: 35rem !important;
}
}
@media (min-width: 768px) and (max-width: 1024px) {
  .school-teacher-name-wrap h3 {
    font-size: 28px;
}
.school-teacher-name-wrap p {
  font-size: 14px;
}
  .calender-timeslote.normal{
    position:static;
  }
  .events__title-upcoming {
    font-size: 24px;
  }
  
  .events__name {
    font-size: 15px;
  
}
.events__tag, .events__item--left span:nth-child(2) {
  font-size: 14px;
}
.framel {
  display: inline-block;
  width: 100%;
}
}

@media (min-width: 965px) and (max-width: 1280px) {
  .filter-value .MuiFormControl-root {
    width: 100%;
    padding: 0 !important;
    margin: 5px 0 !important;
  }

  .create-exam .filter-value .MuiFormControl-root {
    margin: 0px 0 !important;
}

  .calender-timeslote.normal {
    position: static;
    top: 0;
    background: #fff;
    transition: 1.5s;
}

  .instrument-detail-box {
      flex: 0 0 69%;
      max-width: 69%; 
  }
  .box-wrap-lb-imports .col-lg-4 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .right-input-lb {
    width: 100% !important;
    padding: 0;
    max-width: 100% !important;
}
.hidden-tab{
  display: none;
}
.row-buttopnnew {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.row-performance .col-lg-2 ,.box-wrap-lb-imports .col-lg-2,.box-wrap-lb-imports .col-lg-3{
  min-width: 33% !important;
  margin-bottom: 25px;
}
.lesson-down-btns {
   display: flex;
   padding: 10px 20px;
  font-size: 14px;
   margin: 5px 10px;
  justify-content: center;
  border: none;
  width: 140px !important;
}

.lesson-topic-name-wrap .d-flexlession{
justify-content: start;
}
.lesson-right-value h3{
  text-align: left;
}
.box-wrap-lb-imports .col-lg-2, .box-wrap-lb-imports .col-lg-3, .row-performance .col-lg-2 {
  min-width: 33%!important;
  margin-bottom: 25px;
}
.right-input-lb-wrap {
  width: 190px;
  flex-direction: row;
  padding: 1px;
  min-width: 200px;
  margin-left: 0px !important;
}

.lesson-topic-name-wrap .lesson-down-btns,.lesson-topic-name-wrap .Practice-btn {
  transform: scale(0.95);  
}

.lesson-m-panel.full-right-control .lesson-right-value .lessons-top-displays{
flex-direction: column;
display: flex;
align-items: flex-start;
}

.row-buttopnnew {
float: unset;
}

  .fix-menu-lb .navbar-brand .m-4 {
    margin: 22px 0rem !important;
  }
  .homelayout .left-clbox {
    flex-basis: calc(100% + 0px);
    max-width: calc(100% + 0px);
}

  .ant-select.ant-fullcalendar-year-select {
    min-width: 50% !important;
    margin-bottom: 10px;
  }

  .ant-select.ant-fullcalendar-month-select {
    min-width: 50% !important;
    margin-left: -1px !important;
  }

  .lesson-right-value video,.lesson-right-value iframe {
    height: 19rem !important;
    width: 32rem !important;
    box-shadow: 0px 0px 0px 15px #e6e6e6;
  }

  .filter-wrap-fix .col-lg-2,
  .filter-wrap-fix .col-lg-3 {
    flex: 0 0 32% !important;
    max-width: 32% !important;

  }

  .video-frame-full {
    min-height:400px;
  }
  .import-box-lb {

    min-height: 220px;
  }
  .framel{
     width: 49%;
  }
 
  .instrument-box-one .instrument {
      height: auto;
  }
  .instrument-name-progress-boxes { 
    width: calc(50% - 20px);
  }
  .homelayout .right-clbox {
    flex: 0 0 98%;
    max-width: 98%;
}
.calende-multi-function {
   max-width: 500px;
 }
 .importdata .col{
  max-width: 50%;
  width: 50%;
  min-width: 350px;
}

.logout, .logout:hover {
  /* bottom: -20vh !important; */
}
.instrument-box-one {
   margin: 5px 20px;
}
.gotacenter svg {
  height: 60px;
}
.gotacenter{
  margin-top: -30px !important;
}
.fix-menu-lb .navbar-brand svg, .fix-menu-lb-two .navbar-brand svg {
  transform: scale(1) !important;
}
.fix-menu-lb .navbar-brand, .fix-menu-lb-two .navbar-brand {
  margin-bottom: 20px;
  padding-top: 5px !important;
  padding-bottom:5px !important;
}

.fix-menu-lb .navbar-brand, .fix-menu-lb-two .navbar-brand {
  margin-bottom: 20px;
}

}
 
@media screen and (min-height:1200px) {
  /* .studentlogout {
     bottom:calc(-100vh + 15vh);
  }  */
 
.calender-timeslote.normal {
  position: static;
}
.newrcustomModal h4 {
   font-size: 18px;
  line-height: 24px;
}
  }
 
  
  @media screen and (max-width:1200px) {
 
  .calender-timeslote.normal {
    position: static;
  }
  .video-frame-full {
    min-height:400px;
  }
  .box-wrap-lb-song .right-input-lb-wrap {
    max-width: 230px !important;
    width:230px !important;
  }
  .lesson-right-value video,.lesson-right-value iframe {
    height: 28vw !important;
    width: 47vw !important;
  }

  .roadmaploop .list-group {
    transform: scale(60%);
  }
  .roadmaploop .list-group:nth-child(1) {
    left: 1.5vw;
   }
   .roadmaploop .list-group:nth-child(2) {
    left: 22vw;
   }
   .roadmaploop .list-group:nth-child(3){
    left: 43vw;
   }
  
    }
 
    

@media screen and (max-height:600px) {
  /* .studentlogout {
   bottom: -35vh;
} */
.logout,
.logout:hover { 
  /* bottom: -18vh !important;  */
}


}


@media screen and (max-height: 500px) {
  .logout,
  .logout:hover {
    /* position: absolute;
    bottom: -12vh !important;  */
  }
  /* .studentlogout {
    bottom: -32vh;
 } */
}



@media screen and (max-width:960px) {
.homelayout .left-clbox {
  flex-basis: calc(100% + 0px);
  max-width: calc(100% + 0px);
  padding: 15px;
}
.homelayout .right-clbox {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 15px;
  margin: 0 15px;
}
.calende-multi-function {
  max-width: 70%;
}
.progress-value {
  padding: 0 20px;
  flex-wrap: wrap !important;
  flex-direction: column; 
}
.mail .date {
   /* width: 220px; padding: 0; */
   margin-right: 5px !important;
  }
   .mail.portfolio .data.data-1 {
    display: flex; 
    flex-wrap: wrap;
    padding: 15px;
}
.mail .gradeclas {
   margin-bottom: 10px;
}
.mail .details .from .name {
  margin: 3px;
 }
 
 .studentwrapline {
  justify-content: normal;
  flex-wrap: wrap;
  padding: 12px 10px;
}
.namesubt3 {
  display: block;
  width: auto;
}

.mail .details {
   padding: 0;
}

 .main-panel{
  width: 100%;
  /* -webkit-transform: scale(.85)translateY(-25px);
transform: scale(.85)translateY(-25px); */
}

/* responsive header changes start */

.fix-menu-lb,.fix-menu-lb-two {
  height: auto !important;
  overflow: hidden;
  left: 0;
  width: 350px;
  position: relative !important;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  width: 100%;
   transition: 0.6s;
  display: flex;
  flex-direction: column;
}
.logo-img{
text-align: left;
margin: -1.5vh 0;
}
.navbar-toggler{
position: absolute;
right: 20px;
top: 20px;
}
.calender-timeslote.normal {
  top: -30px;
  position: static;
  width: 100%;
}
.calende-multi-function {
  max-width: 100%;
  margin: 0px 0px 0px 20px;
}
.fix-menu-lb .navbar-brand, .fix-menu-lb-two .navbar-brand {
  margin-bottom: 0px;
}

.clasessnamesmodal .logout,.clasessnamesmodal .logout:hover {
  /* position: static !important; */
  width: auto !important;
 }

 .navbar-collapse.collapse .mrlevelimg { 
  opacity: 0;
  transition: 0.1s;
  transform: translate(2000px, 0px);
 }
 .mrlevelimg {
  height: auto !important;
  width: 25vw !important;
  position: absolute !important;
  left: calc(100% - 25vw) !important;
  bottom: 0px;  
  display: none;
  transition-delay: 3s;
  transform: translate(0px,-200vh);
 }

 
 .navbar-collapse.collapse.show .mrlevelimg { 
  opacity: 1;
  transition: 0.7s;
  transform: translate(0px, 0px);
  display: inline;
 }
 
 .framel {
  display: inline-block;
  width: 50%; 
}
.song-form-v2 {
  margin:0 !important;
}
.song-page-instrument {
   padding: 0px 0px;
   }

.bannercourse img {
     margin-bottom: 0;
 }
/* responsive header changes start */
.row.searchelement {
   flex-direction: column-reverse;
}
.mt-50box {
  margin-top: 0;
} 
/* .searchelement .song-form-v2 div{
  padding:0 !important;
  margin: 0 !important; 
 
}  */

.song-form-v2 .mb-3,
.song-form-v2 *{
margin: 0px !important;
}
.content-box-lb h4 {
  font-size: 16px;
   line-height: 18px;
   padding-right: 15px;
}

.hscroll .infinite-scroll-component__outerdiv, .calender-timeslote .events-container.hscroll #scrollableDiv {
  max-height: calc(74vh + 0px);
  overflow-y: auto;
}
 
.searchelement .col-lg-9.col-md-8.col-sm-12,.searchelement .pl-0.col-lg-3.col-md-4.col-sm-12
{
  padding: 10px  !important;
  margin: 0 !important;

}
.serch-icon-btn-lb svg { 
  margin-top: -15px !important;
}
.box-wrap-lb-song ,.filter-value{
  padding: 10px;
  flex-wrap: wrap;
/* display: table-caption; */
}

.filter-value {
  padding: 0;
}
.row-performance .filter-value {
  padding:10px 0;
  margin-top:10px !important;
  margin-bottom:10px !important;
}
.ant-time-picker {
  margin-top: 15px !important;
  margin-bottom: 0px !important;
}

.lb-header-topcluster .filter-value {
  padding: 0;
  display: block;  
}
.lb-header-topcluster {
  position: static;
  width: 100%;

}
 .table-responsive.exam.cluster {
  margin-top:0px !important;
}
.content-box-lb {
 width: 45%;
}
.right-input-lb-wrap {
 width: 40%;
 min-width: 250px;
}

.filter-value .MuiFormControl-root {
 margin-top: 0 !important;
 margin-bottom: 0 !important;
}
.content-box-lb h4 {
    padding-left: 11px;
 }
 .content-box-lb span {
   padding: 3px;
 }
 .t-tips-left-nav {
   height: auto;
 }
 .video-player iframe {
  height: 14.6rem !important;
  min-height: 16rem;
   width: 100vw !important;
   min-width: 78vw !important; 
}
.left-box-lb ,.box-wrap-lb{
   flex-wrap: wrap !important;
}
.progress-search-wrap {
  margin-top: 15px;
}
.lb-header-top {
  padding: 15px;
}
.progress-selectedoption {
  margin:15px 0 0px 0 !important
}
.box-wrap-lb-imports .col-lg-2, .box-wrap-lb-imports .col-lg-3, .row-performance .col-lg-2 {
  min-width: 100%!important;
  margin-bottom: 0;
}
.fix-menu-lb .navbar-brand svg, .fix-menu-lb-two .navbar-brand svg {
  transform: scale(1) !important;
  height: 56px;
  padding: 5px 0;
}
.school-search {
  max-width: 100%;
  flex: 0 0 100%;
}
.events-container {
  padding: 0;
}
/* lesson page changes */
.wrapper-lb .lesson-list .ac-box-arrow {
  opacity: 1;
}
.wrapper-lb-new .lesson-list {
  /* width: auto !important; */
}

.wrapper-lb .lesson-list {
  width: 350px !important;
}
.wrapper-lb .lesson-list-wrap, .wrapper-lb .btn-wrap-p1 button, .wrapper-lb .select-instument-show, .wrapper-lb .weekboxs
/* ,.wrapper-lb .btn */
  {
  display: none;
}
.wrapper-lb .lesson-list {
  border: none !important;
  box-shadow: none !important;
  background: #fff !important;
}
.wrapper-lb .lesson-list {
  width:0px !important;
}
.lesson-m-panel {
  width: calc(100% - 0px);
}

.wrapper-lb-new .lesson-list-wrap, .wrapper-lb-new .btn-wrap-p1 button, .wrapper-lb-new .select-instument-show, .wrapper-lb-new .weekboxs, .wrapper-lb-new .btn {
  display:revert ;
}
.wrapper-lb-new .lesson-list { 
  background: #f7f8fc !important;
   width:350px !important;
     background: #e9e8fc4d;
     box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%) !important;
}
.wrapper-lb .ac-box-arrow {
  position: absolute;
  right: -105px;
  z-index: 1;
  top: 16px;
}

.wrapper-lb .ac-box-arrow b svg {
  opacity: 1 !important;
}
.wrapper-lb .ac-box-arrow svg {
  opacity: 0 !important; 
}
.wrapper-lb-new .ac-box-arrow svg {
  opacity: 1 !important; 
} 
.wrapper-lb-new .ac-box-arrow b svg {
  display: none;
}
.wrapper-lb-new .ac-box-arrow svg {
  opacity: 1 !important;
  display: block;
  height: 24px;
  position: absolute;
  width: 24px;
  top: 8px;
  left: 14px;
}

.wrapper-lb-new .ac-box-arrow {
  position: absolute;
  right: -106px;
  z-index: 1;
  top: 16px;
  width: 95px;
}
.lesson-topic-name-wrap {
 flex-direction: column;
}

.refbooksetup::before {
  bottom: 0px;
  width: 100%;
  height: 100px;
}
.lesson-m-panel.full-right-control .lesson-right-value .lessons-top-displays{
  flex-direction: column;
  display: flex;
  align-items: flex-start;
}

.row-buttopnnew {
  float: unset;
}
.scrollfix-setb {
  position: absolute !important;
  top: 0px;
  background: #fff0; 
  width: calc(100% - 60px);
  z-index: 9;
}

.lesson-list-wrap {
  height: calc(100vh - 40vh) !important;
}
.roadmaploop .list-group {
  transform: scale(60%);
}
.roadmaploop .list-group:nth-child(1) {
  left: 1vw;
 }
 .roadmaploop .list-group:nth-child(2) {
  left: 30vw;
 }
 .roadmaploop .list-group:nth-child(3){
  left: 60vw;
 }

}
@media screen and (max-width:800px) {
  .wrapper-assgnment-lb .col-lg-4.col-md-6.col-sm-10 {
    min-width: 70%;
    max-width: 85%;
  }

  .answer-card {
    padding: 30px;
  }

  .instrument-name-progress-boxes {
    width: calc(100% - 10px);
  }

  .calende-multi-function {
    max-width: 100%;
    margin: 10px 15px;
  }

  .logo-img {
    margin: -2.5vh -15px;
  }

  .framel {
    width: 50%;
    /* padding: 10px 0; */
  }

  .lb-header-top {
    padding: 15px !important;
  }

  .fsm-courses-page .m-3 {
    margin: 0rem !important;
  }

  .song-page-title {
    height: auto;
  }

  .song-form {
    margin-top: 5px;
    margin-bottom: 50px;
  }

  .song-page-title .col-lg-12.col-md-12.col-sm-10 {
    padding-right: 15px;
    padding-left: 15px;
  }

  .fsm-courses-page .lb-header-top {
    padding: 10px 0 !important;
  }

  .shadow-lb-common {
    max-width: 450px !important;
    flex-basis: 450px !important;
  }

  .instrument-flipbook {
    width: 48%;
    transform: scale(0.95);
    transition: 0.3s;
  }

  .desktop-appExperience {
    position: static;
  }

  .studentlogout {
    position: static !important;
    margin-bottom: 50px !important;
    display: block !important;
  }

  .lesson-list-wrap {
    height: calc(100vh - 40vh) !important;
  }

  .wrapper-lb .pdfviewer.book embed {
    height: 55vw;
    min-height: 410px;
  }

  .progressprofile .rightvalueshow {
    padding-left: 45px !important;
  }
  .roadmap {
     animation: roadmove 30s linear infinite;
    -webkit-animation: roadmove 30s linear infinite;
    -moz-animation: roadmove 30s linear infinite;
    -o-animation: roadmove 30s linear infinite;
 }

}

@media screen and (max-width: 767px) {

.roadmaploop .list-group {
  transform: scale(50%);
}
.roadmaploop .list-group:nth-child(1) {
  left: 1vw;
 }
 .roadmaploop .list-group:nth-child(2) {
  left: 30vw;
 }
 .roadmaploop .list-group:nth-child(3){
  left: 60vw;
 }

 .video-player iframe, .video-player {
  width: 90vw !important;
}

.homelayout .right-clbox {
   margin: 0;
}
 
/* leaderboard changes */

 
.lb-header-top p {
  font-size: 14px;
  padding: 0;
  margin-right: 0;
}
.right-section { 
  margin-left: 5px;
  width: 28px;
  text-align: center;
}
.icon-wrapper {
  min-width: 30px !important;
  height: 30px !important;
  margin-right: 6px; 
}
.icon-wrapper p {
  font-size: 16px !important;
}
.rank-section {
  min-width: 40px;
  margin-right: 5px;
}

.rank-section .medal-icon {
  width: 30px;
  height: auto;
}
}

@media screen and (max-width: 480px) {
  .lb-header-top p {
    font-size: 12px !important;
   }

  .icon-wrapper {
    min-width: 30px !important;
    height: 30px !important;
    margin-right: 6px; 
  }
  .icon-wrapper p {
     font-size: 16px !important;
  }
  .content-box-lb {
    width: 100%;
  }
  .right-input-lb-wrap {
    width: 80%;
    min-width: 250px;
  }
  .row.searchelement {
    flex-direction: column-reverse;
 }
 .instrument-flipbook {
  width: 100%;
  transform: scale(0.95);
  transition: 0.3s;
}
  .ans-option.fail::before,
  .ans-option.correct::before {
    background-size: 31px;
    width: 31px;
    height: 31px;
    right: -15px;
    top: -1px;
    border-radius: 100px;
  } 
   .box-wrap-lb-song ,.filter-value{
    padding: 10px;
    flex-wrap: wrap;
 display: flex;
 }
 .create-exam {
  padding: 40px !important;
 }

 .import-full-width .filter-value {
  padding: 0px;
  flex-wrap: wrap;
  display: block; 
  margin-top:15px !important;
  margin-bottom:0px !important;
  width: 100%;
  max-width: 100% !important;
} 
.import-full-width .gadefull-pixel-date{
  padding: 0px;
  flex-wrap: wrap;
  display: block; 
  margin-top:0px !important;
  margin-bottom:0px !important;
  width: 100%;
  max-width: 100% !important;
}

.form-file { 
  margin-top:15px; 
}

.maxvaluew{
  max-width: 100% !important;

}
.shadow-lb-common{
  max-width: 95% !important;
  flex-basis: 95% !important;
}
.music-session-data {
  padding: 0;
  margin: 0;
}
.box-wrap-lb.row,.row-performance{
  
  margin: 0 !important;
}
  .calender-timeslote.normal .instrument-detail-box {
    flex: 0 0 77%;
    max-width: 77%;
}
.instrument-icon {
  width: 64px;
  height: 64px;
}
.instrument-icon img {
  width: 60px;
  height: 60px;
 }
 .events__tag, .events__item--left span:nth-child(2) {
  font-size: 12px;
  line-height: 12px;
 }
 .school-teacher-name-wrap h3 {
  font-size: 5.1vw;
  line-height: 7vw;
  padding-right: inherit;
}
.school-teacher-name-wrap p {
  font-size: 14px;
  padding-right: 32vw;
}
.school-name-heading h4 {
   font-size: 18px;
   line-height: 18px;
   margin-top: 15px;
}
.song-form {
  margin: 6px 0;
  position: static;
  margin-bottom: 60px;
  width: 100%;
}
.framel {
  width: 100%;
  padding: 10px; 
}
.song-page-title {
  margin: 10px;
  padding: 20px;
}
.fsm-courses-page .lb-header-top .MuiTextField-root {
  margin: 10px !important;
  position: relative;
}

.fsm-courses-page .lb-header-top .serch-icon-btn-lb {
  padding: 0;
  position: absolute;
  left: 26px;
  top: 29px;
}
.view-search-filters.excercise-filter .mb-3.input-group{
   margin-bottom:0rem!important;
}

.instrument-box-one h3 {
  font-size: 24px;
  line-height: 30px;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.lb-header-top.lb-header-top-songs.song-heading.mt-0.pt-0{
  padding: 0px !important;
}

/* lesson changes video format for mobile */
.fsm-courses-page .col-lg-12.col-md-12.col-sm-12{
  padding: 0px !important;
}
.lesson-topic-name-wrap {
  min-width: 100%;
}
.video-frame-full {
   min-height: 100%;
}
.lesson-right-value video, .lesson-right-value iframe {
  height: 55vw !important;
  width: 100% !important;
  box-shadow: 0px 0px 0px 5px #e6e6e6;
  margin: 0 !important;
}
.full-right-control .lesson-right-value {
  min-height: auto;
  margin: 80px 10px;
}
 
.row-buttopnnew {
   max-width: 100%;
   min-width: 100%;
   padding: 0px;
}

.lesson-topic-name-wrapsecond {
   margin: 0vh auto 0px auto;
   padding: 0;
   margin-left: 0;
}
.lesson-m-panel.full-right-control .lesson-right-value .lessons-top-displays {
  transform: unset;
  width: 100%;
  min-width: 100%;
  margin: 15px 0;
  padding: 15px !important;
}
 
.lesson-right-value h6 {
  margin: 10px 5px 0px;
  font-size: 14px;
}
.lesson-right-value h3 {
  font-size: 18px !important;
  line-height: 20px !important;
   margin: 5px 5px 0;
}

.btn-wrap-p1 .btn.ml-3{
height: 39px;
width: 40px;
overflow: hidden;
}
.wrapper-lb-new .ac-box-arrow {
  right: -55px;
  width: 45px;
  overflow: hidden;
  color: #3f58de;
  line-height: 6;
}
 
.btn-wrap-p1 .logos svg{   
    width: auto;
    margin-left: -15px;
    height: 45px;
}
.wrapper-lb-new .lesson-list {
   width: 80% !important;
}  
.customModalLessonPage {
  /* padding: 10% 8% !important; */
  min-width: 85%;
}  
.newrcustomModal h2, .newrcustomModal h3 {
  font-size: 18px !important;
  line-height: 20px !important;
}
.objective-details-data{
  font-size: 14px !important;
  line-height: 20px !important; 
}
.objective-details-data ul li{
  text-align: left !important;
}
.react-responsive-modal-closeButton {
  top: 5px !important;
  right: 5px !important;
}
.assignment-heading {
   left: 12px;
}
.select-instument-show-quiz {
   font-size: 12px;
 }
 .answer-card h4 {
  font-size: 18px;
  line-height: 24px;
  }
  .ans-option {
    padding: 10px;
     font-size: 14px;
     margin-bottom: 10px !important;
     width: 100%;
     margin: 0;
}
.question-image {
   width: auto;
  height: 150px;
}
.customModalLessonPage .video-player, .newrcustomModal .video-player, .customModalLessonPage .video-player iframe, .newrcustomModal .video-player iframe {
  width: 100% !important;
  height: auto !important;
}
.newrcustomModal {
  max-width: 90vw !important;
  min-width: 80vw !important;
   margin: 80px 0px !important;
}
.rollbackvideo-fr{
  height: auto;
}
.rollbackvideo {
   padding: 10px !important;
}
.over {
  height: auto;
  max-height: 100% !important;
  padding: 20px;
}
.newrcustomModal .over h2, .newrcustomModal .over h3 {
  font-size: 14px !important;
  line-height: 18px !important;
  padding: 5px !important;
}
.lesson-right-value .lessons-top-display, .exit-assignment.d-endof {
  padding-left: 0% !important;
  padding-right: 0% !important;
  flex-wrap: wrap;
}
.lesson-down-btns {
   padding: 10px;
  font-size: 14px;
  margin: 8px;
   width: 140px !important;
}
.customModalLessonPage .lesson-right-value {
  margin: 5px 0px 5px 20px;
}

.instrument-name-progress-boxes {
   margin:5px 0px !important;
  width:100% !important;
}
.instrument-name-progress .row-instrument-name {
   margin: 0;
}
.t-tips-lb .nav-item:first-child a {
  padding: 15px 10px;
   width: 90%;
  margin: 10px auto;
}
.lb-header-top-concept.justify-content-between.song-page-title{ 
  flex-wrap: wrap;
  margin: 0;
  padding: 15px;
}
.view-search-filters {
   margin: 0 -15px;
  padding: 15px 0 !important;
   flex-direction: column !important;
  margin: 0 !important;
}
.view-search-filters .MuiFormControl-root {
  margin:0px 0px 20px 0!important;
 }

 .t-tips-lb {
  margin: 0;
  width: auto;
  height: auto;
}
.lb-header-top-import h3 ,.lb-header-top h3{
   line-height: 30px;
  font-size: 24px;
 }
 .bg-red {
  color: #333 !important;
  font-weight: 400 !important;
  font-size: 16px;
  margin-bottom: 25px;
  float: left;
}
.row-performance .col-lg-4 {
  max-width: 100% !important;
}
 

.box-wrap-lb-imports .d-flex {
  display: block !important;
}

.desktop-downLinkContainer img {
   margin: 5px !important;}

.navbar-toggler {
   right: 20px;
  top: 20px;
}
.logo-img {
  margin: -2.5vh 0px;
}
.newrcustomModalStudent {
  max-width:96vw !important;
  min-width: 90vw !important;
 }
 .desktop-appExperience {
  padding: 0vh 0px 1vh 15px;
    width: 70%;
 }


 .newrcustomModalStudent h2{
  font-size:20px;
  line-height:24px;

 }
.lesson-right-value{
  width: 90% !important;
}

.lb-header-top.pb-4 {
  /* padding-bottom: 0rem!important; */
}
.mb-0.select-gap{
  margin:10px !important;
  margin-left: 5px;
}
.scrollfix-setb {
  position: absolute !important;
  top: 0px; 
  width: calc(100% - 30px); 
}
.table-responsive.exam {
  width: 90vw; 
  margin:5px 0px;
}
.content-box-lb span{ 
  border: none !important;
  
}
.import-sbt-info.ml-2.icon-small
{margin-left: 0 !important; 
  font-weight: 400 !important;
}
.page-item.active .page-link, .pagination li:first-child a, .pagination li:last-child a {
   line-height: 18px !important;
}
.box-nwtexam .rec-det-boxes
{
  margin-left: 0 !important;
}
.assessmentnew-frame{
  margin: 15px !important;
}
/* .lesson-right-slider .slick-slider video, .lesson-right-slider .slick-slider img {
   width: 75% !important;
} */
.lesson-right-slider {
  margin: 0px 10px;
   width: 100%;
   padding: 0px 20px !important;
} 
.events__item {
  display: flex;
}
.lesson-list-wrap {
  height: calc(100vh - 45vh) !important; 
}

.calende-multi-function {
   margin: 0;
  padding: 15px;
}


/* scroll issue hader changes add */
.wrapper-lb-new .lesson-list .select-instument-show .box-logo-lb img {
  height: 40px !important;
  width: 40px !important;
}
.wrapper-lb-new .select-instument-show h3 {
  font-size: 14px;
  line-height: 16px;
}
.wrapper-lb-new .select-instument-show{
  display: flex !important; 
  margin: 8px;
  transform: scale(0.95);
}


  .weekboxs {
    margin: 0 0px 0 0px;
    padding-left: 15px;
  }
 .select-instument-show {
   margin: 10px 15px 10px 12px;
  }
  .wrapper-lb .p-4 {
    padding: 0.6rem !important;
  }
  .wrapper-lb .pdfviewer.book embed {
    height: 300px;
    min-height: 50vw; 
}
 
.studentwrapline {
  display: block;
}
.mail .details {
  width: auto;
 }
.pr-reporting .form-group{
  margin:5px 0;
  padding: 0 10px;
 }
 .progressprofile .rightvalueshow {
  padding-left: 50px !important;
  display: grid;
  line-height: 2.4 !important;
}

.progressprofile .rightvalueshow .userfsmico{
  margin-left: 0 !important;
  
}

  .pr-reporting{
  margin: 0;
  padding: 0;
 }
 .roadmaploop {
  position: relative;
  height: 300px;
  overflow-y: auto;
}

 .roadmaploop .list-group {
  transform: scale(60%);
}

.roadmaploop .list-group:nth-child(1) {
  left: 1vw;
 }
 .roadmaploop .list-group:nth-child(2) {
  left: 60vw;
 }
 .roadmaploop .list-group:nth-child(3){
  left: 120vw;
 }



.overscreen_roadmap.row{
  display: block !important;
}
.roadmap {
   width: 104%;
}
.roadmap-screen {
   bottom: 90px;
  z-index: -2;
 }
 .roadmap {
  height: 50px;
 }
 .roadmaploop .list-group:nth-child(1) {
  margin-left: -20px;
  top:150px;
  }
 .roadmaploop .list-group:nth-child(2) {
  margin-left: -20px;
  top:150px;
  }
 .roadmaploop .list-group:nth-child(3){
  margin-left: -20px;
  top:150px;
  }
  .overscreen_roadmap.row {
   margin-top: -50px;
}
.song-page-title .d-flex{
  display: block !important;
}
}

@media screen and (max-width: 416px) {
  .events__tag, .events__item--left span:nth-child(2) {
    font-size: 9px;
    line-height: 12px;
   }
   .fix-menu-lb .navbar-brand svg, .fix-menu-lb-two .navbar-brand svg {
    transform: scale(1) !important;
    height:45px;
    padding: 0px 0;
  }
  .lesson-list-wrap {
    padding: 5px 5px;
}
.content-box-lb span {
   font-size: 14px;
  line-height: 16px;
}
.Curriculum-box-btn p { 
  font-size: 18px;
  line-height: 16px;
  padding: 5px 15px;
}
.box-wrap-lb-song .right-input-lb-wrap {
  max-width: 350px !important;
  min-width: 230px !important;
  width: 100% !important;
  margin: 0;
}

}

@media screen and (max-width: 360px){
  .weekboxs button{
    font-size: 13px !important;
  }
  .custom-progressed {
    width: 80%;
  }
  .btn-wrap-p1 .logos svg {
     height: 35px;
}

}









